import { createReducer } from 'typesafe-actions'
import { __ } from '~/lib/i18n'
import { OverseaShippingCompanyType } from '~/types/enums'
import {
  INIT_SHIPPING,
  SELECT_OVERSEA_SHIPPING_COMPANY,
  SET_CURRENT_OVERSEA_SHIPPING_FEES,
  SET_LATEST_SHIPPING_ADDRESS,
  SET_OVERSEA_SHIPPING_COUNTRIES,
  SET_OVERSEA_SHIPPING_FEES,
  SET_SHIPPING_COUNTRY,
  SET_SMILE_DELIVERY_TRANS_POLICY,
  SET_TRANS_POLICY_MAP,
  UPDATE_AVAILABLE_OVERSEA_SHIPPING_COMPANIES,
  UPDATE_BUNDLE_SHIPPING_FEE_LIST,
  UPDATE_DEFAULT_ITEM_SHIPPING_FEE_IS_FREE_LIST,
  UPDATE_SHIPPING_POLICY_LIST,
  UPDATE_SHIPPING_UNAVAILABLE_CART_UNITS,
} from './actions'
import {
  LatestShippingAddress,
  OverseaShippingCompany,
  OverseaShippingInfo,
  ShippingAction,
  ShippingCountry,
  ShippingPolicy,
  ShippingState,
  SmileDeliveryTransPolicy,
  SmileDeliveryTransPolicyDisplay,
} from './types'

const initialState: ShippingState = {
  shippingCountry: 'SouthKorea',
  bundleShippingFeeList: [],
  defaultItemShippingFeeIsFreeList: [],
  shippingPolicyList: [],
  transPolicyMap: {},
  smileDeliveryTransPolicyMap: {},
  overseaShippingCountries: [],
  availableShippingCompanies: [],
  selectedOverseaShippingCompany: 'Unknown',
  overseaShippingFeeList: [],
  currentOverseaShippingFeeList: [],
  shippingUnavailableCartUnits: [],
}

export const getBundleShippingFee = (
  state: ShippingState,
  bundleKey: string,
): number => {
  const bundleShippingFee = state.bundleShippingFeeList.find(
    (x) => x.bundleKey === bundleKey,
  )

  return bundleShippingFee ? bundleShippingFee.shippingFee : 0
}

export const getIsBundleShipping = (
  state: ShippingState,
  bundleKey: string,
): number => {
  const bundleShippingFee = state.bundleShippingFeeList.find(
    (x) => x.bundleKey === bundleKey,
  )

  return bundleShippingFee ? bundleShippingFee.shippingFee : 0
}

/**
 * 특수배송 여부
 * 직접배송
 * 퀵
 * 방문수령
 * 착불
 * e쿠폰
 * @param shippingPolicy
 * @param isEcoupon
 * @returns {boolean}
 */
const isSpecialShipping = (
  shippingPolicy: ShippingPolicy,
  isEcoupon: boolean,
): boolean => {
  const { shippingMethodType, shippingChargePayType } = shippingPolicy

  if (shippingMethodType === 'Post') {
    return false // 우편은 일반배송처럼 노출해야하는데 착불로 설정되기 때문에 예외처리한다
  }

  return (
    ['Direct', 'QuickService', 'SelfReceive'].indexOf(shippingMethodType) >=
      0 ||
    shippingChargePayType === 'PaymentOnArrival' ||
    isEcoupon
  )
}

const getShippingMethodText = (
  shippingPolicy: ShippingPolicy,
  isECoupon: boolean,
): string | undefined => {
  switch (shippingPolicy.shippingMethodType) {
    case 'Direct':
      return __('ESCROW_BASKET_TEXT_50', '직접배송')
    case 'SelfReceive':
      return __('ESCROW_BASKET_TEXT_47', '방문수령')
    case 'QuickService':
      return __('ESCROW_BASKET_TEXT_49', '퀵서비스')
    case 'Post':
      return undefined // 우편은 일반배송처럼 노출한다..
    default:
      if (isECoupon) {
        return 'e쿠폰'
      } else if (shippingPolicy.shippingChargePayType === 'PaymentOnArrival') {
        return __('ESCROW_BASKET_TEXT_48', '착불')
      } else {
        return undefined
      }
  }
}

/**
 * 기본 배송 정책
 * getShippingPolicyEx > getShippingPolicy > getShippingPolicyBasicInfo (음..너무 구린데..)
 * @param state
 * @param shippingPolicyKey
 */
export const getShippingPolicyBasicInfo = (
  state: ShippingState,
  shippingPolicyKey: string,
): ShippingPolicy | undefined =>
  state.shippingPolicyList.find(
    (_) => _.shippingPolicyKey === shippingPolicyKey,
  )

/**
 * shallowEqual 필수
 * 특수배송 여부/배송방법 문구 포함한 배송정책
 * getShippingPolicyEx > getShippingPolicy > getShippingPolicyBasicInfo (음..너무 구린데..)
 * @param state
 * @param shippingPolicyKey
 * @param isEcoupon
 */
export const getShippingPolicy = (
  state: ShippingState,
  shippingPolicyKey: string,
  isEcoupon: boolean,
):
  | (ShippingPolicy & {
      isSpecialShipping: boolean
      shippingMethodText?: string
    })
  | undefined => {
  const shippingPolicy = state.shippingPolicyList.find(
    (_) => _.shippingPolicyKey === shippingPolicyKey,
  )
  if (shippingPolicy) {
    return {
      ...shippingPolicy,
      isSpecialShipping: isSpecialShipping(shippingPolicy, isEcoupon),
      shippingMethodText: getShippingMethodText(shippingPolicy, isEcoupon),
    }
  } else {
    return undefined
  }
}

/**
 * shallowEqual 필수
 * @param state
 * @param itemNo
 */
export const getArrivalEstimatedNotice = (
  state: ShippingState,
  itemNo: string,
): { text: string; conditionText?: string } | undefined => {
  const transPolicyInfo = state.transPolicyMap[itemNo]
  const smileDeliveryTransPolicy = state.smileDeliveryTransPolicyMap[itemNo]

  if (smileDeliveryTransPolicy) {
    const displayInfo = smileDeliveryTransPolicy.transPolicyDisplay
    const isDawnDelivery = displayInfo?.shippingType === 'Dawn'
    if (displayInfo) {
      const conditionText = displayInfo.displayV3 ? displayInfo.displayV4 : ''
      if (!displayInfo.displayV1 && !displayInfo.displayV3) {
        return {
          text: displayInfo.displayV4,
          conditionText,
        }
      } else if (!displayInfo.displayV1) {
        return {
          text: displayInfo.displayV2 + ' ' + displayInfo.displayV3,
          conditionText,
        }
      } else if (displayInfo.displayV5 && isDawnDelivery) {
        return {
          text:
            displayInfo.displayV1 +
            ' ' +
            displayInfo.displayV5 +
            ' ' +
            displayInfo.displayV3,
          conditionText,
        }
      } else {
        return {
          text: displayInfo.displayV1 + ' ' + displayInfo.displayV3,
          conditionText,
        }
      }
    }
  } else if (transPolicyInfo) {
    if (!transPolicyInfo.displayV1 && !transPolicyInfo.displayV3) {
      return { text: transPolicyInfo.displayV5 || '' }
    } else if (!transPolicyInfo.displayV1) {
      return {
        text: transPolicyInfo.displayV2 + ' ' + transPolicyInfo.displayV3,
      }
    } else {
      return {
        text:
          transPolicyInfo.displayV1 +
          ' ' +
          transPolicyInfo.displayV2 +
          ' ' +
          transPolicyInfo.displayV3,
      }
    }
  }
  return undefined
}

export const getSmileDeliveryTransPolicy = (
  state: ShippingState,
  itemNo: string,
): SmileDeliveryTransPolicy | undefined => {
  return state.smileDeliveryTransPolicyMap[itemNo]
}

/**
 * 배송국가
 * shallowEqual 필수
 * @param state
 */
export const getShippingCountry = (state: ShippingState): ShippingCountry => {
  const result = state.overseaShippingCountries.find(
    (x) => x.countryType === state.shippingCountry,
  )
  return (
    result || {
      countryCode: 'KR',
      countryType: 'SouthKorea',
      countryName: 'South Korea',
    }
  )
}

/**
 * 해외배송여부
 * @param state
 */
export const getIsOverseaShipping = (state: ShippingState): boolean => {
  const shippingCountry = getShippingCountry(state)

  return (
    shippingCountry.countryType !== 'Unknown' &&
    shippingCountry.countryType !== 'SouthKorea'
  )
}

export const getSelectedShippingCompanyUnavailableCartUnitIds = (
  state: ShippingState,
  shippingCompanyType: OverseaShippingCompanyType,
): number[] | undefined =>
  state.shippingUnavailableCartUnits.find(
    (shippingUnavailableCartUnit) =>
      shippingUnavailableCartUnit.shippingCompany === shippingCompanyType,
  )?.unavailableCartUnitIds

export const getIsCartUnitsOverSeaShippingUnavailable = (
  state: ShippingState,
  cartUnitIdList: number[],
  shippingCompanyType: OverseaShippingCompanyType,
): boolean => {
  const unavailableCartUnitIds =
    getSelectedShippingCompanyUnavailableCartUnitIds(
      state,
      shippingCompanyType,
    )?.filter((x) => cartUnitIdList.includes(x))
  return (
    JSON.stringify(cartUnitIdList) === JSON.stringify(unavailableCartUnitIds)
  )
}

export const getCartUnitOverseaShippingCostAndWeight = (
  state: ShippingState,
  cartUnitId: number,
): OverseaShippingInfo | undefined =>
  state.currentOverseaShippingFeeList
    .find((x) => x.shippingCompany === state.selectedOverseaShippingCompany)
    ?.shippingFees.find((shippingInfo) => {
      if (
        shippingInfo.cartUnitId === cartUnitId &&
        !getSelectedShippingCompanyUnavailableCartUnitIds(
          state,
          state.selectedOverseaShippingCompany,
        )?.includes(cartUnitId)
      ) {
        return {
          shippingFee: shippingInfo.shippingFee,
          shippingWeight: shippingInfo.shippingWeight,
        }
      }
    })

/**
 * 현재 선택한 배송사 기준 배송불가한 상품목록
 * shallowEqual 필수
 * @param state
 */
export const getCurrentShippingUnavailableCartUnitIdList = (
  state: ShippingState,
): number[] =>
  state.shippingUnavailableCartUnits.find(
    (x) => x.shippingCompany === state.selectedOverseaShippingCompany,
  )?.unavailableCartUnitIds || []

/**
 * 현재 선택한 배송사 기준 배송불가 상품 여부
 * @param state
 * @param cartUnitId
 */
export const getIsCurrentShippingUnavailable = (
  state: ShippingState,
  cartUnitId: number,
): boolean =>
  getCurrentShippingUnavailableCartUnitIdList(state).includes(cartUnitId)

/**
 * 사용시 shallowEqual로 안됨. isEqual사용할것
 * @param state
 * @param allCartUnitIdList
 */
export const getOverseaShippingCompaniesWithCost = (
  state: ShippingState,
  allCartUnitIdList: number[],
  selectedCartUnitIdList: number[],
): Array<
  OverseaShippingCompany & {
    cost: number
    isAllCartUnitUnAvailable: boolean
    isAvailable: boolean
  }
> => {
  return state.availableShippingCompanies.flatMap((x) => {
    const cost = state.overseaShippingFeeList
      .find((y) => y.shippingCompany === x.shippingCompanyType)
      ?.shippingFees.reduce(
        (result, entry) =>
          state.shippingUnavailableCartUnits
            .find(
              (shippingUnavailableCartUnit) =>
                shippingUnavailableCartUnit.shippingCompany ===
                x.shippingCompanyType,
            )
            ?.unavailableCartUnitIds.includes(entry.cartUnitId)
            ? result
            : result + entry.shippingFee,
        0,
      )
    if (cost) {
      return [
        {
          ...x,
          cost,
          isAllCartUnitUnAvailable: getIsCartUnitsOverSeaShippingUnavailable(
            state,
            allCartUnitIdList,
            x.shippingCompanyType,
          ),
          isAvailable: !getIsCartUnitsOverSeaShippingUnavailable(
            state,
            selectedCartUnitIdList,
            x.shippingCompanyType,
          ),
        },
      ]
    } else {
      return [
        {
          ...x,
          cost: 0,
          isAllCartUnitUnAvailable: getIsCartUnitsOverSeaShippingUnavailable(
            state,
            allCartUnitIdList,
            x.shippingCompanyType,
          ),
          isAvailable: !getIsCartUnitsOverSeaShippingUnavailable(
            state,
            selectedCartUnitIdList,
            x.shippingCompanyType,
          ),
        },
      ]
    }
  })
}

export const getOverseaShippingCompanyCount = (
  state: ShippingState,
): number => {
  return state.availableShippingCompanies.filter(
    (x) => x.shippingCompanyType !== 'Unknown',
  ).length
}

export const getOverseaShippingCost = (
  state: ShippingState,
): number | undefined =>
  state.overseaShippingFeeList
    .find((x) => x.shippingCompany === state.selectedOverseaShippingCompany)
    ?.shippingFees.reduce(
      (result, entry) =>
        getIsCurrentShippingUnavailable(state, entry.cartUnitId)
          ? result
          : result + entry.shippingFee,
      0,
    )

export const getSellerGroupOverseaShippingCost = (
  state: ShippingState,
  cartUnitIdList: number[],
): number | undefined =>
  state.overseaShippingFeeList
    .find((x) => x.shippingCompany === state.selectedOverseaShippingCompany)
    ?.shippingFees.reduce(
      (result, entry) =>
        getIsCurrentShippingUnavailable(state, entry.cartUnitId)
          ? result
          : cartUnitIdList.includes(entry.cartUnitId)
          ? result + entry.shippingFee
          : result,
      0,
    )

export const getCurrentDawnDeliveryCutOffTime = (
  state: ShippingState,
): DateAsString | undefined => {
  const dawnDeliveryTransPolicy = Object.values(
    state.smileDeliveryTransPolicyMap,
  ).find((x) => x?.transPolicyDisplay?.shippingType === 'Dawn')
  if (dawnDeliveryTransPolicy?.dawnDeliveryCutOffTime) {
    return dawnDeliveryTransPolicy?.dawnDeliveryCutOffTime
  }
}

export const getLatestShippingAddress = (
  state: ShippingState,
): LatestShippingAddress | undefined => state.latestShippingAddress

/**
 * shallowEqual 필수
 * @param state
 */
export const getFastDeliveryNudgingTransPolicy = (
  state: ShippingState,
): SmileDeliveryTransPolicyDisplay | undefined => {
  return Object.values(state.smileDeliveryTransPolicyMap).find(
    (x) => x && x.nudgeTransPolicyDisplay,
  )?.nudgeTransPolicyDisplay
}

export const getIsUnconditionalFreeShippingItem = (
  state: ShippingState,
  itemNo: string,
): boolean =>
  !!state.defaultItemShippingFeeIsFreeList.find((x) => x.itemNo === itemNo)
    ?.isFreeShipping

// region helpers

// endregion

const shipping = createReducer<ShippingState, ShippingAction>(initialState, {
  [INIT_SHIPPING]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [UPDATE_BUNDLE_SHIPPING_FEE_LIST]: (state, { payload }) => ({
    ...state,
    bundleShippingFeeList: state.bundleShippingFeeList
      .filter((x) => !payload.some((y) => y.bundleKey === x.bundleKey))
      .concat(payload),
  }),
  [UPDATE_DEFAULT_ITEM_SHIPPING_FEE_IS_FREE_LIST]: (state, { payload }) => ({
    ...state,
    defaultItemShippingFeeIsFreeList: state.defaultItemShippingFeeIsFreeList
      .filter((x) => !payload.some((y) => y.itemNo === x.itemNo))
      .concat(payload),
  }),
  [UPDATE_SHIPPING_POLICY_LIST]: (state, { payload }) => ({
    ...state,
    shippingPolicyList: [
      ...state.shippingPolicyList.filter(
        (x) =>
          !payload.some((y) => y.shippingPolicyKey === x.shippingPolicyKey),
      ),
      ...payload,
    ],
  }),
  [SET_TRANS_POLICY_MAP]: (state, { payload }) => ({
    ...state,
    transPolicyMap: payload,
  }),
  [SET_SMILE_DELIVERY_TRANS_POLICY]: (state, { payload }) => ({
    ...state,
    smileDeliveryTransPolicyMap: payload.map,
  }),
  [SET_OVERSEA_SHIPPING_COUNTRIES]: (state, { payload }) => ({
    ...state,
    overseaShippingCountries: payload,
  }),
  [UPDATE_AVAILABLE_OVERSEA_SHIPPING_COMPANIES]: (state, { payload }) => ({
    ...state,
    availableShippingCompanies: payload,
  }),
  [SELECT_OVERSEA_SHIPPING_COMPANY]: (state, { payload }) => ({
    ...state,
    selectedOverseaShippingCompany: payload,
  }),
  [SET_OVERSEA_SHIPPING_FEES]: (state, { payload }) => ({
    ...state,
    overseaShippingFeeList: payload,
  }),
  [SET_CURRENT_OVERSEA_SHIPPING_FEES]: (state, { payload }) => ({
    ...state,
    currentOverseaShippingFeeList: payload,
  }),
  [UPDATE_SHIPPING_UNAVAILABLE_CART_UNITS]: (state, { payload }) => ({
    ...state,
    shippingUnavailableCartUnits: payload,
  }),
  [SET_LATEST_SHIPPING_ADDRESS]: (state, { payload }) => ({
    ...state,
    latestShippingAddress: payload,
  }),
  [SET_SHIPPING_COUNTRY]: (state, { payload }) => ({
    ...state,
    shippingCountry: payload,
  }),
})

export default shipping
