export const CookieKeys = {
  NudgingOneClickShowingCount: 'one_click_showing_count',
  NudgingSmileCardClosed: 'smile_card_nudging_closed',
  NudgingClubOneClickClosed: 'one_click_closed',
  NudgingAppDownClosed: 'app_nudging_closed',
  NudgingCartCountExtended: 'cart_count_extended',
  ShippingCountry: 'shipnation',
  ShippingMethodType: 'shippingMethodType',
  Currency: 'currency',
  TabType: 'tabType',
  Charset: 'charset',
}

export default class CookieHelper {
  static setCookie(
    key: string,
    value: string,
    expires?: Date,
    domain?: string,
    path?: string,
  ): void {
    document.cookie = `${key}=${escape(value)};${
      domain ? ` domain=${domain};` : ''
    }${path ? ` path=${path};` : ' path=/;'}${
      expires ? ` expires=${expires.toUTCString()};` : ''
    }`
  }

  static getCookie(key: string): string | undefined {
    const value = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)')
    return value ? value[2] : undefined
  }

  static delCookie(key: string, domain?: string, path?: string): void {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;${
      domain ? ` domain=${domain};` : ''
    }${path ? ` path=${path};` : ' path=/;'}`
  }
}
