import { createStandardAction } from 'typesafe-actions'
import { BranchServiceType } from '~/types/enums'
import { SmileFreshState } from './types'

export const INIT_SMILE_FRESH = 'smile-fresh/INIT_SMILE_FRESH'
export const SET_BRANCH_SERVICE_TYPE = 'smile-fresh/SET_BRANCH_SERVICE_TYPE'

export const initSmileFresh = createStandardAction(INIT_SMILE_FRESH)<
  Partial<SmileFreshState>
>()
export const setBranchServiceType = createStandardAction(
  SET_BRANCH_SERVICE_TYPE,
)<BranchServiceType>()
