import dataPicker from '~/data/data-picker'
import siteEnv from '~/lib/site-env'
import { ExpressShopType, NudgingType, StaticPageType } from '~/types/enums'
import auctionConstants from './auction'
import g9Constants from './g9'
import gmarketConstants from './gmarket'

const siteConstants: SiteEnvDataType<TenantConstants>[] = [
  {
    env: {
      tenantType: 'Auction',
    },
    data: auctionConstants,
  },
  {
    env: {
      tenantType: 'Gmarket',
    },
    data: gmarketConstants,
  },
  {
    env: {
      tenantType: 'G9',
    },
    data: {
      ...gmarketConstants,
      ...g9Constants,
    },
  },
]

/**
 * 주문서에서 사용가능한 쿠폰타입
 * GroupCoupon : 옥션처럼 상품단위로 적용되는 쿠폰
 * UnitCoupon : G마켓, G9처럼 주문단위로 적용되는 쿠폰
 */
export type CheckoutCouponUseType = 'GroupCoupon' | 'UnitCoupon'

export type TenantConstants = Readonly<{
  // PC 버전 따로 사용하는 static page 리스트
  NotUseMobileVersionStaticPages: Readonly<StaticPageType[]>

  // 해외배송 가능 여부(지마켓만 지원)
  IsOverseaShippingAvailable: boolean

  // 사은품, 덤 정보 노출 여부
  IsShowingBenefitInfos: boolean

  // 페이지 보여줄 시점 (필수요소만/전부로드되었을때)
  PageShowingPoint: 'OnlyEssential' | 'FullLoad'

  CouponUseType: CheckoutCouponUseType

  IsUsingCouponBoxLayer: boolean

  IsUsingCartTab: boolean

  IsUsingCartBt: boolean

  /**
   * focus 시에 그냥 scrollIntoView 하면 안되고 특정 위치로 스크롤되어야 하는 애들
   * - 약관은 일반적인 상황에 결제금액 박스에 가려지게 되어서 예외처리한다.
   */
  ElementScrollOptions: { [key: string]: ScrollToOptions | undefined }

  SupportedNudgingTypes: NudgingType[]

  SFExpressUnableLargeCategoryCodes?: string[]

  ExpressShopSellerIds?: Record<ExpressShopType, string>

  MomentLocale: 'ko' | 'en' | 'zh-cn'

  CustomerCenterContact: string
}>

const data = dataPicker<TenantConstants>(siteConstants, siteEnv)

if (!data) {
  throw new Error('no checkout constants data')
}

const tenantConstants: TenantConstants = data

export default tenantConstants
