import moment from 'moment'
import { createReducer } from 'typesafe-actions'
import { RootState } from '~/cart/modules/reducers'
import {
  INIT_BUYER,
  SET_SMILE_CLUB_MEMBER_INFO,
  SET_SMILE_CLUB_ONE_CLICK_TARGET_INFO,
  UPDATE_HASHED_CGUID,
  UPDATE_UNIFY_MEMBERSHIP_INFO,
} from './actions'
import { BuyerAction, BuyerState } from './types'

const initialState: BuyerState = {
  buyerName: '',
  phoneNumber: '',
  isLogin: false,
  hashedCguid: '',
  isSmileClub: false,
  memberType: 'Unknown',
  isSimpleJoinForeigner: false,
  isDomesticBusinessman: false,
  smileClubInfo: {
    memberType: 'Unknown',
  },
  unifyMembershipInfo: {
    unifyMasterId: 0,
    smileClubMember: false,
    smileClubMemberInformation: {
      statusCode: '',
      payCycleType: '',
      memberType: '',
      membershipGrade: '',
      joinPartnerId: '',
      isUnifyMembership: false,
      isSSGPoint: false,
      isSSGMembership: false,
      withdrawDt: '',
    },
  },
  isSmileClubOneClickTarget: false,
}

export const getIsJoinableSmileClubBiz = (state: BuyerState): boolean =>
  state.isDomesticBusinessman &&
  state.smileClubInfo &&
  (state.smileClubInfo.memberType === 'Unknown' ||
    state.smileClubInfo.memberType === 'Waiting')

export const getIsTargetUnifyMembership = (state: BuyerState): boolean =>
  !['S001', 'S002'].includes(
    state.unifyMembershipInfo.smileClubMemberInformation.joinPartnerId,
  ) &&
  ['SF', 'SP'].includes(
    state.unifyMembershipInfo.smileClubMemberInformation.statusCode,
  ) &&
  state.unifyMembershipInfo.smileClubMemberInformation.isSSGMembership &&
  !state.unifyMembershipInfo.smileClubMemberInformation.isSSGPoint

export const getIsUniverseClub = (state: BuyerState): boolean =>
  state.isSmileClub ||
  state.unifyMembershipInfo.smileClubMemberInformation.isSSGMembership

export const getIsUniverseClubNudging = (state: RootState): boolean => {
  const { buyer } = state
  const months = moment().format('MM')
  const withdrawDt = buyer.smileClubInfo.membershipWithdrawDate
  const isBaseNudgingActived =
    !buyer.isDomesticBusinessman && !getIsUniverseClub(buyer)

  return withdrawDt
    ? isBaseNudgingActived && months !== moment(withdrawDt).format('MM')
    : isBaseNudgingActived
}

const buyer = createReducer<BuyerState, BuyerAction>(initialState, {
  [INIT_BUYER]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [SET_SMILE_CLUB_MEMBER_INFO]: (
    state,
    { payload: { isSmileClub, smileClubInfo } },
  ) => ({
    ...state,
    isSmileClub,
    smileClubInfo,
  }),
  [UPDATE_UNIFY_MEMBERSHIP_INFO]: (state, { payload }) => ({
    ...state,
    unifyMembershipInfo: payload,
  }),
  [SET_SMILE_CLUB_ONE_CLICK_TARGET_INFO]: (state, { payload: isTarget }) => ({
    ...state,
    isSmileClubOneClickTarget: isTarget,
  }),
  [UPDATE_HASHED_CGUID]: (state, { payload }) => ({
    ...state,
    hashedCguid: payload,
  }),
})

export default buyer
