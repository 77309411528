import 'moment/min/locales'
import { fetchGetEnv } from '~/apis/env'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import simpleSwitch from '~/lib/simple-switch'
import siteEnv from '~/lib/site-env'
import { LanguageType } from '~/types/enums'

const TIMEOUT = 3000

export default class PageInitializer {
  private static loadScript = (src: string): Promise<HTMLScriptElement> => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src

      const parent = document.getElementById('script-section') || document.body

      parent.appendChild(script)
      let isResolved = false

      script.onload = (): void => {
        if (!isResolved) {
          isResolved = true
          resolve(script)
        }
      }

      window.setTimeout(() => {
        if (!isResolved) {
          isResolved = true
          reject('script load timeout')
        }
      }, TIMEOUT)
    })
  }

  private static loadCss = (src: string): void => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = src
    const parent = document.getElementById('css-section') || document.head
    parent.appendChild(link)
  }

  private static loadCssAsync = (src: string): Promise<HTMLLinkElement> => {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = src
      const parent = document.getElementById('css-section') || document.head
      parent.appendChild(link)
      let isResolved = false
      link.onload = (): void => {
        if (!isResolved) {
          isResolved = true
          resolve(link)
        }
      }
      window.setTimeout(() => {
        if (!isResolved) {
          isResolved = true
          reject('css load timeout')
        }
      }, TIMEOUT)
    })
  }

  static setEnvTagAndGoOldButton = (forceOpen?: boolean): void => {
    const queryCharsetRegex = /[&?]charset=(\w+)/i
    const queryCharsetMatch = window.location.search.match(queryCharsetRegex)
    const cookieCharset = CookieHelper.getCookie(CookieKeys.Charset)
    const correctLanguage = simpleSwitch<LanguageType, string>(
      queryCharsetMatch ? queryCharsetMatch[1] : cookieCharset,
    )
      .on('enUS', 'English')
      .on('zhCN', 'Chinese')
      .otherwise('Korean')
    if (
      siteEnv.languageType !== 'Korean' &&
      correctLanguage !== 'Korean' &&
      siteEnv.languageType !== correctLanguage
    ) {
      window.location.replace(
        window.location.href
          .replace(queryCharsetRegex, '')
          .replace(
            /\/(ko|en|cn)\//,
            `/${simpleSwitch(correctLanguage)
              .on('Chinese', 'cn')
              .otherwise('en')}/`,
          ),
      )
      throw new Error('page moved')
    }

    if (
      siteEnv.env &&
      (siteEnv.env !== 'production' || forceOpen) &&
      /\/\w{2}\/(?:(pc|m)\/)?(cart)(\/?)/.test(window.location.href)
    ) {
      if (!document.getElementById('env_select')) {
        const envSelect = document.createElement('select')
        envSelect.setAttribute('id', 'env_select')
        envSelect.setAttribute(
          'style',
          'position: fixed;\n' +
            '    z-index: 99999;\n' +
            '    opacity: 0.5;\n' +
            '    background-color: white;\n' +
            '    font-weight: bold;\n' +
            '    left:0;\n' +
            '    top:0;\n' +
            '    padding: 1px 5px;',
        )

        const envList =
          /^av/.test(siteEnv.env) || siteEnv.env === 'production'
            ? ['production', 'av', 'av2']
            : [
                'development',
                'development1',
                'development2',
                // 'development3',
                // 'development4',
                // 'development5',
                'local',
              ]

        envList.forEach((x) => {
          const option = document.createElement('option')
          option.value = x
          option.innerText = x
          envSelect.appendChild(option)
        })

        const currentEnv = siteEnv.env + (siteEnv.devSet || '')
        if (!envList.includes(currentEnv)) {
          return
        }
        envSelect.value = currentEnv

        const getUrl = (env: string, currentHref: string): string => {
          const protocol = env === 'local' ? 'http' : 'https'
          const prefix =
            env === 'local'
              ? '-local'
              : /^av\d?$/.test(env)
              ? `-${env}`
              : /^development\d?$/.test(env)
              ? `-${env.replace('elopment', '')}`
              : ''
          const port = env === 'local' ? ':3001' : ''

          return currentHref.replace(
            /^https?:\/\/(\w+)(?:-[\w\d]+)?\.(gmarket|auction|g9)\.co\.kr(?::\d{1,4})?/,
            `${protocol}://$1${prefix}.$2.co.kr${port}`,
          )
        }

        envSelect.onchange = (): void => {
          const env = envSelect.value
          if (env !== currentEnv) {
            window.location.replace(getUrl(env, window.location.href))
          }
        }

        document.body.appendChild(envSelect)
      }
    }
  }

  static setBodyClass = (bodyClass: string): void => {
    document.body.className = bodyClass
  }

  static init = async (
    title: string,
    bodyClass: string,
    cssList: string[] = [],
    scriptList: string[] = [],
    redirectByDevice?: boolean,
    initialScript?: () => void,
    loadScriptByOrder?: boolean,
  ): Promise<void> => {
    if (window.location.hostname === 'localhost') {
      window.location.hostname = `cart-local.${siteEnv.tenantType.toLowerCase()}.co.kr`
    }
    window.name = 'cartWindow'
    PageInitializer.setBodyClass(bodyClass)

    // title !== ''
    if (title) {
      document.title = title
    }
    try {
      const env = await fetchGetEnv()
      if (redirectByDevice && env.isMobile !== env.isMobileDevice) {
        const regex = /(\w{2})\/(m|pc)\//i
        if (regex.test(window.location.href)) {
          window.location.replace(
            window.location.href.replace(
              regex,
              `$1/${env.isMobileDevice ? 'm' : 'pc'}/`,
            ),
          )
        }
      }

      const platformOsClass = `${env.platformType} ${env.osType}`
      document.documentElement.className = platformOsClass

      if (siteEnv.tenantType === 'G9') {
        // G9는 구버전규격에 따라서 body에 붙여줘야함
        document.body.className = `${
          document.body.className
        } ${platformOsClass.replace(/_/g, '-')}`
      }
    } catch (e) {
      console.error(e)
    }

    PageInitializer.setEnvTagAndGoOldButton()

    const loadScriptByOrderFunction = async (): Promise<void> => {
      for (const src of scriptList) {
        await PageInitializer.loadScript(src)
      }
    }

    return Promise.allSettled([
      ...cssList.map(PageInitializer.loadCssAsync),
      ...(loadScriptByOrder
        ? [loadScriptByOrderFunction()]
        : scriptList.map(PageInitializer.loadScript)),
    ]).then((results) => {
      if (initialScript) {
        initialScript()
      }
      results.forEach((x) => {
        if (x.status === 'rejected') {
          console.error(x.reason)
        }
      })
    })
    //
    // return Promise.all(scriptList.map(PageInitializer.loadScript))
    //   .then((scripts) => {
    //     if (initialScript) {
    //       initialScript()
    //     }
    //     return scripts
    //   })
    //   .catch((e) => console.error(e))
  }
}
