import React, { useCallback, useState } from 'react'
import AddCartButton from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/AddCartButton'
import BuyBoxContext, {
  useBuyBoxContext,
} from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxContext'
import BuyBoxPending from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/BuyBoxPending'
import LowestPriceBuyBox from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/LowestPriceBuyBox'
import SelectOptionButton from '~/cart/gmarket/ko/common/components/CartRecommend/BuyBox/SelectOptionButton'
import useIntersectionObserver from '~/cart/hooks/useIntersectionObserver'
import { BuyBox, BuyBoxItem } from '~/cart/modules/recommend/types'
import Filter from '~/lib/components/Filter'
import { CartTabType } from '~/types/enums'

interface SoldOutBuyBoxProps {
  tab: CartTabType
  buyBox: BuyBox
  pending?: React.ReactNode
  children?: React.ReactNode
}

const IntersectionObserved = React.memo(function IntersectionObserved({
  itemNo,
  children,
}: {
  itemNo: string
  children?: React.ReactNode
}): JSX.Element {
  const { addIntersectionObserver } = useBuyBoxContext()

  return (
    <Section callbackRef={addIntersectionObserver(itemNo)}>{children}</Section>
  )
})

const Section = React.memo(function Section({
  callbackRef,
  children,
}: {
  callbackRef: (element: HTMLElement | null) => void
  children?: React.ReactNode
}): JSX.Element {
  return (
    <div className="section box__buybox" ref={callbackRef}>
      {children}
    </div>
  )
})

const GoodDealBuyBox = ({ item }: { item: BuyBoxItem }): JSX.Element => (
  <LowestPriceBuyBox title="같은 상품 중 배송비 포함 최저가!" item={item} />
)

const SoldOutBuyBox = ({ item }: { item: BuyBoxItem }): JSX.Element => (
  <LowestPriceBuyBox title="품절된 상품과 같은 상품이 있어요!" item={item} />
)

const BuyBoxSection = ({
  tab,
  buyBox,
  pending = <BuyBoxSection.Pending />,
  children,
}: SoldOutBuyBoxProps): JSX.Element => {
  const { addTarget } = useIntersectionObserver({
    rootMargin: '0px',
    threshold: 0.5,
  })

  const [appearedItems, setAppearedItems] = useState<string[]>([])

  const addIntersectionObserver = useCallback(
    (key: string) =>
      (element: HTMLElement | null): void => {
        if (element) {
          addTarget({
            key,
            element,
            callback: () =>
              setAppearedItems((prev) =>
                prev.includes(key) ? prev : [...prev, key],
              ),
          })
        }
      },
    [addTarget],
  )

  return (
    <BuyBoxContext.Provider
      value={{
        tab,
        buyBox,
        appearedItems,
        addIntersectionObserver,
      }}
    >
      <Filter selected={buyBox.status}>
        <Filter.In list={['Pending']}>{pending}</Filter.In>
        <Filter.In list={['Loaded']}>{children}</Filter.In>
      </Filter>
    </BuyBoxContext.Provider>
  )
}

BuyBoxSection.Pending = BuyBoxPending
BuyBoxSection.GoodDeal = GoodDealBuyBox
BuyBoxSection.SoldOut = SoldOutBuyBox
BuyBoxSection.AddCartButton = AddCartButton
BuyBoxSection.SelectOptionButton = SelectOptionButton
BuyBoxSection.IntersectionObserved = IntersectionObserved

export default BuyBoxSection
