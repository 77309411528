import axiosClient from '~/lib/axios-client'
import { AppType, LanguageType, TenantType } from '~/types/enums'

export const fetchGetEnv = async (): Promise<{
  isLogin: boolean
  isApp: boolean
  isAndroid: boolean
  isIE: boolean
  isMobile: boolean // 현재 모바일페이지인지 여부
  isMobileDevice: boolean // 실제로 모바일 디바이스로 접근한것인지 여부 (주의!)
  tenantType: TenantType
  osType: string
  platformType: string
  appInfo?: {
    os: string
    appVersion: string
    appKey: string
    appType: AppType
  }
  browserVersion?: string
  languageType: LanguageType
}> => axiosClient.get('/env')
