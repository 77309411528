/* eslint-disable */
import siteEnv from '~/lib/site-env'
import { openUrlFromHiddenIframe } from '~/lib/utils'

const isProduction = siteEnv.env === 'production' || siteEnv.env === 'av'
const scriptHost = isProduction
  ? '//script.gmarket.co.kr'
  : '//script-dev.gmarket.co.kr'

export default {
  loadScriptByOrder: false, // 순차적으로 로드해야할때만 사용!! 이슈없으면 default는 false다 주의
  headerScriptSources: [
    // scriptHost + '/mobile/v4/gen/IM.Header.min.js',
    // scriptHost + '/mobile/v4/gen/IM.Header.LayerTemplate.min.js',
  ],
  footerScriptSources: [],
  renderHeader: (title) => {
    if (window.MobileLayout && !window.gmHeader) {
      window.gmHeader = MobileLayout.render([
        {
          name: 'Header',
          params: { titleText: title, backButton: true, closeButton: false },
          container: document.getElementById('header'),
        },
      ])[0]
    }
  },

  renderBottomNavigation: () => {
    if (window.MobileLayout && !window.gmBottomNavigation) {
      window.gmBottomNavigation = MobileLayout.render([
        {
          name: 'BottomNavigation',
          container: document.getElementById('bottomNavigation'),
        },
      ])[0]
    }
  },

  renderFooter: () => {
    if (window.MobileLayout && !window.gmFooter) {
      window.gmFooter = MobileLayout.render([
        {
          name: 'Footer',
          params: { topButtonHidden: true },
          container: document.getElementById('footer'),
        },
      ])[0]
    }
  },

  releaseHeader: () => {
    delete window.gmHeader
    // DO NOTHING
  },

  releaseFooter: () => {
    delete window.gmFooter
    // DO NOTHING
  },

  releaseBottomNavigation: () => {
    delete window.gmBottomNavigation
  },

  setHeaderTitle: (title) => {
    if (window.gmHeader) {
      window.gmHeader.set({
        titleText: title,
      })
    }
  },

  setCloseUrl: (url) => {},
  hideAppHeader: (title) => {
    openUrlFromHiddenIframe('gmarket://newWebGnb?type=10')
  },
  showAppHeader: (title) => {
    if (window.gmHeader) {
      window.gmHeader.set({
        titleText: title,
      })
    }
  },
}
