import axiosClient from '~/lib/axios-client'

export const fetchAddCheckoutByCart = async (
  request: cartAPI.AddCheckoutCommandRequest,
): Promise<cartAPI.AddCheckoutCommandResponse> => {
  return axiosClient.post('/cart-checkout/addCheckoutByCart', request, {
    timeout: 5000, // addCheckoutByCart 은 오래걸릴수 있어서 따로 정의됨. 기본 3초이니 특별한 이유 없으면 제거할것
  })
}

/**
 * @deprecated
 * @param request
 */
export const fetchPrepareOrder = async (
  request: cartAPI.PrepareOrderRequest,
): Promise<cartAPI.CommandResult> => {
  return axiosClient.post('/cart-checkout/prepareOrder', request, {
    timeout: 5000,
  })
}
