import { AxiosRequestConfig } from 'axios'
import { getParameters } from '~/lib/param-parser'

const isMockApi =
  getParameters().isMockApi && process.env.REACT_APP_ENV !== 'production'

export const mockWrapper = (
  org: <X, Y>(url: string, config?: AxiosRequestConfig) => Promise<X>,
): (<X, Y>(url: string, config?: AxiosRequestConfig) => Promise<X>) => {
  if (!isMockApi) {
    return org
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { getMockData } = require('./mock-data')
    const mockDataList = getMockData()

    return <T, S>(url: string, config?: AxiosRequestConfig): Promise<T> => {
      const mockData: T = mockDataList[url]
      if (mockData) {
        console.log(url, 'api modified with Mock >>', mockData)
        return Promise.resolve(mockData)
      } else {
        return org<T, S>(url, config)
      }
    }
  }
}

export const postMockWrapper = (
  org: <X, Y, Z>(
    url: string,
    body: Y,
    config?: AxiosRequestConfig,
  ) => Promise<X>,
): (<X, Y, Z>(
  url: string,
  body: Y,
  config?: AxiosRequestConfig,
) => Promise<X>) => {
  if (!isMockApi) {
    return org
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { getMockData } = require('./mock-data')
    const mockDataList = getMockData()

    return <T, B, R>(
      url: string,
      body: B,
      config?: AxiosRequestConfig,
    ): Promise<T> => {
      const mockData: T = mockDataList[url]
      if (mockData) {
        console.log(url, 'api is replaced with Mock >>', mockData)
        return Promise.resolve(mockData)
      } else {
        return org<T, B, R>(url, body, config)
      }
    }
  }
}
