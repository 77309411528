import { omit } from 'lodash'
import { createReducer } from 'typesafe-actions'
import {
  ADD_BUY_BOXES,
  ADD_HIT_BUY_BOX,
  ADD_RECOMMENDED_ITEMS,
  FILL_BT_RECOMMEND_ITEM_EXT_INFO,
  REMOVE_BUY_BOXES,
  SET_BT_RECOMMEND_ITEM_MAP,
  SET_BT_VIEW_TYPE,
  SET_CURRENT_BT_SEED_IDX,
} from './actions'
import {
  FullFilledBtRecommendItem,
  RecommendAction,
  RecommendState,
} from './types'

const initialState: RecommendState = {
  btItemMap: [],
  currentBtSeedIdx: -1,
  btViewType: 'TypeA',
  buyBoxes: {},
  hitBuyBoxes: {},
  hitBuyBoxIndexes: [],
  items: {},
}

/**
 * isEqual 필수
 * @param state
 */
export const getCurrentBtRecommendItemList = (
  state: RecommendState,
): FullFilledBtRecommendItem[] => {
  const btItem = state.btItemMap[state.currentBtSeedIdx]
  if (btItem && btItem.isExtInfoFilled) {
    return btItem.recommendItems.flatMap<FullFilledBtRecommendItem>((x) =>
      x.extInfo ? [{ ...x.source, ...x.extInfo }] : [],
    )
  }
  return []
}

export const getTotalBtSeedItemCount = (state: RecommendState): number =>
  state.btItemMap.length

export const getCurrentBtSeedItemNo = (
  state: RecommendState,
): string | undefined =>
  state.btItemMap[state.currentBtSeedIdx]
    ? state.btItemMap[state.currentBtSeedIdx].seedItemNo
    : undefined

const recommend = createReducer<RecommendState, RecommendAction>(initialState, {
  [SET_BT_RECOMMEND_ITEM_MAP]: (state, { payload }) => {
    return {
      ...state,
      btItemMap: payload,
    }
  },
  [FILL_BT_RECOMMEND_ITEM_EXT_INFO]: (state, { payload }) => {
    return {
      ...state,
      btItemMap: state.btItemMap.map((x) => {
        if (x.seedItemNo !== payload.seedItemNo) {
          return x
        } else {
          return {
            ...x,
            isExtInfoFilled: true,
            recommendItems: x.recommendItems.map((y) => ({
              ...y,
              extInfo: payload.itemExtInfoList.find(
                (z) => z.itemNo === y.itemNo,
              ),
            })),
          }
        }
      }),
    }
  },
  [SET_CURRENT_BT_SEED_IDX]: (state, { payload }) => {
    return {
      ...state,
      currentBtSeedIdx: payload,
    }
  },
  [SET_BT_VIEW_TYPE]: (state, { payload }) => ({
    ...state,
    btViewType: payload,
  }),
  [ADD_BUY_BOXES]: (state, { payload }) => {
    const prev = state.buyBoxes
    return {
      ...state,
      buyBoxes: {
        ...prev,
        ...payload,
      },
    }
  },
  [REMOVE_BUY_BOXES]: (state, { payload }) => {
    const prev = state.buyBoxes
    return {
      ...state,
      buyBoxes: omit(prev, payload),
    }
  },
  [ADD_HIT_BUY_BOX]: (state, { payload }) => {
    const { itemNo, hitBuyBox } = payload

    const hitBuyBoxes = state.hitBuyBoxes
    const prevHits = hitBuyBoxes[itemNo] ?? []
    const addedHits = [...prevHits, hitBuyBox]
    hitBuyBoxes[itemNo] = addedHits

    return {
      ...state,
      hitBuyBoxes: {
        ...hitBuyBoxes,
      },
      hitBuyBoxIndexes: [
        ...state.hitBuyBoxIndexes,
        { itemNo, index: addedHits.length - 1 },
      ],
    }
  },
  [ADD_RECOMMENDED_ITEMS]: (state, { payload }) => {
    return {
      ...state,
      items: {
        ...state.items,
        ...payload,
      },
    }
  },
})

export default recommend
