import axiosClient from '~/lib/axios-client'

export const fetchGetOverseaShippingFees = async (
  request: cartAPI.GetOverseaShippingFeesRequest,
): Promise<cartAPI.GetOverseaShippingFeesResponse[]> => {
  return axiosClient.post('/oversea/getOverseaShippingFees', request)
}

export const fetchGetOverseaShippingCountries = async (): Promise<
  cartAPI.ShippingCountryResponse[]
> => {
  return axiosClient.post('/oversea/getOverseaShippingCountries')
}

export const fetchGetOverseaShippingCompanies = async (
  request: cartAPI.GetOverseaShippingCompaniesRequest,
): Promise<cartAPI.OverseaShippingCompanyResponse[]> => {
  return axiosClient.post('/oversea/getOverseaShippingCompanies', request)
}

export const fetchGetShippingCompanyLimit = async (
  request: cartAPI.GetShippingCompanyLimitRequest,
): Promise<cartAPI.ShippingCompanyLimitResponse[]> => {
  return axiosClient.post('/oversea/getShippingCompanyLimit', request)
}
