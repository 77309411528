import moment from 'moment'
import { createReducer } from 'typesafe-actions'
import { UPDATE_UNIT_CASHBACK_LIST } from '~/cart/modules/cashback/actions'
import { SmileCashbackType } from '~/types/enums'
import {
  CashbackAction,
  CashbackState,
  CashbackSummaryType,
  UnitCashback,
} from './types'

const initialState: CashbackState = {
  unitCashbackList: [],
}

const _getCashbackAmount = (
  cashbackList: UnitCashback[],
  type: SmileCashbackType,
): number => {
  return cashbackList
    .filter((x) => x.type === type)
    .reduce((result, x) => result + x.amount, 0)
}

/**
 * shallowEqual 사용필수
 * @param state
 * @param selectedCartUnitIdList
 */
export const getCashbackSummary = (
  state: CashbackState,
  selectedCartUnitIdList: number[],
): CashbackSummaryType => {
  const { unitCashbackList, cashbackUseEnableDateUnix } = state

  const cashbackList = unitCashbackList
    .filter((x) => selectedCartUnitIdList.includes(x.cartUnitId))
    .flatMap((x) => x.details)

  return {
    itemCashback: _getCashbackAmount(cashbackList, 'Item'),
    sellerCashback: _getCashbackAmount(cashbackList, 'Seller'),
    smilePayClubCashback: _getCashbackAmount(cashbackList, 'SmileClub'),
    smilePayCashback: _getCashbackAmount(cashbackList, 'SmilePay'),
    cartCashback: _getCashbackAmount(cashbackList, 'Cart'),
    clubDayCashback: _getCashbackAmount(cashbackList, 'ClubDay'),
    clubDayCashbackRate:
      cashbackList.find((x) => x.type === 'ClubDay')?.rate || 0,
    ssgPointCashback: _getCashbackAmount(cashbackList, 'SsgPoint'),
    useEnableDateUnix: cashbackUseEnableDateUnix || 0,
    ssgCashbackDate:
      // 주문서와 다르게 장바구니에서는 일반 상품과 신세계포인트 적립 예상 일자가 다른 상품군인 여행/항공 카테고리 상품들도 모두 SsgPoint 캐시백 타입으로 넘어옴
      // 장바구니에서 노출되는 신세계 포인트 적립 일자는 가장 최근 날짜 기준으로 보여준다
      cashbackList
        .filter((x) => x.type === 'SsgPoint')
        .sort((l, r) => new Date(l.date).getTime() - new Date(r.date).getTime())
        .find((x) => x)?.date || '',
  }
}

const cashback = createReducer<CashbackState, CashbackAction>(initialState, {
  [UPDATE_UNIT_CASHBACK_LIST]: (state, { payload }) => {
    return {
      ...state,
      unitCashbackList: state.unitCashbackList
        .filter(
          (x) =>
            !payload.unitCashbackList.some(
              (y) => y.cartUnitId === x.cartUnitId,
            ),
        )
        .concat(payload.unitCashbackList),
      cashbackUseEnableDateUnix: moment(payload.useEnableDate).unix(),
    }
  },
})

export default cashback
