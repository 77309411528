import dataPicker from '~/data/data-picker'
import { Domain, DomainByEnv, DomainCommon } from '~/data/domains'
import siteEnv from '~/lib/site-env'

/**
 * 중국어/일본어는 영어와 다른경우만 여기에 정의
 */
const domains: SiteEnvDataType<Partial<DomainByEnv>>[] = []

const common: Partial<DomainCommon> = {}

const domainsByEnv =
  dataPicker<Partial<DomainByEnv>>(domains, siteEnv, true) || {}

const result: Partial<Domain> = {
  ...domainsByEnv,
  ...common,
}

export default result
