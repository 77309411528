import siteEnv from '~/lib/site-env'
import { compareAppVersion } from '~/lib/utils'
import { AppType } from '~/types/enums'

export default class AppSchemeHelper {
  static getDefaultScheme = (
    isApp: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp) {
        return appType === 'Used' ? 'auctionused://' : 'auction://'
      }
    } else if (siteEnv.tenantType === 'G9') {
      if (isApp) {
        return 'g9mobile://'
      }
    } else if (siteEnv.tenantType === 'Gmarket') {
      if (isApp) {
        if (siteEnv.languageType !== 'Korean') {
          return 'globalgmarket://'
        } else {
          return appType === 'Core' ? 'gmarket://' : undefined
        }
      }
    }
  }
  static getOpenUrlInAppScheme = (
    isApp: boolean,
    isAndroid: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp) {
        return appType === 'Used'
          ? 'auctionused://escrow?returnurl={0}'
          : 'auction://escrow?returnurl={0}'
      }
    } else if (siteEnv.tenantType === 'G9') {
      if (isApp) {
        if (isAndroid) {
          return 'g9mobile://redirect?redirectUrl={0}'
        } else {
          return 'g9iphone://redirect?redirecturl={0}'
        }
      }
    } else if (siteEnv.tenantType === 'Gmarket') {
      if (isApp) {
        if (siteEnv.languageType !== 'Korean') {
          return 'globalgmarket://escrow?returnURL={0}'
        } else {
          return appType === 'SFC'
            ? 'gmarketsfc://escrow?returnURL={0}'
            : 'gmarket://escrow?returnURL={0}'
        }
      }
    }
  }

  static getOpenBrowserScheme = (
    isApp: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp && appType === 'Core') {
        return 'auction://openbrowser?targeturl={0}'
      }
    } else if (siteEnv.tenantType === 'G9') {
      if (isApp) {
        return 'g9mobile://openWindow?targetUrl={0}'
      }
    }
  }
  static getGetContactScheme = (
    isApp: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp && appType === 'Core') {
        return 'auction://getcontact?type=phoneno&callbackjavascript={0}'
      }
    } else if (
      siteEnv.tenantType === 'Gmarket' &&
      siteEnv.languageType === 'Korean'
    ) {
      if (isApp && appType === 'Core') {
        return 'gmarket://getcontact?type=phoneno&callbackjavascript={0}'
      }
    }
  }
  static getCheckAppInstallScheme = (
    isApp: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp && appType === 'Core') {
        return 'auction://checkInstall?app={0}'
      }
    } else if (siteEnv.tenantType === 'G9') {
      if (isApp) {
        return 'g9mobile://checkInstall?app={0}'
      }
    } else if (
      siteEnv.tenantType === 'Gmarket' &&
      siteEnv.languageType === 'Korean'
    ) {
      if (isApp && appType === 'Core') {
        return 'gmarket://checkInstall?app={0}'
      }
    }
  }
  static getPreventRefreshScheme = (
    isApp: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Gmarket' && siteEnv.languageType === 'Korean') {
      if (isApp && appType === 'Core') {
        return 'gmarket://webview?action=useRefresh&value=false'
      }
    }
  }
  static getOpenWindowScheme = (
    isApp: boolean,
    isAndroid: boolean,
    appType?: AppType,
    appVersion?: string,
  ): string | undefined => {
    if (!isApp) {
      return
    }
    if (siteEnv.tenantType === 'Auction') {
      if (isApp && appType === 'Core') {
        return 'auction://openwindow.v2?targeturl={0}&title={1}&new=true'
      }
    } else if (siteEnv.tenantType === 'G9') {
      if (isApp) {
        return 'g9mobile://openWindow?targetUrl={0}'
      }
    } else if (
      siteEnv.tenantType === 'Gmarket' &&
      siteEnv.languageType === 'Korean'
    ) {
      if (isApp && appType === 'Core') {
        return 'gmarket://openwindow?targetUrl={0}&title={1}&new=true'
      }
    } else {
      if (isAndroid) {
        return compareAppVersion(appVersion, '1.6.2') >= 0
          ? 'globalgmarket://openwindow?targetUrl={0}&new=true'
          : 'globalgmarket://escrow?returnURL={0}'
      } else {
        return 'globalgmarket://openwindow?targetUrl={0}'
      }
    }
  }
  static getCloseWindowScheme = (): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      return 'auction://webviewclose'
    } else if (
      siteEnv.tenantType === 'Gmarket' &&
      siteEnv.languageType === 'Korean'
    ) {
      return 'gmarket://webview?action=close'
    } else {
      return 'globalgmarket://webview?action=close'
    }
  }
  static getIOSOpenWindowSimpleScheme = (
    isApp: boolean,
    isAndroid: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp && appType === 'Core' && !isAndroid) {
        return 'auction://openwindow?targeturl={0}&new=true'
      }
    }
  }
  static getOpenCheckoutWebviewScheme = (
    isApp: boolean,
    appType?: AppType,
  ): string | undefined => {
    if (siteEnv.tenantType === 'Auction') {
      if (isApp && appType === 'Core') {
        return 'auction://openwindow?targeturl={0}&type=escrow'
      }
    }
  }
  static getCloseWebViewScheme = (
    isApp: boolean,
    isAndroid: boolean,
  ): string | undefined => {
    if (siteEnv.tenantType === 'G9') {
      if (isApp) {
        if (isAndroid) {
          return 'g9mobile://webviewclose'
        } else {
          return 'g9iphone://webviewclose'
        }
      }
    }
  }
}
