import { Store } from 'redux'

const configureGlobalMessageEventListener = (
  store: Store,
  gatewayHandler?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: (param1?: any, param2?: any) => any
  },
): void => {
  window.globalMessageEventListener = (
    message: Message<typeof gatewayHandler>,
  ): void => {
    const { dispatch } = store

    const objectMessage: MessageObject<typeof gatewayHandler> =
      typeof message === 'string' ? JSON.parse(message) : message

    const { action, param1, param2 } = objectMessage

    if (gatewayHandler && gatewayHandler[action]) {
      dispatch(gatewayHandler[action](param1, param2))
    }
  }
}

export default configureGlobalMessageEventListener
