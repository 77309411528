import { createHashHistory, History } from 'history'
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import Thunk from 'redux-thunk'
import configureGlobalMessageEventListener from '~/lib/global-message-event-listener'
import crashReport from '~/lib/redux-middlewares/crash-report'
import siteEnv from '~/lib/site-env'
import * as gatewayHandler from './complex-actions/gateway-handler'
import reducers from './reducers'
import {
  setHeaderCloseUrl,
  setShowingAppHeader,
  setTitle,
} from './view/actions'
import { INITIAL_TITLE } from './view/reducer'

const history = createHashHistory()
history.replace('/')
window.initialHistoryLength = window.history.length

export default function configureStore(): {
  store: Store
  history: History
} {
  const store = createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(
        crashReport,
        // actionDepsHandler,
        Thunk.withExtraArgument(history),
      ),
    ),
  )

  if (siteEnv.env !== 'production') {
    // 디버깅용
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.checkoutStore = store
  }

  const { dispatch } = store

  history.listen((location) => {
    if (location.pathname === '/') {
      // 최초화면으로 돌아올때 처리될것들은 여기서
      // 좀 많아지면 action 에 따로 빼는게 좋겠다
      dispatch(setTitle(INITIAL_TITLE))
      dispatch(setShowingAppHeader(true))
      dispatch(setHeaderCloseUrl(undefined))
    } else {
      // 초기화면이 아닌경우 헤더의 닫기버튼을 초기화면으로 돌아오도록 세팅한다
      // 단순히 url을 #/ 로 설정하면 뺑뺑이가 되기떄문에 이렇게 설정한다.
      // TODO 정말 이런방법뿐인가..
      dispatch(
        setHeaderCloseUrl(
          `javascript:globalMessageEventListener(
              ${JSON.stringify({
                action: 'handleGoInitialPath',
              })})`,
        ),
      )
    }
  })

  configureGlobalMessageEventListener(store, gatewayHandler)

  return {
    store,
    history,
  }
}
