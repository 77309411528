import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { uniqueId } from 'lodash'
import { mockWrapper, postMockWrapper } from '~/apis/mock'
import domains from '~/data/domains'
import siteEnv from '~/lib/site-env'

const baseUrl = domains.CART_FE_SERVER + '/api'

type ApiDomainType = 'cart-api' | 'ex-api' | 'order-api' | 'cart-fe' | 'etc'
const usableDomains: ApiDomainType[] = [
  'cart-api',
  'ex-api',
  'order-api',
  'cart-fe',
  'etc',
]

interface ResponseType<T> {
  domain?: ApiDomainType
  resultCode?: string
  redirectUrl?: string
  message?: string
  data: T
  nextAction?:
    | 'ORDER_FAIL'
    | 'ALERT'
    | 'ALERT_AND_REDIRECT'
    | 'ALERT_AND_REFRESH'
    | 'REDIRECT'
}

export function isAxiosError(e: Error): e is AxiosError {
  return (e as AxiosError).isAxiosError
}

const UNKNOWN_ERROR_RES_CODE = '9999'
const SUCCESS_RES_CODE = '0000'

const DEFAULT_TIMEOUT =
  siteEnv.env === 'production' || siteEnv.env === 'av' ? 3000 : 10000

const mergeConfig: (config?: AxiosRequestConfig) => AxiosRequestConfig = (
  config,
) => {
  const newConfig = config || {}

  return {
    timeout: DEFAULT_TIMEOUT,
    baseURL: baseUrl,
    headers: {
      'Cache-Control': 'no-cache',
      ...config?.headers,
    },
    ...newConfig,
  }
}

export class ApiError<T = unknown> extends Error {
  domain?: ApiDomainType
  resultCode: string
  requestUrl?: string
  data?: T
  redirectUrl?: string
  nextAction?:
    | 'ORDER_FAIL'
    | 'ALERT'
    | 'ALERT_AND_REDIRECT'
    | 'ALERT_AND_REFRESH'
    | 'REDIRECT'
  status?: number

  public constructor(
    url?: string,
    domain?: ApiDomainType,
    resultCode: string = UNKNOWN_ERROR_RES_CODE,
    data?: T,
    redirectUrl?: string,
    message?: string,
    nextAction?:
      | 'ORDER_FAIL'
      | 'ALERT'
      | 'ALERT_AND_REDIRECT'
      | 'ALERT_AND_REFRESH'
      | 'REDIRECT',
    status?: number,
  ) {
    super(message)
    this.domain = domain
    this.resultCode = resultCode
    this.requestUrl = url
    this.data = data
    this.redirectUrl = redirectUrl
    this.nextAction = nextAction
    this.status = status
  }
}

const isResponseTypeError = function <T>(
  data: Error | ResponseType<T>,
): data is ResponseType<T> {
  const castedData = data as {
    domain?: ApiDomainType
    resultCode?: string
  }

  return (
    !!castedData.domain &&
    !!castedData.resultCode &&
    usableDomains.indexOf(castedData.domain) >= 0
  )
}

const success = <T>(response: AxiosResponse<ResponseType<T>>): T => {
  if (response.data.resultCode === SUCCESS_RES_CODE) {
    return response.data.data
  } else if (
    response.data.domain &&
    response.data.resultCode &&
    usableDomains.indexOf(response.data.domain) >= 0
  ) {
    throw new ApiError(
      response.config.url,
      response.data.domain,
      response.data.resultCode,
      response.data.data,
      response.data.redirectUrl,
      response.data.message,
      response.data.nextAction,
      200,
    )
  } else {
    throw new ApiError(response.config.url)
  }
}

const errorHandler = <T>(error: AxiosError<Error | ResponseType<T>>): never => {
  if (
    error.response &&
    error.response.data &&
    isResponseTypeError(error.response.data)
  ) {
    throw new ApiError(
      error.response.config.url,
      error.response.data.domain,
      error.response.data.resultCode,
      error.response.data.data,
      error.response.data.redirectUrl,
      error.response.data.message,
      error.response.data.nextAction,
      error.response.status,
    )
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    throw new Error(error.response.data.message)
  }

  throw error
}

export class AxiosClient {
  apiCalls: { [key: string]: string | undefined }
  api: AxiosInstance

  /**
   * Creates an instance of Api.
   *
   * @param {import('axios').AxiosRequestConfig} [config] - axios configuration.
   * @memberof Api
   */
  public constructor(config?: AxiosRequestConfig) {
    this.apiCalls = {}
    this.api = axios.create(mergeConfig(config))

    this.getUri = this.getUri.bind(this)
    this.request = this.request.bind(this)
    this.get = mockWrapper(this.get.bind(this))
    this.delete = mockWrapper(this.delete.bind(this))
    this.head = mockWrapper(this.head.bind(this))
    this.post = postMockWrapper(this.post.bind(this))
    this.put = mockWrapper(this.put.bind(this))
    this.patch = mockWrapper(this.patch.bind(this))
  }

  /**
   * Get Uri
   *
   * @param {import('axios').AxiosRequestConfig} [config]
   * @returns {string}
   * @memberof Api
   */
  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config)
  }

  /**
   * Generic request.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} - HTTP axios response payload.
   * @memberof Api
   *
   * @example
   * api.request({
   *   method: "GET|POST|DELETE|PUT|PATCH"
   *   baseUrl: "http://www.domain.com",
   *   url: "/api/v1/users",
   *   headers: {
   *     "Content-Type": "application/json"
   *  }
   * }).then((response: AxiosResponse<User>) => response.data)
   *
   */
  public request<T>(config: AxiosRequestConfig): Promise<T> {
    return this.api
      .request<ResponseType<T>>(config)
      .then(success)
      .catch(errorHandler)
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} HTTP `axios` response payload.
   * @memberof Api
   */
  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.api
      .get<ResponseType<T>>(url, config)
      .then(success)
      .catch((e) => {
        if (e.message === 'Network Error') {
          e.message = e.message + ` (GET ${url})`
        }
        return errorHandler(e)
      })
  }

  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.api
      .delete<ResponseType<T>>(url, config)
      .then(success)
      .catch(errorHandler)
  }

  /**
   * HTTP HEAD method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public head<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.api
      .head<ResponseType<T>>(url, config)
      .then(success)
      .catch(errorHandler)
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @param antiDuplicationType
   * @param antiDuplicationKey
   * @returns {Promise<T>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public post<T, B = undefined>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
    antiDuplicationType?: 'SendFirstReqOnly' | 'TakeLastResOnly',
    antiDuplicationKey?: string,
  ): Promise<T> {
    const apiId = uniqueId('anti_duple_id_')
    const antiKey = antiDuplicationKey || url
    if (antiDuplicationType) {
      if (
        antiDuplicationType === 'SendFirstReqOnly' &&
        this.apiCalls[antiKey]
      ) {
        return new Promise<T>(() => {
          console.log(`duplicated [${antiKey}] request was blocked`)
          // DO NOTHING
        })
      }

      this.apiCalls[antiKey] = apiId
    }

    return this.api
      .post<ResponseType<T>>(url, data, config)
      .then((res) => {
        if (
          antiDuplicationType === 'TakeLastResOnly' &&
          this.apiCalls[antiKey] !== apiId
        ) {
          return new Promise<T>(() => {
            console.log(`duplicated [${antiKey}] response was ignored`)
            // DO NOTHING
          })
        } else {
          return success(res)
        }
      })
      .catch((e) => {
        if (
          antiDuplicationType === 'TakeLastResOnly' &&
          this.apiCalls[antiKey] !== apiId
        ) {
          return new Promise<T>(() => {
            console.log(`duplicated [${antiKey}] error was ignored`)
            // DO NOTHING
          })
        } else {
          if (e.message === 'Network Error') {
            e.message = e.message + ` (POST ${baseUrl + url})`
          }
          return errorHandler(e)
        }
      })
      .finally(() => {
        if (antiDuplicationType && this.apiCalls[antiKey] === apiId) {
          delete this.apiCalls[antiKey]
        }
      })
  }

  /**
   * HTTP PUT method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public put<T, B>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.api
      .put<ResponseType<T>>(url, data, config)
      .then(success)
      .catch(errorHandler)
  }

  /**
   * HTTP PATCH method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import('axios').AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<T>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public patch<T, B>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return this.api
      .patch<ResponseType<T>>(url, data, config)
      .then(success)
      .catch(errorHandler)
  }
}

const axiosClient = new AxiosClient()

export default axiosClient
