/* eslint-disable */

export default {
  loadScriptByOrder: true,
  isRenderFooterAfterHeader: true,
  headerScriptSources: [
    'https://script.gmarket.co.kr/_Net/js/global/Renew/GlobalHeader.js',
    'https://script.gmarket.co.kr/_Net/js/global/Renew/GlobalFooter.js',
    'https://script.gmarket.co.kr/_Net/js/global/Renew/GlobalWing.js',
  ],
  footerScriptSources: [],
  /**
   * @param userInfo {checkoutFrontendAPI.GmarketHeaderUserInfo|undefined}
   */
  preRenderHeader: (userInfo) => {
    window.headerKey = 'ESCROW'
    if (userInfo) {
      window.GlobalHeaderComm = {
        Member: {
          // 로그인상태: 0(비로그인), 1(회원로그인/비회원로그인)
          IsLogin: userInfo.type !== 'unknown' ? '1' : '0',
          // 회원 등급
          Grade: userInfo.grade,
          // 회원 이름 => 이름에 quotes 가 포함되는 경우가 있어서 복잡하게 인코딩/디코딩
          Name: userInfo.name,
          // 유형: 0(비로그인), 1(회원로그인), 2(비회원로그인)
          Type:
            userInfo.type === 'member'
              ? '1'
              : userInfo.type === 'nonmember'
              ? '2'
              : '0',
        },
      }
    }
  },
  renderHeader: (arg1, userInfo) => {},

  renderFooter: () => {
    // DO NOTHING
  },

  releaseHeader: () => {
    // DO NOTHING
  },

  releaseFooter: () => {
    // DO NOTHING
  },

  setHeaderTitle: (title, isCoreApp) => {
    // DO NOTHING
  },

  setCloseUrl: (url) => {
    // DO NOTHING
  },
  hideAppHeader: (title) => {
    // DO NOTHING
  },
  showAppHeader: (title) => {
    // DO NOTHING
  },
}
