import { createStandardAction } from 'typesafe-actions'
import { AvailableGroupCoupon, RecommendedGroupCouponMap } from './types'

export const UPDATE_AVAILABLE_GROUP_COUPON =
  'groupCoupon/UPDATE_AVAILABLE_GROUP_COUPON'
export const UPDATE_AVAILABLE_GROUP_COUPON_MULTI =
  'groupCoupon/UPDATE_AVAILABLE_GROUP_COUPON_MULTI'
export const APPLY_GROUP_COUPONS = 'groupCoupon/APPLY_GROUP_COUPONS'
export const CLEAR_ALL_GROUP_COUPONS = 'groupCoupon/CLEAR_ALL_GROUP_COUPONS'
export const SET_TOTAL_GROUP_COUPON_COUNT =
  'groupCoupon/SET_TOTAL_GROUP_COUPON_COUNT'
export const SET_DOWNLOADED_GROUP_COUPON_ISSUE_NO =
  'groupCoupon/SET_DOWNLOADED_GROUP_COUPON_ISSUE_NO'
export const UPDATE_RECOMMENDED_GROUP_COUPONS =
  'groupCoupon/UPDATE_RECOMMENDED_GROUP_COUPONS'
export const CLEAR_RECOMMENDED_GROUP_COUPONS =
  'groupCoupon/CLEAR_RECOMMENDED_GROUP_COUPONS'
export const SET_DOWNLOADED_RECOMMENDED_GROUP_COUPON =
  'groupCoupon/SET_DOWNLOADED_RECOMMENDED_GROUP_COUPON'
export const SET_IS_AUTO_DOWNLOADED_GROUP_COUPON =
  'groupCoupon/SET_IS_AUTO_DOWNLOADED_GROUP_COUPON'

export const updateAvailableGroupCoupon = createStandardAction(
  UPDATE_AVAILABLE_GROUP_COUPON,
)<AvailableGroupCoupon>()

export const updateAvailableGroupCouponMulti = createStandardAction(
  UPDATE_AVAILABLE_GROUP_COUPON_MULTI,
)<AvailableGroupCoupon[]>()

export const applyGroupCoupons = createStandardAction(APPLY_GROUP_COUPONS)<
  {
    couponIssueNo: number
    cartUnitIdList?: number[]
  }[]
>()

export const clearAllGroupCoupons = createStandardAction(
  CLEAR_ALL_GROUP_COUPONS,
)<{
  cartUnitIdList: number[]
}>()

export const setTotalGroupCouponCount = createStandardAction(
  SET_TOTAL_GROUP_COUPON_COUNT,
)<number>()

export const setDownloadedGroupCouponIssueNo = createStandardAction(
  SET_DOWNLOADED_GROUP_COUPON_ISSUE_NO,
)<{ cartUnitIdList: number[]; couponIssueNo: number; couponPolicyNo: number }>()

export const updateRecommendedGroupCoupons = createStandardAction(
  UPDATE_RECOMMENDED_GROUP_COUPONS,
)<RecommendedGroupCouponMap>()

export const clearRecommendedGroupCoupons = createStandardAction(
  CLEAR_RECOMMENDED_GROUP_COUPONS,
)()

export const setDownloadedRecommendedGroupCoupon = createStandardAction(
  SET_DOWNLOADED_RECOMMENDED_GROUP_COUPON,
)<{
  key: string
  policyNo: number
  issueNo: number
}>()

export const setIsAutoDownloadedGroupCoupon = createStandardAction(
  SET_IS_AUTO_DOWNLOADED_GROUP_COUPON,
)<{
  cartUnitIdList: number[]
  couponIssueNo: number
  isAutoDownloadedCoupon: boolean
}>()
