import React, { DetailedHTMLProps } from 'react'

type CustomAnchorProps = DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

const CustomAnchor = (props: CustomAnchorProps): JSX.Element => {
  const { href, onClick } = props
  const newHref = href || '#'

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    if (!href) {
      e.preventDefault()
    }

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <a {...props} href={newHref} onClick={clickHandler}>
      {props.children}
    </a>
  )
}

export default CustomAnchor
