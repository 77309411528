import React from 'react'
import { CartUnit } from '~/cart/modules/cart/types'
import tenantConstants from '~/data/checkout-constants'
import domains from '~/data/domains'
import { formatString } from '~/lib/formatter'
import siteEnv from '~/lib/site-env'

/**
 * 앱 설치 체크
 */
export const checkAppInstall = (scheme: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    let isResolved = false
    const eventListener = (message: MessageEvent): void => {
      if (message.data && message.data.method === 'checkInstall') {
        if (!isResolved) {
          isResolved = true
          window.removeEventListener('message', eventListener)
          const result =
            typeof message.data.result === 'boolean'
              ? message.data.result
              : message.data.result === 'true'
          resolve(result)
        }
      }
    }
    window.addEventListener('message', eventListener)

    // 2초 timeout 처리
    window.setTimeout(() => {
      if (!isResolved) {
        isResolved = true
        window.removeEventListener('message', eventListener)
        reject('timeout')
      }
    }, 2000)

    window.location.href = scheme
  })

/**
 * 앱이 특정 상태가 될 때 까지 기다리는 함수
 * 현재 옥션 iOS 앱에서 Foreground Active 상태만 작동한다
 * @param appStatus
 * @param timeout
 */
export const waitUntilAppStatus = async (
  appStatus: AppStatus,
  timeout: number,
): Promise<void> => {
  const interval = 100
  let cnt = 0
  while (
    window.appStatus &&
    window.appStatus !== appStatus &&
    cnt++ < timeout / interval
  ) {
    await new Promise((resolve) => window.setTimeout(resolve, interval))
  }
  if (cnt >= timeout / interval) {
    throw new Error('app status check timeout')
  }
}

export const getPhoneContacts = (
  scheme: string,
): Promise<{
  name: string
  phoneNumber: string
}> =>
  new Promise((resolve) => {
    window.callbackAppContact = (name: string, phoneNumber: string): void => {
      if (name && phoneNumber) {
        const fixedPhoneNumber = phoneNumber
          .replace(/[^\d]/g, '')
          .replace(/^\+?82/, '0') // 국내휴대폰번호에 +82가 붙어서 오는 경우가 있다
        delete window.callbackAppContact
        resolve({
          name,
          phoneNumber: fixedPhoneNumber,
        })
      }
    }

    window.location.href = formatString(scheme, 'callbackAppContact')
  })

export const focusOrScrollIntoView = (
  elementOrId: string | HTMLElement,
): HTMLElement | undefined => {
  const element =
    typeof elementOrId === 'string'
      ? document.getElementById(elementOrId)
      : elementOrId

  const scrollPosition =
    typeof elementOrId === 'string'
      ? tenantConstants.ElementScrollOptions[elementOrId]
      : undefined

  if (element) {
    if (scrollPosition) {
      window.scrollTo(scrollPosition)
    } else {
      element.scrollIntoView({
        block: 'center',
      })
    }
    if (
      element instanceof HTMLInputElement ||
      element instanceof HTMLTextAreaElement ||
      element instanceof HTMLButtonElement ||
      element instanceof HTMLAnchorElement ||
      element instanceof HTMLSelectElement
    ) {
      element.focus()
    }
    return element
  }
}

export const parseKoreanAddress = (
  address: string,
):
  | {
      level1?: string
      level2?: string
    }
  | undefined => {
  const regexResult = address.match(
    /^([^가-힣A-Za-z0-9]+)?((서울|대전|대구|부산|광주|울산|인천|세종|제주|경기|강원|충청?북도?|충청?남도?|경상?북도?|경상?남도?|전라?북도?|전라?남도?)((특별|광역)?(자치)?([시도]))?)\s+(([가-힣]{1,3}([시군구]))(\s|$))?/,
  )
  if (regexResult) {
    return {
      level1: regexResult[3] || undefined, // 시/도 광역자치단체 ex)서울특별시/경기도
      level2: regexResult[9] || undefined, // 시/군/구 기초자치단체 ex)강남구, 성남시
    }
  }
}

export const isCapitalArea = (
  address: string | undefined,
  countryCode: string,
): boolean => {
  if (!address || countryCode.toUpperCase() !== 'KR') {
    return false
  }
  const parsedAddress = parseKoreanAddress(address)
  if (parsedAddress && parsedAddress.level1) {
    if (/^(서울|경기)/.test(parsedAddress.level1)) {
      return true
    }
  }
  return false
}

export const getCurrentUrl = (encode = false): string => {
  const url =
    window.location.origin + window.location.pathname + window.location.search
  return encode ? encodeURIComponent(url) : url
}

export const goBackOrClose = (): void => {
  if (window.history.length > 1) {
    try {
      window.history.back()
    } catch (e) {
      console.error(e)
    }
  } else {
    window.setTimeout(() => {
      try {
        window.close()
      } catch (e) {
        console.error(e)
      }

      if (siteEnv.tenantType === 'Auction') {
        if (
          /mobileapp/i.test(window.navigator.userAgent) &&
          /auction/i.test(window.navigator.userAgent)
        ) {
          try {
            window.location.href = 'auction://webviewclose'
          } catch (e) {
            console.error(e)
          }
        }
      }
    }, 500)
  }
}

/**
 * version1 이 높은 경우 1
 * version2 이 높은 경우 -1
 * 동일한 경우 0
 * 하나라도 없는경우 NaN
 * @param version1
 * @param version2
 */
export const compareAppVersion = (
  version1?: string,
  version2?: string,
): number => {
  if (version1 && version2) {
    const v1List = version1.split('.')
    const v2List = version2.split('.')
    const count = Math.max(v1List.length, v2List.length)
    for (let i = 0; i < count; i++) {
      const v1 = parseInt(v1List[i]) || 0
      const v2 = parseInt(v2List[i]) || 0
      if (v1 > v2) {
        return 1
      } else if (v1 < v2) {
        return -1
      }
    }
    return 0
  } else {
    return NaN
  }
}

export const getWidthStyle = (width: number): React.CSSProperties => ({
  width: `${width}px`,
})

const iosCopyToClipboard = (el: HTMLTextAreaElement): void => {
  const oldContentEditable = el.contentEditable
  const oldReadOnly = el.readOnly
  const range = document.createRange()

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  el.contentEditable = true
  el.readOnly = false
  range.selectNodeContents(el)

  const s = window.getSelection()
  if (s) {
    s.removeAllRanges()
    s.addRange(range)
  }

  el.setSelectionRange(0, 999999) // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable
  el.readOnly = oldReadOnly

  document.execCommand('copy')
}

export const copyToClipboard = (text: string): Promise<void> =>
  new Promise((resolve) => {
    const input = document.createElement('textarea')
    input.style.position = 'fixed'
    input.style.top = '0'
    input.style.left = '0'
    input.style.width = '2em'
    input.style.height = '2em'
    input.style.padding = '0'
    input.style.border = 'none'
    input.style.outline = 'none'
    input.style.boxShadow = 'none'
    input.style.background = 'transparent'
    const parent = document.body

    parent.appendChild(input)
    input.value = text
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      iosCopyToClipboard(input)
    } else {
      input.select()
      document.execCommand('copy')
    }
    parent.removeChild(input)
    window.setTimeout(() => {
      resolve()
    }, 0)
  })

export const openUrlFromHiddenIframe = (url: string): Promise<void> =>
  new Promise((resolve) => {
    const iframe = document.createElement('iframe')
    iframe.setAttribute(
      'style',
      'height: 0;margin:0;padding: 0;opacity: 0;bottom: 0;position: fixed;',
    )
    iframe.src = url
    iframe.onload = (): void => {
      resolve()
    }

    const parent = document.body
    parent.appendChild(iframe)
  })

export const getValidUrlOrText = (
  source?: string,
): { url?: string; text?: string } => {
  if (source) {
    const urlMatch = source && source.match(/^(http(s)?:\/\/)?[\w-]+\.[\w]+/)
    if (urlMatch) {
      return {
        url: urlMatch[1] ? source : `http://${source}`,
      }
    } else {
      return {
        text: source,
      }
    }
  }
  return {}
}

export const loadScript = (src: string): Promise<HTMLScriptElement> => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src

    document.body.appendChild(script)

    script.onload = (): void => {
      resolve(script)
    }
  })
}

export const sendGoogleScriptByTagManager = (
  eventName: string,
  hashedCguid?: string | null,
  isApp?: boolean,
  selectedCartUnitList?: CartUnit[],
): void => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (typeof window.dataLayer !== 'undefined') {
      if (eventName === 'PAGE_VIEW') {
        const pageViewValue = hashedCguid // 마케팅 동의 정보 미동의 xor 비회원 or 존재하지 않는 회원 등은 값이 없어, 이 경우 제외하고 보내준다
          ? {
              event: 'cart',
              userId: hashedCguid,
            }
          : {
              event: 'cart',
            }
        // WebView Tagging 추가 (mobile only)
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (typeof window.logEvent !== 'undefined' && isApp) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.logEvent('cart', pageViewValue)
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.dataLayer.push(pageViewValue)
        }
      } else if (eventName === 'REMOVE_FROM_CART') {
        if (selectedCartUnitList) {
          let totalPrice = 0
          selectedCartUnitList.forEach(
            (cartUnit) =>
              (totalPrice += cartUnit.quantity * cartUnit.item.itemSellPrice),
          )
          const areaValue = {
            event: 'remove_from_cart',
            ecommerce: hashedCguid
              ? {
                  service: 'cart',
                  currency: 'KRW',
                  value: totalPrice,
                  userId: hashedCguid,
                  items: selectedCartUnitList.map((cartUnit) => {
                    return {
                      affiliation: cartUnit.seller.sellerName,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_category: cartUnit.item.largeCategoryCode,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_category2: cartUnit.item.mediumCategoryCode,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_category3: cartUnit.item.smallCategoryCode,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_id: cartUnit.item.itemNo,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_name: cartUnit.item.itemName,
                      price: cartUnit.item.itemSellPrice,
                      quantity: cartUnit.quantity,
                    }
                  }),
                }
              : {
                  service: 'cart',
                  currency: 'KRW',
                  value: totalPrice,
                  items: selectedCartUnitList.map((cartUnit) => {
                    return {
                      affiliation: cartUnit.seller.sellerName,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_category: cartUnit.item.largeCategoryCode,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_category2: cartUnit.item.mediumCategoryCode,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_category3: cartUnit.item.smallCategoryCode,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_id: cartUnit.item.itemNo,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      item_name: cartUnit.item.itemName,
                      price: cartUnit.item.itemSellPrice,
                      quantity: cartUnit.quantity,
                    }
                  }),
                },
          }

          // WebView Tagging 추가 (mobile only)
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          if (typeof window.logEvent !== 'undefined' && isApp) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window.logEvent('remove_from_cart', areaValue)
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window.dataLayer.push(areaValue)
          }
        }
      }
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * @param src
 * @param sizePerPage
 * @param maxPage
 * @param floor 버림 여부
 */
export const groupArrayBySize = <T>(
  src: T[],
  sizePerPage: number,
  maxPage = Infinity,
  floor?: boolean,
): T[][] => {
  return src
    .reduce<T[][]>((result, item, idx) => {
      if (idx % sizePerPage !== 0) {
        result[result.length - 1].push(item)
      } else {
        result.push([item])
      }
      return result
    }, [])
    .filter((x) => !floor || x.length === sizePerPage)
    .slice(0, maxPage)
}

export const goSignIn = (): void => {
  window.location.href = formatString(
    domains.SIGN_IN,
    domains.NOT_ENCODE_SIGN_IN_RETURN_URL
      ? window.location.href
      : encodeURIComponent(window.location.href),
  )
}

export const goVIP = (itemNo: string): void => {
  window.location.href = formatString(domains.VIP, itemNo)
}

export const isHighPriority = <T>(
  priorities: T[],
  target: T,
  compare: T,
): boolean => {
  return priorities.indexOf(target) > priorities.indexOf(compare)
}

export const getHighPriority = <T>(
  priorities: T[],
  target: T,
  compare: T,
): T => {
  return isHighPriority(priorities, target, compare) ? target : compare
}

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
