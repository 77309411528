import React, { DetailedHTMLProps } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleCartSelectorComplex } from '~/cart/modules/complex-actions'
import { getIsAllSelected, RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'

type CartSelectorProps = {
  cartUnitIdList?: number[]
  wrapperTitle?: string
  showingTitle?: boolean
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const CartSelector = (props: CartSelectorProps): JSX.Element => {
  const { cartUnitIdList, wrapperTitle, showingTitle, children, ...elseProps } =
    props
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isAllSelected = useSelector((state: RootState) =>
    getIsAllSelected(state, cartUnitIdList),
  )

  const onClickToggleSelectButton = (): void => {
    dispatch(toggleCartSelectorComplex(cartUnitIdList))
  }

  return (
    <span className="input_custom" title={wrapperTitle}>
      <input
        {...elseProps}
        type="checkbox"
        className="input__checkbox sprite__cart"
        checked={isAllSelected}
        onChange={onClickToggleSelectButton}
      />
      {showingTitle ? (
        <label htmlFor={elseProps.id}>{children}</label>
      ) : (
        <label htmlFor={elseProps.id} className="for_a11y">
          {elseProps.title}
        </label>
      )}
    </span>
  )
}

export default CartSelector
