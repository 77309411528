import axiosClient from '~/lib/axios-client'

export const fetchG9SignOut = async (): Promise<{
  success: boolean
  signOutUrl: string
}> => axiosClient.post('/buyer/g9SignOut')

export const fetchGetUserInfoForGmarketHeader = async (): Promise<checkoutFrontendAPI.GmarketHeaderUserInfo> =>
  axiosClient.get('/buyer/getUserInfoForGmarketHeader')

export const fetchHasOrderHistory = async (): Promise<boolean> =>
  axiosClient.post('/buyer/hasOrderHistory')

export const fetchGetHashedCguid = async (): Promise<Nullable<string>> => {
  return axiosClient.get('/buyer/getHashedCguid')
}

export const fetchGetUnifyMembershipInfo = async (): Promise<cartAPI.SmileclubUnifyMembershipInfoResponse> =>
  axiosClient.post('/buyer/getUnifyMembershipInfo')
