import React, { useEffect } from 'react'
import CouponReApplyNotiToast from '~/cart/gmarket/ko/common/containers/Toast/CouponReApplyNotiToast'
import ExhibitDiscountToast from '~/cart/gmarket/ko/common/containers/Toast/ExhibitDiscountToast'
import HitBuyBoxToast from '~/cart/gmarket/ko/common/containers/Toast/HitBuyBoxToast'
import SmileDeliveryCutOffNotiToast from '~/cart/gmarket/ko/common/containers/Toast/SmileDeliveryCutOffNotiToast'
import logRender from '~/lib/log-render'
import UXEHelper from '~/lib/uxe-helper'

const ToastContainer = (): JSX.Element => {
  logRender()

  useEffect(() => {
    if (UXEHelper && UXEHelper.onLottiePaymentMethod) {
      UXEHelper.onLottiePaymentMethod()
    }
  }, [])
  return (
    <>
      <ExhibitDiscountToast />
      <SmileDeliveryCutOffNotiToast />
      <CouponReApplyNotiToast />
      <HitBuyBoxToast />
    </>
  )
}

export default ToastContainer
