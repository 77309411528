import { createStandardAction } from 'typesafe-actions'
import { FundingDiscount, FundingDiscountState } from './types'

export const INIT_FUNDING_DISCOUNT = 'fundingDiscount/INIT_FUNDING_DISCOUNT'
export const UPDATE_AVAILABLE_FUNDING_DISCOUNT =
  'fundingDiscount/UPDATE_AVAILABLE_FUNDING_DISCOUNT'
export const APPLY_FUNDING_DISCOUNT = 'fundingDiscount/APPLY_FUNDING_DISCOUNT'

export const initFundingDiscount = createStandardAction(INIT_FUNDING_DISCOUNT)<
  Partial<FundingDiscountState>
>()

export const updateAvailableFundingDiscount = createStandardAction(
  UPDATE_AVAILABLE_FUNDING_DISCOUNT,
)<
  {
    cartUnitId: number
    fundingDiscounts: FundingDiscount[]
  }[]
>()

export const applyFundingDiscount = createStandardAction(
  APPLY_FUNDING_DISCOUNT,
)<
  {
    cartUnitId: number
    appliedPolicyNo?: number
  }[]
>()
