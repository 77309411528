import { createStandardAction } from 'typesafe-actions'
import { OverseaShippingCompanyType, ShippingCountryType } from '~/types/enums'
import {
  BundleShippingFee,
  LatestShippingAddress,
  OverseaShippingCompany,
  OverseaShippingFee,
  ShippingCountry,
  ShippingPolicy,
  ShippingState,
  SmileDeliveryTransPolicy,
  TransPolicy,
} from './types'

export const INIT_SHIPPING = 'shipping/INIT_SHIPPING'
export const UPDATE_BUNDLE_SHIPPING_FEE_LIST =
  'shipping/UPDATE_BUNDLE_SHIPPING_FEE_LIST'
export const UPDATE_DEFAULT_ITEM_SHIPPING_FEE_IS_FREE_LIST =
  'shipping/UPDATE_DEFAULT_ITEM_SHIPPING_FEE_IS_FREE_LIST'
export const UPDATE_SHIPPING_POLICY_LIST =
  'shipping/UPDATE_SHIPPING_POLICY_LIST'
export const SET_TRANS_POLICY_MAP = 'shipping/SET_TRANS_POLICY_MAP'
export const SET_SMILE_DELIVERY_TRANS_POLICY = 'shipping/SET_TRANS_POLICY'
export const SET_SMILE_DELIVERY_SHIPPING_TYPE =
  'shipping/SET_SMILE_DELIVERY_SHIPPING_TYPE'
export const SET_OVERSEA_SHIPPING_COUNTRIES =
  'shipping/SET_OVERSEA_SHIPPING_COUNTRIES'
export const UPDATE_AVAILABLE_OVERSEA_SHIPPING_COMPANIES =
  'shipping/UPDATE_AVAILABLE_OVERSEA_SHIPPING_COMPANIES'
export const SELECT_OVERSEA_SHIPPING_COMPANY =
  'shipping/SELECT_OVERSEA_SHIPPING_COMPANY'
export const SET_OVERSEA_SHIPPING_FEES = 'shipping/SET_OVERSEA_SHIPPING_FEES'
export const SET_CURRENT_OVERSEA_SHIPPING_FEES =
  'shipping/SET_CURRENT_OVERSEA_SHIPPING_FEES'
export const UPDATE_SHIPPING_UNAVAILABLE_CART_UNITS =
  'shipping/UPDATE_SHIPPING_UNAVAILABLE_CART_UNITS'
export const SET_LATEST_SHIPPING_ADDRESS =
  'shipping/SET_LATEST_SHIPPING_ADDRESS'
export const SET_SHIPPING_COUNTRY = 'shipping/SET_SHIPPING_COUNTRY'

export const initShipping = createStandardAction(INIT_SHIPPING)<
  Partial<ShippingState>
>()
export const updateBundleShippingFeeList = createStandardAction(
  UPDATE_BUNDLE_SHIPPING_FEE_LIST,
)<BundleShippingFee[]>()
export const updateDefaultItemShippingFeeIsFreeList = createStandardAction(
  UPDATE_DEFAULT_ITEM_SHIPPING_FEE_IS_FREE_LIST,
)<{ itemNo: string; isFreeShipping: boolean }[]>()
export const updateShippingPolicyList = createStandardAction(
  UPDATE_SHIPPING_POLICY_LIST,
)<ShippingPolicy[]>()
export const setTransPolicyMap = createStandardAction(SET_TRANS_POLICY_MAP)<{
  [itemNo: string]: TransPolicy
}>()
export const setSmileDeliveryTransPolicy = createStandardAction(
  SET_SMILE_DELIVERY_TRANS_POLICY,
)<{
  map: { [itemNo: string]: SmileDeliveryTransPolicy }
}>()
export const setOverseaShippingCountries = createStandardAction(
  SET_OVERSEA_SHIPPING_COUNTRIES,
)<ShippingCountry[]>()
export const updateAvailableOverseaShippingCompanies = createStandardAction(
  UPDATE_AVAILABLE_OVERSEA_SHIPPING_COMPANIES,
)<OverseaShippingCompany[]>()
export const selectOverseaShippingCompany = createStandardAction(
  SELECT_OVERSEA_SHIPPING_COMPANY,
)<OverseaShippingCompanyType>()
export const setOverseaShippingCosts = createStandardAction(
  SET_OVERSEA_SHIPPING_FEES,
)<
  {
    shippingCompany: OverseaShippingCompanyType
    shippingFees: OverseaShippingFee[]
  }[]
>()
export const setCurrentOverseaShippingCosts = createStandardAction(
  SET_CURRENT_OVERSEA_SHIPPING_FEES,
)<
  {
    shippingCompany: OverseaShippingCompanyType
    shippingFees: OverseaShippingFee[]
  }[]
>()
export const updateShippingUnavailableCartUnits = createStandardAction(
  UPDATE_SHIPPING_UNAVAILABLE_CART_UNITS,
)<
  {
    shippingCompany: OverseaShippingCompanyType
    unavailableCartUnitIds: number[]
  }[]
>()
export const setLatestShippingAddress = createStandardAction(
  SET_LATEST_SHIPPING_ADDRESS,
)<LatestShippingAddress | undefined>()
export const setShippingCountry = createStandardAction(
  SET_SHIPPING_COUNTRY,
)<ShippingCountryType>()
