import axiosClient from '~/lib/axios-client'

export const fetchAddCart = async (
  request: cartAPI.AddCartUnitsCommandRequest,
): Promise<cartAPI.AddCartUnitsCommandResponse> => {
  return axiosClient.post('/cart/addCart', request)
}

export const fetchGetCart = async (
  cartUnitIdList?: number[],
): Promise<
  cartAPI.GetCartResponse & {
    isBusinessBuyer: boolean
    encodedSellerKeys?: { [sellerKey: string]: string | undefined }
  }
> => {
  return axiosClient.post(
    '/cart/getCart',
    {
      cartUnitIdList,
    },
    {
      timeout: 5000, // getCheckout 은 오래걸릴수 있어서 따로 정의됨. 기본 3초이니 특별한 이유 없으면 제거할것
    },
  )
}

export const fetchMerge = async (): Promise<cartAPI.CommandResult> => {
  return axiosClient.post('/cart/merge')
}

export const fetchApplyCoupons = async (
  request: cartAPI.ApplyCouponsCommandRequest,
): Promise<cartAPI.CommandResult> => {
  return axiosClient.post('/cart/applyCoupons', request)
}

export const fetchRemoveCartUnits = async (
  request: cartAPI.RemoveCartUnitsCommandRequest,
): Promise<cartAPI.CommandResult> => {
  return axiosClient.post('/cart/removeCartUnits', request)
}

export const fetchSetQuantity = async (
  request: cartAPI.SetUnitQuantityCommandRequest,
): Promise<cartAPI.CommandResult> => {
  return axiosClient.post(
    '/cart/setQuantity',
    request,
    undefined,
    'TakeLastResOnly',
    'setQuantity_' + request.cartUnitId,
  )
}

export const fetchUnapplyCoupons = async (
  request: cartAPI.UnapplyCouponsCommandRequest,
): Promise<cartAPI.CommandResult> => {
  return axiosClient.post('/cart/unapplyCoupons', request)
}

export const fetchHideNudging = async (
  request: cartAPI.HideCartNudgingCommandRequest,
): Promise<cartAPI.CommandResult> => {
  return axiosClient.post('/cart/hideNudging', request)
}

export const fetchReplace = async (
  request: cartFE.ReplaceCartRequest,
): Promise<cartFE.ReplaceCartResponse> => {
  return axiosClient.post('/cart/replace', request)
}
