/* eslint-disable */

export default {
  loadScriptByOrder: true,
  headerScriptSources: [],
  footerScriptSources: [],
  renderHeader: (arg1, userInfo) => {
    DesktopLayout.render([
      {
        name: 'Header',
        params: { type: 'sub', user: userInfo, isAdult: false },
        container: document.getElementById('header'),
      },
      {
        name: 'Footer',
        params: { type: 'default' },
        container: document.getElementById('footer'),
      },
    ])
  },

  renderFooter: () => {
    // DO NOTHING
  },

  releaseHeader: () => {
    // DO NOTHING
  },

  releaseFooter: () => {
    // DO NOTHING
  },

  setHeaderTitle: (title, isCoreApp) => {
    // DO NOTHING
  },

  setCloseUrl: (url) => {
    // DO NOTHING
  },
  hideAppHeader: (title) => {
    // DO NOTHING
  },
  showAppHeader: (title) => {
    // DO NOTHING
  },
}
