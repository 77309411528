import axiosClient, { ApiError, isAxiosError } from '~/lib/axios-client'

export const fetchClientError = async (
  e: Error,
  ignored: boolean,
): Promise<void> => {
  const url =
    (e instanceof ApiError && e.requestUrl) ||
    (isAxiosError(e) && e.config.url) ||
    window.location.href

  return axiosClient.post<
    void,
    {
      message: string
      url: string
      stack?: string
      ignored: boolean
    }
  >('/error-log/client', {
    message: e.message,
    url: url,
    stack: e.stack,
    ignored,
  })
}

export const fetchClientLog = async (
  message: string,
  payload: object,
): Promise<void> => {
  return axiosClient.post<
    void,
    {
      message: string
      payload: object
    }
  >('/activity-log/client', { message, payload })
}
