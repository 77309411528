import siteEnv from '~/lib/site-env'
import auctionMobile from './auction/mobile'
import auctionPC from './auction/pc'
import gmarketMobileKorean from './gmarket/mobile'
import gmarketMobileGlobal from './gmarket/mobile/global'
import gmarketPCKorean from './gmarket/pc'
import gmarketPCGlobal from './gmarket/pc/global'

type SiteHeaderModule = {
  loadScriptByOrder?: boolean
  headerScriptSources: string[]
  footerScriptSources: string[]
  preRenderHeader?: (
    userInfo?: checkoutFrontendAPI.GmarketHeaderUserInfo,
  ) => void
  renderHeader: (
    title: string,
    userInfo?: checkoutFrontendAPI.GmarketHeaderUserInfo,
  ) => void
  renderFooter: () => void
  renderBottomNavigation?: () => void
  releaseHeader: () => void
  releaseFooter: () => void
  releaseBottomNavigation?: () => void
  setHeaderTitle: (title: string, isCoreApp?: boolean) => void
  setCloseUrl: (url?: string) => void
  hideAppHeader: (title: string) => void
  showAppHeader: (title: string) => void
}

const getModule = (): SiteHeaderModule | undefined => {
  if (siteEnv.tenantType === 'Auction') {
    if (siteEnv.deviceType === 'Mobile') {
      return auctionMobile
    } else if (siteEnv.deviceType === 'PC') {
      return auctionPC
    }
  } else if (siteEnv.tenantType === 'Gmarket') {
    if (siteEnv.deviceType === 'Mobile') {
      if (siteEnv.languageType === 'Korean') {
        return gmarketMobileKorean
      } else {
        return gmarketMobileGlobal
      }
    } else if (siteEnv.deviceType === 'PC') {
      if (siteEnv.languageType === 'Korean') {
        return gmarketPCKorean
      } else {
        return gmarketPCGlobal
      }
    }
  }
}

const loadScript = (src: string): Promise<HTMLScriptElement> => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src

    document.body.appendChild(script)

    script.onload = (): void => {
      resolve(script)
    }
  })
}

const unloadScript = (script: HTMLScriptElement): void => {
  document.body.removeChild(script)
}

export default class HeaderHelper {
  module?: SiteHeaderModule
  isFooterLoading: boolean
  isFooterLoaded: boolean
  headerScripts: HTMLScriptElement[]
  footerScripts: HTMLScriptElement[]

  constructor() {
    this.module = getModule()
    this.isFooterLoading = false
    this.isFooterLoaded = false
    this.headerScripts = []
    this.footerScripts = []
  }

  async renderHeader(
    title: string,
    userInfo?: checkoutFrontendAPI.GmarketHeaderUserInfo,
  ): Promise<boolean> {
    try {
      if (!this.module) {
        return false
      }

      if (this.module.preRenderHeader) {
        this.module.preRenderHeader(userInfo)
      }

      if (this.module.loadScriptByOrder) {
        for (const src of this.module.headerScriptSources) {
          const script = await loadScript(src)
          this.headerScripts.push(script)
        }
      } else {
        this.headerScripts = await Promise.all(
          this.module.headerScriptSources.map((src) => loadScript(src)),
        )
      }

      this.module.renderHeader(title, userInfo)

      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }

  async renderFooter(): Promise<boolean> {
    try {
      if (this.isFooterLoading || this.isFooterLoaded || !this.module) {
        return false
      }

      this.isFooterLoading = true

      if (this.module.loadScriptByOrder) {
        for (const src of this.module.footerScriptSources) {
          const script = await loadScript(src)
          this.footerScripts.push(script)
        }
      } else {
        this.footerScripts = await Promise.all(
          this.module.footerScriptSources.map((src) => loadScript(src)),
        )
      }

      this.module.renderFooter()

      this.isFooterLoading = false
      this.isFooterLoaded = true

      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }

  renderBottomNavigation(): boolean {
    try {
      if (!this.module || !this.module.renderBottomNavigation) {
        return false
      }

      this.module.renderBottomNavigation()

      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }

  removeHeader(): void {
    try {
      if (!this) {
        return
      }
      this.headerScripts.forEach((x) => unloadScript(x))
      this.headerScripts = []
      if (this.module) {
        this.module.releaseHeader()
      }
    } catch (e) {
      console.error(e)
    }
  }

  removeFooter(): void {
    try {
      this.footerScripts.forEach((x) => unloadScript(x))
      this.footerScripts = []
      if (this.module) {
        this.module.releaseFooter()
      }
    } catch (e) {
      console.error(e)
    }
  }

  removeBottomNavigation(): void {
    try {
      if (this.module && this.module.releaseBottomNavigation) {
        this.module.releaseBottomNavigation()
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * @param title
   * @param isCoreApp
   */
  setHeaderTitle(title: string, isCoreApp?: boolean): void {
    try {
      if (this.module) {
        this.module.setHeaderTitle(title, isCoreApp)
      }
    } catch (e) {
      console.error(e)
    }
  }

  setCloseUrl(url?: string): void {
    try {
      if (this.module) {
        this.module.setCloseUrl(url)
      }
    } catch (e) {
      console.error(e)
    }
  }

  hideAppHeader(title: string): void {
    try {
      if (this.module) {
        this.module.hideAppHeader(title)
      }
    } catch (e) {
      console.error(e)
    }
  }

  showAppHeader(title: string): void {
    try {
      if (this.module) {
        this.module.showAppHeader(title)
      }
    } catch (e) {
      console.error(e)
    }
  }
}
