import { Domain, DomainByEnv, DomainCommon } from '~/data/domains'
import siteEnv from '~/lib/site-env'
import dataPicker from '../data-picker'

const domains: SiteEnvDataType<Partial<DomainByEnv>>[] = []

const cartHost = 'http://cart-local.{tenant}.co.kr:3001'
// const cartHost = 'https://cart-dev.{tenant}.co.kr'
// const cartHost = 'http://cart-local.{tenant}.co.kr:9090'

const common: Partial<DomainCommon> = {
  CART: cartHost,
  CART_HOME: `${cartHost}/{language}/{device}/cart`,
  CART_FE_CLIENT: `${cartHost}/{language}/{device}`,
  CART_FE_CLIENT_MOBILE: `${cartHost}/{language}/m`,
  CART_FE_SERVER: `${cartHost}/server/{language}/{device}`,
  CHECKOUT_LOCAL:
    'http://checkout-local.{tenant}.co.kr:3000/{language}/{device}/checkout?chid={0}',
}

const domainsByEnv =
  dataPicker<Partial<DomainByEnv>>(domains, siteEnv, true) || {}

const result: Partial<Domain> = {
  ...domainsByEnv,
  ...common,
}

export default result
