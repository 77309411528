import React from 'react'
import { urlWithQueryParameters } from '~/cart/modules/cart/reducer'
import { ExhibitExtraDiscount } from '~/cart/modules/cart/types'
import areaCodes from '~/data/areaCodes'
import CustomAnchor from '~/lib/components/CustomAnchor'

const CartUnitDiscountNudge = ({
  idx,
  isSmileClub,
  exhibitExtraDiscount,
}: {
  idx: number
  isSmileClub: boolean
  exhibitExtraDiscount: ExhibitExtraDiscount | null | undefined
}): JSX.Element => {
  if (!exhibitExtraDiscount || idx > 0) {
    return <></>
  }
  return (
    <div className="section item_payment-mothod">
      <CustomAnchor
        className="link__payment-mothod sprite__cart--after"
        data-montelena-acode={
          exhibitExtraDiscount.discountTargetType === 'Club' && !isSmileClub
            ? areaCodes?.EXHIDIT_EXTRA_DISCOUNT?.CartUnitClub
            : areaCodes?.EXHIDIT_EXTRA_DISCOUNT?.CartUnitNonClub
        }
        data-montelena-exhibit_name={exhibitExtraDiscount.exhibitName}
        data-montelena-master_no={exhibitExtraDiscount.extraMasterNo}
        href={urlWithQueryParameters(exhibitExtraDiscount.linkUrl, [
          { extraMasterNo: exhibitExtraDiscount.extraMasterNo },
        ])}
      >
        <span className="text__promotion-name image__credit-card">
          {exhibitExtraDiscount.exhibitName}
        </span>
        {exhibitExtraDiscount.discountTargetType === 'Club' && !isSmileClub ? (
          <span className="text__method-type-apply">
            클럽은 {exhibitExtraDiscount.extraDiscountRate}% 결제할인
          </span>
        ) : (
          <span className="text__method-type-apply">
            {exhibitExtraDiscount.extraDiscountRate}% 결제할인
          </span>
        )}
      </CustomAnchor>
    </div>
  )
}

export default CartUnitDiscountNudge
