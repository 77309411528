/* tslint:disable */
/* eslint-disable */

import domains from '~/data/domains'

/**
 * UXE에서 제공하는 함수들은 다 이쪽으로 몬다
 * type체크없이 실행한다
 */
class Montelena {
  /**
   *
   * @param actionType {MontelenaActionType}
   * @param areaCode {string}
   * @param areaValues {MontelenaAreaValueType}
   * @param source {string}
   */
  static logImpression = (actionType, areaCode, areaValues, source) => {
    try {
      window.montelena.sendEvent(
        actionType,
        areaCode,
        areaValues,
        source,
        domains.MONTELENA_SERVICE_NAME,
        domains.MONTELENA_API_KEY,
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export default Montelena
