import siteEnv from '~/lib/site-env'
import cn from './locales/cn.json'
import en from './locales/en.json'
import ko from './locales/ko.json'

const sources: {
  [key: string]: { [indexKey: string]: string | undefined }
} = {
  ko,
  en,
  cn,
}

const getLanguageCode = (): 'ko' | 'en' | 'cn' => {
  switch (siteEnv.languageType) {
    case 'English':
      return 'en'
    case 'Chinese':
      return 'cn'
    default:
      return 'ko'
  }
}

export function __(indexKey: string): string | undefined
export function __(indexKey: string, originText: string): string

export function __(indexKey: string, originText?: string): string | undefined {
  const languageCode = getLanguageCode()
  if (languageCode === 'ko' && originText) {
    // 국문은 인덱스 안쓴다
    return originText
  }
  const source = sources[languageCode]
  if (source) {
    const result = source[indexKey]
    if (result) {
      return result
    }
  }
  return originText
}
