import { createStandardAction } from 'typesafe-actions'
import { UnitCashbackApplication } from './types'

export const UPDATE_UNIT_CASHBACK_LIST = 'cashback/UPDATE_UNIT_CASHBACK_LIST'

export const updateUnitCashbackList = createStandardAction(
  UPDATE_UNIT_CASHBACK_LIST,
)<{
  unitCashbackList: UnitCashbackApplication[]
  useEnableDate: string
}>()
