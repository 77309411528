import { Dispatch } from 'redux'
import domains from '~/data/domains'

export default function crashReport() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (next: Dispatch) => (action: any): void => {
    try {
      return next(action)
    } catch (err) {
      //에러 전송
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window.$.ajax({
          type: 'POST',
          url: domains.CART_FE_SERVER + '/api/error-log/script',
          dataType: 'application/json',
          data: {
            moduleName: 'ScriptError',
            errorContent: encodeURI(err),
          },
        })
      } catch (e) {}
    }
  }
}
