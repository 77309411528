import { formatString } from '~/lib/formatter'
import siteEnv from '~/lib/site-env'
import { LanguageType } from '~/types/enums'

const defaultMessages: Record<LanguageType, string> = {
  Korean: '일시적인 문제로 정상처리 되지 않았습니다. 다시 확인 해주세요.',
  English:
    'Your request is not properly processed due to a temporary error. Please check again.',
  Chinese: '因系统临时发生错误，信息并未得到正常处理，请重新确认。',
}

const messages: Record<
  LanguageType,
  { [brCode: string]: string | undefined }
> = {
  Korean: {
    'CART-BR1-01':
      '현재 이 상품의 주문가능한 재고수량은 {0}개 입니다. {0}개 이하로 주문해주세요.',
    'CART-BR1-02':
      '현재 이 옵션의 주문가능한 재고수량은 {0}개 입니다. {0}개 이하로 주문해주세요.',
    'CART-BR1-03':
      '현재 이 추가구성의 주문가능한 재고수량은 {0}개 입니다. {0}개 이하로 주문해주세요.',
    'CART-BR1-04': '이 상품은 상품정보 변경/재고부족으로 주문이 불가합니다.',
    'CART-BR1-05': '이 상품은 상품정보 변경/재고부족으로 주문이 불가합니다.',
    'CART-BR1-06':
      '상품정보 변경/재고부족 등 이유로 구매불가한 상품이 포함되어 있습니다. 다시 확인해주세요.',
    'CART-BR1-07':
      '상품정보 변경/재고부족 등 이유로 구매불가한 상품이 포함되어 있습니다. 다시 확인해주세요.',
    'CART-BR1-08': '당일배송 상품은 배송지를 선택해주세요.',
    'CART-BR1-09': '해당 지점에 재고가 없거나 판매하지 않는 상품입니다.',
    'CART-BR1-11':
      '신세계 백화점 상품은 2개 이상 구매를 원하시는 경우 상품을 각각 1개씩 장바구니에 담아서 주문해 주세요.',
    'CART-BR1-14':
      '본 상품은 장바구니에 담을 수 없습니다. 즉시구매만 가능합니다.',
    'CART-BR1-15':
      '판매자와 연락하셨나요? 주문하기 전 반드시 판매자와 연락하여 상품이 있는지와 오늘 퀵서비스 배송이 가능한지를 확인해주세요. 판매자 연락처:{0} \n퀵서비스 배송 상품은 주문 후 평균 3시간 이내에 배송됩니다.\n요금은 상품 수령시 지불해 주세요.',
    'CART-BR1-20': '판매가 중지된 상품입니다.',
    'CART-BR1-21': '{0}은(는) 구매 제한 상품입니다.',
    'CART-BR1-22': '최대 구매가능 수량보다 선택한 수량이 많습니다.',
    'CART-BR1-23': '최소 구매 수량보다 선택한 수량이 부족합니다.',
    'CART-BR1-25': '본인이 판매하는 상품은 본인 장바구니에 담을 수 없습니다.',
    'CART-BR1-26': '19세 미만이 구매할 수 없는 상품입니다.',
    'CART-BR1-27': '해당 상품은 회원만 가능합니다.',
    'CART-BR1-28':
      '고객님께서는 해당 판매자의 상품을 구매하실 수 없습니다. 구매를 위해서는 판매자에게 연락해주세요.',
    'CART-BR1-29': '고객님은 이용 제한으로 상품을 담을 수 없습니다.',
    'CART-BR1-30':
      '당일배송 상품은 회원만 구매할 수 있습니다. 로그인 후 구매하세요.',
    'CART-BR1-31':
      '해당 상품은 최대구매 수량 제한으로 인해 최대 {0}개까지만 구매 가능합니다.',
    'CART-BR1-32':
      '구매 가능 최대 수량({0}개) 보다 많은 수량으로 장바구니에 담으실 수 없습니다.',
    'CART-BR1-33':
      '구매 가능 최대 수량({0}개) 보다 많은 수량으로 장바구니에 담으실 수 없습니다.',
    'CART-BR1-34': '본상품은 {0}개 이상 구매 가능합니다.',
    'CART-BR1-35':
      '배달 상품은 회원만 구매할 수 있습니다. 로그인 후 구매하세요.',
    'CART-BR1-36': '스마일 클럽딜 상품은 스마일클럽 회원만 구매 가능합니다. ',
    'CART-BR1-50':
      '스마일페이 전용딜 상품은 회원만 구매할 수 있습니다. 로그인 후 구매하세요.',
    'CART-BR1-37':
      '일시적인 문제로 정상처리 되지 않았습니다. 다시 확인 해주세요.',
    'CART-BR1-38':
      '고객님 불편을 드려 죄송합니다. 본 상품은 사업자 회원만 구매 가능하여, 로그인 후에 이용하실 수 있습니다. 로그인 하시겠습니까?',
    'CART-BR1-39': '탈퇴한 회원입니다. 회원가입 후 이용해 주세요.',
    'CART-BR1-43':
      '장바구니에 담을 수 있는 최대 상품수(100개)를 초과하였습니다. \n장바구니를 확인해주세요.',
    'CART-BR1-45': '선택한 상품을 장바구니에 담을 수 없습니다.',
    'CART-BR1-52':
      '주문가능한 재고수량이 부족합니다. 수량을 변경하여 주시기 바랍니다.',
    'CART-BR1-53':
      '주문가능한 재고수량이 부족합니다. 수량을 변경하여 주시기 바랍니다.',
    'CART-BR1-55': '본 상품은 {0}개의 배수로만 구매 가능합니다.',
    'CART-BR3-01': '주문 가능한 최소 수량은 {0}개 입니다.',
    'CART-BR3-02':
      '로그인세션이 만료되어 수량 변경에 실패하였습니다. 로그인 후 변경하시길 바랍니다.',
    'CART-BR3-03':
      '현재 이 상품의 주문가능한 재고수량은 {0}개 입니다. {0}개 이하로 주문해주세요.',
    'CART-BR3-04':
      '현재 이 상품의 주문가능한 재고수량은 {0}개 입니다. {0}개 이하로 주문해주세요.',
    'CART-BR3-05':
      '이 상품의 최소 구매수량은 {0}개 입니다. {0}개 이상으로 주문해주세요.',
    'CART-BR3-06':
      '이 상품의 최대 구매수량은 {0}개 입니다. {0}개 이하로 주문해주세요.',
    'CART-BR3-07': '할인쿠폰이 적용된 상품은 수량을 변경하실 수 없습니다.',
    'CART-BR3-08': '신세계백화점 상품은 1개씩 주문해주세요.',
    'CART-BR3-09':
      '선택하신 상품의 주문 가능한 수량은 999개 입니다. 999개 이하로 주문해 주세요.',
    'CART-BR3-16': '숫자를 입력해 주세요.',
    'CART-BR3-17':
      '주문가능한 재고수량이 부족합니다. 수량을 변경하여 주시기 바랍니다.',
    'CART-BR3-18':
      '주문가능한 재고수량이 부족합니다. 수량을 변경하여 주시기 바랍니다.',
    'CART-BR4-09':
      '선택하신 상품이 없습니다. 삭제를 원하시는 상품을 선택 해 주세요.',
    'CART-BR5-01': '할인쿠폰은 먼저 로그인한 후 이용해주시기 바랍니다.',
    'CART-BR5-02':
      '고객님, 죄송합니다.\n제휴채널을 통한 접속시 쿠폰적용이 불가합니다.',
    'CART-BR5-04':
      '고객님, 죄송합니다.\n중고 상품에 쿠폰을 적용할 수 없습니다.',
    'CART-BR5-05': '가입상품은 쿠폰을 적용할 수 없습니다.',
    'CART-BR5-06':
      '고객님, 죄송합니다.\n이 상품은 쿠폰을 적용할 수 없는 상품입니다.',
    'CART-BR5-07':
      '고객님, 죄송합니다.\n이 상품은 쿠폰을 적용할 수 없는 상품입니다.',
    'CART-BR5-08':
      '고객님, 죄송합니다.\n이 상품은 쿠폰을 적용할 수 없는 상품입니다.',
    'CART-BR5-16': '사용가능한 쿠폰이 없습니다.',
    'CART-BR5-09':
      '일시적인 문제로 정상처리 되지 않았습니다. 다시 확인 해주세요.',
    'CART-BR5-10':
      '{0} 결제수단만 사용가능한 쿠폰입니다. 주문결제시 결제제한이 적용됩니다.',
    'CART-BR5-11':
      '해외배송전용 쿠폰입니다. 국내배송(대한민국) 시 주문이 불가합니다',
    'CART-BR5-12':
      '현재 다른 상품에 이미 적용되어 있는 쿠폰 입니다. 취소 후 이 상품에 적용하시겠습니까?',
    'CART-BR5-13':
      '쿠폰 선택 후 적용이 완료되지 않았습니다. 쿠폰적용 버튼을 눌러주십시오.',
    'CART-BR5-14':
      '선택하신 슈퍼쿠폰은 상품별할인 및 G마켓 추가할인에만 중복적용이 가능합니다.',
    'CART-BR5-17':
      '일시적으로 쿠폰 목록 조회에 실패하였습니다. 다시 시도해 주세요.',
    'CART-BR5-20': '쿠폰은 본인인증 후 사용할 수 있습니다.',
    'CART-BR5-21': '묶음 배송비가 유료인 경우에만 사용가능한 쿠폰입니다.',
    'CART-BR5-22': '쿠폰을 적용할 수 없습니다.',
    'CART-BR5-23':
      '죄송합니다. 서로 다른 결제수단 제한으로 적용된 쿠폰이 있습니다. 쿠폰을 다시 적용해 주세요.',
    'CART-BR7-01': '주문하실 상품을 선택해주세요.',
    'CART-BR7-02':
      '판매불가 상품이 포함되어 있습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-03':
      '재고가 부족한 상품이 포함되어 있습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-04':
      '옵션 정보/재고가 변경되어 주문할 수 없습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-05':
      '옵션 정보/재고가 변경되어 주문할 수 없습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-06':
      '추가구성 정보/재고가 변경되어 주문할 수 없습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-07':
      '추가구성 정보/재고가 변경되어 주문할 수 없습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-08':
      'e쿠폰 상품과 배송상품은 함께 주문이 불가능합니다. 별도로 주문해주세요.',
    'CART-BR7-09':
      '도서상품은 소득공제대상 상품으로 일반 상품과 동시 주문이 불가합니다. 별도로 주문해주세요.',
    'CART-BR7-10':
      '해당 상품은 회원만 구매 가능 합니다. 로그인 후 주문해주세요.',
    'CART-BR7-11':
      '재고가 부족한 상품이 포함되어 있습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-13':
      '클럽딜 상품은 스마일 클럽 회원만 구매 가능합니다. 다시 확인해주세요. 상품명: {0}',
    'CART-BR7-14':
      '적용된 쿠폰을 다시 확인해주세요. 동일 판매자의 배송비 할인 쿠폰 적용된 상품이 2개 이상 있습니다.',
    'CART-BR7-15':
      '적용된 쿠폰을 다시 확인해주세요. 서로 다른 결제수단 전용 쿠폰으로 적용되어 주문할 수 없습니다.',
    'CART-BR7-16':
      '고객님께서는 상품구매가 제한되어 있습니다. 문의사항이 있으시면 고객센터로 연락하여 주시기 바랍니다. (1566-5701)',
    'CART-BR7-17': '탈퇴한 회원입니다. 회원가입 후 이용해주세요.',
    'CART-BR7-18':
      '해외전용 할인쿠폰이 적용된 상품이 있습니다. 국내배송에서는 사용불가하므로 적용된 할인 쿠폰을 다시 확인해 주세요.',
    'CART-BR7-19':
      'SF Express이용 중국배송은 구매수량이 총 6개이상(옵션/추가구성 포함)이거나 구매금액(배송비제외)이 30,000원초과 시 주문할 수 없습니다.',
    'CART-BR7-20':
      '중국으로 SF Express 배송 시, 바디/헤어 카테고리 제품은 ￦15,000원 이하로만 주문이 가능합니다.',
    'CART-BR7-21':
      '선택하신 국가로 배송불가한 상품이 포함되어있습니다. 다시 확인해주세요.',
    'CART-BR7-22': '선택한 택배사는 해당 국가로 배송할 수 없습니다.',
    'CART-BR7-23':
      '선택하신 국가로 배송불가한 상품이 포함되어있습니다. 다시 확인해주세요.',
    'CART-BR7-24':
      'EMS 배송 서비스를 사용하려면, 상품 총 무게는 30kg를 초과 할 수 없습니다.',
    'CART-BR7-25':
      'SF Express 배송 서비스를 사용하려면, 상품 총 무게는 30kg를 초과 할 수 없습니다.',
    'CART-BR7-26':
      'CJ대한통운 배송 서비스를 사용하려면, 상품 총 무게는 30kg를 초과 할 수 없습니다.',
    'CART-BR7-27':
      '지점 정보가 누락된 상품이 포함되어 있습니다. 다시 확인해주세요.',
    'CART-BR7-28':
      '일시적으로 해외직구상품과 홈플러스 상품을 같이 주문할 수 없습니다. 해외직구 상품만 따로 주문 부탁드립니다',
    'CART-BR7-29':
      '홈플러스 상품과 망고코리아 상품은 배송정책상 같이 주문하실 수 없습니다. 따로 주문 부탁드립니다.',
    'CART-BR7-31': '선택하신 배송시간은 마감되었습니다. 다시 선택해주세요.',
    'CART-BR7-32':
      '홈플러스 상품과 일반배송 상품은 같이 주문하실 수 없습니다. 따로 주문 부탁 드립니다.',
    'CART-BR7-33': '상품 주문은 최대 30개까지 가능합니다.',
    'CART-BR7-34': '주문 불가한 상품이 포함되어 있습니다.',
    'CART-BR7-36':
      '품절/상품정보 변경 등의 이유로 주문 불가한 상품이 포함 되어 있습니다. 다시 확인해주세요. 상품명: {0}',
    'CART-BR7-39':
      '국내배송불가 상품이\n포함되어 있습니다.\nPC버전 [해외로 배송]을 이용해주세요.',
    'CART-BR7-41':
      '선택하신 국가로는 구매금액(배송비 제외)이 {0}원 초과하여 주문이 불가합니다.',
    'CART-BR7-42':
      '선택하신 택배사를 사용하려면, 상품 총 무게는 30kg를 초과 할 수 없습니다.',
    'CART-BR7-43':
      '무료배송쿠폰이 적용된 상품이 있습니다. 무료배송쿠폰은 결제할 묶음 배송비가 0원인 경우에는 사용할 수 없습니다. 무료배송쿠폰을 적용 취소 후 주문해주세요. \n상품명 : {0}',
    'CART-BR7-44':
      '환금성 상품은 다른 상품과 함께 구매 불가합니다. 단독 구매하여 주시기 바랍니다.',
    'CART-BR7-45':
      '사업자클럽 회원만 구매 가능한 상품 입니다. 상품 확인 해주세요. 상품명: {0}',
    'CART-BR7-46':
      '사업자클럽 전용 상품이 포함 되어 있습니다. 상품 확인 해주세요. 상품명: {0}',
    'CART-BR7-47':
      '주문하신 상품 중 결제 금액 0원 이하의 상품이 있습니다. 다시 확인하신 후 주문해 주세요.',
    'CART-BR7-48':
      '본 상품은 {0} 이후 멤버십에 가입/연장한 고객만 구매하실 수 있습니다. 무료이용 회원은 연간회원 전환 후 구매 가능합니다.',
    'CART-BR7-49':
      '본 상품은 특정 기간 동안 멤버십에 가입/연장한 고객만 구매하실 수 있습니다. 가입 기간을 확인해주세요.',
    'CART-BR7-51': '선택하신 국가({0})로 배송이 가능한 택배사가 없습니다.',
    'CART-BR7-52':
      '낮밤배송만 가능한 상품이 포함되어 있습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-53':
      '새벽배송만 가능한 상품이 포함되어 있습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-54':
      '배송불가한 상품이 포함되어 있습니다. 다시 확인해 주세요.\n상품명: {0}',
    'CART-BR7-55':
      '당일배송관 상품은 상품 상세 페이지에서 배송지 확인 후 주문해주세요.',
    'CART-BR2-139': '회원만 사용가능합니다. 로그인 하여 주시기 바랍니다.',
  },
  English: {
    'CART-BR1-01': 'This item has {0} in stock. Please order {0} or less.',
    'CART-BR1-02': 'This option has {0} in stock. Please order {0} or less.',
    'CART-BR1-03':
      'This add-on item has {0} in stock. Please order {0} or less.',
    'CART-BR1-04':
      'This item cannot be ordered as the product details have been changed or the stock has run out.',
    'CART-BR1-05':
      'This item cannot be ordered as the product details have been changed or the stock has run out.',
    'CART-BR1-06':
      'Your order includes items that you cannot purchase because they are out of stock or the product details have been changed. Please check again.',
    'CART-BR1-07':
      'Your order includes items that you cannot purchase because they are out of stock or the product details have been changed. Please check again.',
    'CART-BR1-08': 'Enter a shipping address for same-day shipping out items.',
    'CART-BR1-09':
      'This item is out of stock at the branch or no longer for sale.',
    'CART-BR1-11':
      'To purchase 2 or more Shinsegae Department Store items, add items in the shopping cart one by one and place orders separately.',
    'CART-BR1-14':
      'This item cannot be added to the shopping cart. It is available only for Buy Now.',
    'CART-BR1-15':
      'Did you contact the seller? Please contact the seller to check whether the item is available, and whether the express delivery service is provided before placing an order. Contact information {0} Express delivery service items will be shipped within an average of three hours after the order. Please make a payment on the receipt of the item.',
    'CART-BR1-20': 'This item is no longer available for sale.',
    'CART-BR1-21': '{0} is a purchase restricted item.',
    'CART-BR1-22':
      'You have entered more than the maximum allowable quantity for items.',
    'CART-BR1-23':
      'You have entered less than the minimum allowable quantity for items.',
    'CART-BR1-25':
      'You cannot add an item you are selling into your shopping cart.',
    'CART-BR1-26': 'Not for sale to persons under the age of 19.',
    'CART-BR1-27': 'This item is available only for members.',
    'CART-BR1-28':
      'You are unable to purchase an item from this seller. To place an order, please contact the seller.',
    'CART-BR1-29':
      'You cannot add an item to the shopping cart due to the purchase restriction.',
    'CART-BR1-30':
      'Same-day shipping out items are available only for members. Please sign in and place an order.',
    'CART-BR1-31':
      'Due to the maximum allowable quantity for items, you can order up to {0} units.',
    'CART-BR1-32':
      'You cannot add items more than the maximum allowable quantity of ({0} units).',
    'CART-BR1-33':
      'You cannot add items more than the maximum allowable quantity of ({0} units).',
    'CART-BR1-34': 'This item has a minimum purchase quantity of {0}.',
    'CART-BR1-35':
      'Delivery items are available only for members. Please sign in and place an order.',
    'CART-BR1-36':
      'Smile Club Deal items are available only for Smile Club members.',
    'CART-BR1-37':
      'Your request is not properly processed due to a temporary error. Please check again.',
    'CART-BR1-38':
      'We apologize for any inconvenience. This item is available only for business members. You may purchase after signing in. Do you want to sign in?',
    'CART-BR1-39':
      'You have already canceled your membership. Please try again after registering.',
    'CART-BR1-43':
      'You have exceeded the maximum allowable quantity for items you can add to the shopping cart. Please check the shopping cart.',
    'CART-BR1-45': 'The selected item cannot be added to the shopping cart.',
    'CART-BR1-50':
      'Smile Pay Deal items are available only for members. Please sign in and place an order.',
    'CART-BR1-52':
      'This item cannot be ordered as the product details have been changed or the stock has run out.',
    'CART-BR1-53':
      'This item cannot be ordered as the product details have been changed or the stock has run out.',
    'CART-BR3-01': 'The minimum purchase quantity for this item is {0}.',
    'CART-BR3-02':
      'Updating the quantity has been failed because the sign-in session expired. Please sign in and retry.',
    'CART-BR3-03': 'This item has {0} in stock. Please order {0} or less.',
    'CART-BR3-04': 'This item has {0} in stock. Please order {0} or less.',
    'CART-BR3-05':
      'The minimum purchase quantity for this item is {0}. Please order {0} units or more.',
    'CART-BR3-06':
      'The maximum purchase quantity for this item is {0}. Please order {0} units or less.',
    'CART-BR3-07':
      'It is not allowed to change purchase quantities while coupons are applied.',
    'CART-BR3-08':
      'Please order Shinsegae department store items separately, one at a time',
    'CART-BR3-09':
      'The maximum allowable purchase quantity for this item is 999. Please order 999 or less.',
    'CART-BR3-16': 'Please insert numbers.',
    'CART-BR3-17': '',
    'CART-BR3-18': '',
    'CART-BR4-09': 'No item selected. Please select the item want to delete.',
    'CART-BR5-01': 'Please sign in first to apply a discount coupon.',
    'CART-BR5-02':
      'Sorry, coupons are not applicable if you sign in from the partnered channels.',
    'CART-BR5-04': 'Sorry, coupons are not applicable to secondhand items',
    'CART-BR5-05':
      'Coupons are not applicable to the registration required items.',
    'CART-BR5-06': 'Sorry, coupons are not applicable for this item.',
    'CART-BR5-07': 'Sorry, coupons are not applicable for this item.',
    'CART-BR5-08': 'Sorry, coupons are not applicable for this item.',
    'CART-BR5-16': 'You do not have coupons applicable for this item.',
    'CART-BR5-09':
      'Your request is not properly processed due to a temporary error. Please check again.',
    'CART-BR5-10':
      'This coupon is only applicable with {0} payment method. Payment restriction applies when paying for your order with this coupon.',
    'CART-BR5-11':
      'This coupon is only for international shipping and not applicable for domestic shipping in Korea.',
    'CART-BR5-12':
      'This coupon is currently applied to another item. Do you want to cancel and apply the coupon to this item?',
    'CART-BR5-13':
      'The selected coupon is not yet applied. Please click ’Apply’ button.',
    'CART-BR5-14':
      'The selected Super Coupon is applicable only with Gmarket Extra discount coupon.',
    'CART-BR5-17':
      'Coupon List reference has temporarily failed. Please try again.',
    'CART-BR5-20': '',
    'CART-BR5-21':
      'This coupon can only be used when the combined shipping fee is charged.',
    'CART-BR5-22':
      'Coupon List reference has temporarily failed. Please try again.',
    'CART-BR5-23':
      'Coupon List reference has temporarily failed. Please try again.',
    'CART-BR7-01': 'Select the item you want to order.',
    'CART-BR7-02':
      'Your order includes items that are no longer available for sale. Please check again. Product name: {0}',
    'CART-BR7-03':
      'Your order includes items that are out of stock. Please check again. Product name: {0}',
    'CART-BR7-04':
      'This item cannot be ordered as there have been changes made to the stock or option information. Please check again. Product name: {0}',
    'CART-BR7-05':
      'This item cannot be ordered as there have been changes made to the stock or option information. Please check again. Product name: {0}',
    'CART-BR7-06':
      'This item cannot be ordered as there have been changes made to the stock or add-on item information. Please check again. Product name: {0}',
    'CART-BR7-07':
      'This item cannot be ordered as there have been changes made to the stock or add-on item information. Please check again. Product name: {0}',
    'CART-BR7-08':
      'e-coupon items cannot be ordered together with delivery items. Please place an order separately.',
    'CART-BR7-09':
      'Books are subject to a tax deduction and cannot be purchased together with other items. Please place an order separately.',
    'CART-BR7-10':
      'This item is available only for members. Please sign in and place an order.',
    'CART-BR7-11':
      'Your order includes items that are out of stock. Please check again. Product name: {0}',
    'CART-BR7-13':
      'Club Deal items are available only for Smile Club members. Please check again. Product name: {0}',
    'CART-BR7-14':
      'Please check the selected coupon again. You have selected shipping fee discount coupons for 2 or more items from the same seller.',
    'CART-BR7-15':
      'Please check the selected coupon again. You have selected coupons for different payment forms at the same time and orders cannot be made.',
    'CART-BR7-16':
      'You are restricted from purchasing this item. If you have any questions, please contact the customer service center. (1566-5701)',
    'CART-BR7-17':
      'You have already canceled your membership. Please try again after registering.',
    'CART-BR7-18':
      'Your order includes items with an international shipping coupon applied. This coupon cannot be applied to the shipping service in Korea. Please check the selected coupon again.',
    'CART-BR7-19':
      'If your order ships to China by using S.F Express, all your shipment of Gmarket has to be less than 6(six) total quantity and less than KRW 30,000(excluding shipping fee)of purchases.',
    'CART-BR7-20':
      'If your order ships to China by using S.F Express, total costs of items from Body/Hair category has to be less than KRW 15,000(excluding shipping fee)of purchases.',
    'CART-BR7-21':
      'Your order includes items that cannot be shipped to the selected country. Please check again.',
    'CART-BR7-22':
      'The selected shipping company does not provide the shipping service to the country in question.',
    'CART-BR7-23':
      'Your order includes items that cannot be shipped to the selected country. Please check again.',
    'CART-BR7-24':
      'To use the EMS shipping service, the total weight of the item should not exceed 30kg.',
    'CART-BR7-25':
      'To use the SF Express shipping service, the total weight of the item should not exceed 30kg.',
    'CART-BR7-26':
      'To use the CJ Logistics shipping service, the total weight of the item should not exceed 30kg.',
    'CART-BR7-27':
      'Your order includes items that is missing sales branch information. Please check again.',
    'CART-BR7-28':
      'Temporarily, the items available for the overseas direct purchase service cannot be ordered together with Home Plus items. Please order overseas direct purchase items separately.',
    'CART-BR7-29':
      'Items from Mango Korea cannot be ordered together with Home Plus items under our delivery policy. Please place orders separately.',
    'CART-BR7-31': 'The selected delivery time has ended. Please try again.',
    'CART-BR7-32':
      'Home Plus items cannot be ordered together with general delivery items. Please place orders separately.',
    'CART-BR7-33': 'Up to 30 items can be ordered.',
    'CART-BR7-34': 'Your order includes items that cannot be ordered.',
    'CART-BR7-36':
      'Your items include unavailable items to order due to changed item info and/or out of stock. Please check again. Product name: {0}',
    'CART-BR7-39': '',
    'CART-BR7-41':
      'Orders cannot be placed for the selected country since the purchase amount (excluding shipping fee) exceeds KRW {0}.',
    'CART-BR7-42':
      'In order to use the chosen delivery service, the package should not be over 30kg.',
    'CART-BR7-43':
      'You have a product that the free shipping coupon is applied to. Free shipping coupons cannot be used if the combined shipping fee is already free. Please check the applied discount coupon again. \nProduct Name : {0}',
    'CART-BR7-47': 'The error has temporarily occurred. Please try again.',
    'CART-BR7-48':
      'Smile Club Deal items are available only for Smile Club members.',
    'CART-BR7-49':
      'Smile Club Deal items are available only for Smile Club members.',
    'CART-BR7-51':
      'There is no courier that can deliver to the selected country ({0}).',
    'CART-BR2-139': 'It is available for use only by members. Please sign in.',
  },
  Chinese: {
    'CART-BR1-01': '此商品可购买库存数量为 {0}个，请选择{0}个以下购买。',
    'CART-BR1-02': '此商品选项可购买的库存数量为 {0}个，请选择{0}个以下购买。',
    'CART-BR1-03': '此附加选项可购买的库存数量为 {0}个，请选择{0} 个以下购买。',
    'CART-BR1-04': '此商品因信息变更或缺货，所以无法购买。',
    'CART-BR1-05': '此商品因信息变更或缺货，所以无法购买。',
    'CART-BR1-06':
      '订单中有因商品信息变更或缺货等原因而无法购买的商品，请您重新确认。',
    'CART-BR1-07':
      '订单中有因商品信息变更或缺货等原因而无法购买的商品，请您重新确认。',
    'CART-BR1-08': '请您选择韩国当天配送商品的配送地。',
    'CART-BR1-09': '商品处于缺货状态或店铺内没有该商品。',
    'CART-BR1-11': '购买2件以上的新世界百货店商品时，需单件添加到购物车购买。',
    'CART-BR1-14': '本商品无法添加到购物车，仅限立即购买。',
    'CART-BR1-15':
      '您已与卖家联系了吗？购买前请务必与卖家联系确认商品是否有库存以及能否当日急送。卖家联系方式{0} ₩n急送商品会在支付完毕后的3个小时以内进行配送。 请在收货时支付₩n金额。',
    'CART-BR1-20': '本商品已停止出售。',
    'CART-BR1-21': '{0}为限制购买商品。',
    'CART-BR1-22': '您所选数量已超过最多可购买数量。',
    'CART-BR1-23': '您所选数量低于最少购买数量。',
    'CART-BR1-25': '无法将自己出售的商品添加到自己的购物车。',
    'CART-BR1-26': '未满19岁无法购买本商品。',
    'CART-BR1-27': '本商品仅限会员购买。',
    'CART-BR1-28': '您无法购买相应卖家的商品。如需购买，请联系卖家。',
    'CART-BR1-29': '因您使用受限，无法将此商品添加到购物车。',
    'CART-BR1-30': '韩国当天配送商品仅限会员购买。请登录后再购买。',
    'CART-BR1-31': '本商品购买数量受限，最多可购买{0}件。',
    'CART-BR1-32': '因超过最多购买数量({0}个)，无法添加到购物车。',
    'CART-BR1-33': '因超过最多购买数量({0}个)，无法添加到购物车。',
    'CART-BR1-34': '本商品的最少购买数量为{0}件。',
    'CART-BR1-35': '外卖商品仅限会员购买，请登录后购买。',
    'CART-BR1-36': 'Smile Club促销商品仅限Smile Club会员购买。',
    'CART-BR1-37': '因系统临时发生错误，信息并未得到正常处理，请您重新确认。',
    'CART-BR1-38':
      '给您带来的不便我们深表歉意。本商品是企业会员专享，需登录后才可购买，您要登录吗？',
    'CART-BR1-39': '您已是退出会员，请注册后再使用。',
    'CART-BR1-43': '已超过购物车的最多可购买数量，请确认₩n购物车。',
    'CART-BR1-45': '您所选择的商品无法添加到购物车内。',
    'CART-BR1-50': 'Smile Pay专享商品仅限会员购买，请登录后购买。',
    'CART-BR1-52': '此商品因信息变更或缺货，所以无法购买。',
    'CART-BR1-53': '此商品因信息变更或缺货，所以无法购买。',
    'CART-BR3-01': '最少购买数量为{0}个。',
    'CART-BR3-02':
      '因登录时间过长被自动退出，无法修改商品数量。请登录后再修改。',
    'CART-BR3-03': '此商品可购买库存数量为 {0}个，请选择{0}个以下购买。',
    'CART-BR3-04': '此商品可购买库存数量为 {0}个，请选择{0}个以下购买。',
    'CART-BR3-05': '本商品最少购买数量为 {0}个，请选择 {0}个以上的数量购买。',
    'CART-BR3-06': '本商品最多购买数量为 {0}个，请选择{0}个以下的数量购买。',
    'CART-BR3-07': '已使用优惠券的商品不可修改购买数量。',
    'CART-BR3-08': '请单件购买新世界百货店商品。',
    'CART-BR3-09': '本商品的限购数量为999件。购买数量不得超过999件。',
    'CART-BR3-16': '请输入数字。',
    'CART-BR3-17': '此商品因信息变更或缺货，所以无法购买。',
    'CART-BR3-18': '此商品因信息变更或缺货，所以无法购买。',
    'CART-BR4-09': '您选择的商品不存在。请选择需要删除的商品。',
    'CART-BR5-01': '优惠券需先登录后才能使用。',
    'CART-BR5-02': '非常抱歉！通过₩n专用通道登录时无法使用优惠券。',
    'CART-BR5-04': '非常抱歉！₩n二手商品无法使用优惠券。',
    'CART-BR5-05': '添加商品无法使用优惠券。',
    'CART-BR5-06': '非常抱歉！₩n此商品无法使用优惠券。',
    'CART-BR5-07': '非常抱歉！₩n此商品无法使用优惠券。',
    'CART-BR5-08': '非常抱歉！₩n此商品无法使用优惠券。',
    'CART-BR5-16': '现无可使用优惠券。',
    'CART-BR5-09': '因系统临时发生错误，信息并未得到正常处理，请重新确认。',
    'CART-BR5-10': '此优惠券仅限 {0} 支付专享支付订单时支付受限。',
    'CART-BR5-11': '全球配送专享优惠券无法进行韩国国内配送。',
    'CART-BR5-12': '此优惠券正使用于其他商品。是否取消后用于此商品?',
    'CART-BR5-13': '选择优惠券后未完成使用。请点击使用优惠券。',
    'CART-BR5-14':
      '您所选择的超级优惠券，仅可与各类目优惠券或Gmarket追加折扣优惠券叠加使用。',
    'CART-BR5-17': '优惠券列表获取失败。请重试。',
    'CART-BR5-20': '',
    'CART-BR5-21': '本优惠券仅适用于合并配送收费的情况。',
    'CART-BR5-22': '优惠券列表获取失败。请重试。',
    'CART-BR5-23': '优惠券列表获取失败。请重试。',
    'CART-BR7-01': '请选择购买商品。',
    'CART-BR7-02': '订单中有不可出售商品。请重新确认。₩n商品名称: {0}',
    'CART-BR7-03': '订单中有商品缺货。请重新确认。₩n商品名称: {0}',
    'CART-BR7-04':
      '因选项信息或库存发生变动，所以无法购买。请重新确认。₩n商品名称: {0}',
    'CART-BR7-05':
      '因选项信息或库存发生变动，所以无法购买。请重新确认。₩n商品名称: {0}',
    'CART-BR7-06':
      '因附加商品信息或库存发生变动，所以无法购买。请重新确认。₩n商品名称: {0}',
    'CART-BR7-07':
      '因附加商品信息或库存发生变动，所以无法购买。请重新确认。₩n商品名称: {0}',
    'CART-BR7-08': '电子券商品与配送商品无法同时购买，请单独购买。',
    'CART-BR7-09':
      '图书商品属于所得税抵扣商品，因此无法与一般商品同时购买。请单独购买。',
    'CART-BR7-10': '此商品仅限会员购买，请登录后再购买。',
    'CART-BR7-11': '订单中有商品缺货。请重新确认。₩n商品名称: {0}',
    'CART-BR7-13':
      'Smile Club促销商品仅限Smile Club会员购买。请重新确认。商品名称: {0}',
    'CART-BR7-14':
      '请重新确认使用的优惠券，相同卖家配送优惠卷已使用的商品有2件以上。',
    'CART-BR7-15':
      '请重新确认使用的优惠券，不可选择不同支付方式的专享优惠券进行购买。',
    'CART-BR7-16':
      '您无法购买此商品。如有任何疑问，请咨询客服中心。(1566-5701)',
    'CART-BR7-17': '您已是退出会员。请注册后再使用。',
    'CART-BR7-18':
      '订单中有商品已使用海外专用折扣优惠券。因无法用于韩国国内配送，请重新确认使用中的优惠券。',
    'CART-BR7-19':
      '利用SF快递向往中国配送时，商品数量不可超过6件（包括选项/追加商品），商品总额不可超过30,000韩币。',
    'CART-BR7-20':
      '如果您使用SF Express快递到中国，则身体/头发类别的商品总费用必须少于15,000韩元（不含运费）。',
    'CART-BR7-21': '订单中有无法配送到您所选定国家的商品。请您重新确认。',
    'CART-BR7-22': '您所选择的快递公司无法配送至您选定的国家。',
    'CART-BR7-23': '订单中有无法配送到您所选定国家的商品。请您重新确认。',
    'CART-BR7-24': '使用EMS进行配送时，商品总重量不可超过30kg。',
    'CART-BR7-25': '使用顺丰速运进行配送时，商品总重量不可超过30kg。',
    'CART-BR7-26': '使用CJ物流进行配送时，商品总重量不可超过30kg。',
    'CART-BR7-27': '订单中有地点信息遗漏的商品，请您重新确认。',
    'CART-BR7-28':
      '海外直购商品暂时无法与Home plus商品同时购买，请单独购买海外直购商品。',
    'CART-BR7-29':
      'Home plus商品与Mango Korea 商品因配送政策原因不可同时购买，请单独购买。',
    'CART-BR7-31': '您所选择的配送时间已结束，请您重新选择。',
    'CART-BR7-32': 'Home plus商品不可与普通商品同时购买。请您单独购买。',
    'CART-BR7-33': '最多可购买商品为30个。',
    'CART-BR7-34': '订单中有无法购买的商品。',
    'CART-BR7-36':
      '订单中含有因商品缺货或信息变更等原因而无法购买的商品。请您重新确认。商品名: {0}',
    'CART-BR7-39': '',
    'CART-BR7-41': '下单失败。您选择的国家限购买金额(除运费)不可超过{0}元。',
    'CART-BR7-42': '您所选定的配送公司，包裹重量不能超过30kg。',
    'CART-BR7-43':
      '订单中有商品已使用免费配送优惠券。合并配送的商品免运费的情况下无法使用免费配送优惠券。请再次确认您所选的优惠券信息。\n商品名称 : {0}',
    'CART-BR7-47': '由于暂时出现错误，未能正常处理。请重新确认一下。',
    'CART-BR7-48': 'Smile Club促销商品仅限Smile Club会员购买。',
    'CART-BR7-49': 'Smile Club促销商品仅限Smile Club会员购买。',
    'CART-BR7-51': '已选的国家/地区({0})，目前没有快递站点。',
    'CART-BR2-139': '仅限会员使用。请登录。',
  },
}

export const getBrErrorMessage = (brCode: string, data?: unknown): string => {
  const languageType: LanguageType = siteEnv.languageType || 'Korean'
  const defaultMessage =
    defaultMessages[languageType] ||
    '일시적인 문제로 정상처리 되지 않았습니다. 다시 확인 해주세요.'

  if (!messages[languageType]) {
    return defaultMessage
  } else {
    const message = messages[languageType][brCode]
    if (message) {
      if (typeof data === 'string' || typeof data === 'number') {
        return formatString(message, data)
      } else {
        return message
      }
    } else {
      return defaultMessage
    }
  }
}
