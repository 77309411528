/* eslint-disable */
import siteEnv from '~/lib/site-env'
import { openUrlFromHiddenIframe } from '~/lib/utils'

const isProduction = siteEnv.env === 'production' || siteEnv.env === 'av'
const scriptHost = isProduction
  ? '//script.gmarket.co.kr'
  : '//script-dev.gmarket.co.kr'

export default {
  loadScriptByOrder: false, // 순차적으로 로드해야할때만 사용!! 이슈없으면 default는 false다 주의
  headerScriptSources: [
    // scriptHost + '/mobile/v4/gen/IM.Header.min.js',
    // scriptHost + '/mobile/v4/gen/IM.Header.LayerTemplate.min.js',
  ],
  footerScriptSources: [],
  renderHeader: (title) => {
    if (window.GMHeader && !window.gmHeader) {
      window.gmHeader = new GMHeader(HeaderEnum.Type.Simple, title)
    }
  },

  renderBottomNavigation: () => {},

  renderFooter: () => {
    if (window.GMFooter && !window.gmFooter) {
      window.gmFooter = new GMFooter()
    }
  },

  releaseHeader: () => {
    delete window.gmHeader
    // DO NOTHING
  },

  releaseFooter: () => {
    delete window.gmFooter
    // DO NOTHING
  },

  releaseBottomNavigation: () => {},

  setHeaderTitle: (title, isCoreApp) => {
    $('.h_sub').text(title)
    if (isCoreApp) {
      document.title = title
      openUrlFromHiddenIframe(
        'globalgmarket://webview?action=header&type=simple',
      )
    }
  },

  setCloseUrl: (url) => {
    if (window.gmHeader) {
      // window.gmHeader.SetBackUrl(url)
    }
  },
  hideAppHeader: (title) => {
    openUrlFromHiddenIframe('globalgmarket://webview?action=header&type=none')
  },
  showAppHeader: (title) => {
    document.title = title
    openUrlFromHiddenIframe('globalgmarket://webview?action=header&type=simple')
  },
}
