// TODO: 속성별로 분리하면 좋겠는데.. 서로 의존관계가 있어서 어떻게 하면 아름답게 분리할수있을지 고민중..

import { createBrowserHistory, History } from 'history'
import { isEmpty, uniq, uniqueId } from 'lodash'
import moment from 'moment'
import { apiErrorHandler } from '~/api-error-handler'
import {
  fetchAddCart,
  fetchGetCart,
  fetchMerge,
  fetchRemoveCartUnits,
  fetchSetQuantity,
  fetchUnapplyCoupons,
} from '~/apis/cart'
import { fetchIssueCoupon } from '~/apis/coupon'
import { fetchClientError } from '~/apis/log'
import {
  fetchCartBtVimp,
  fetchCartImp,
  fetchGetRecommendItems,
} from '~/apis/recommend'
import { fetchJoinSmileClubByOneClick } from '~/apis/smile'
import {
  initBuyer,
  updateSmileClubMemberInfo,
} from '~/cart/modules/buyer/actions'
import { getIsJoinableSmileClubBiz } from '~/cart/modules/buyer/reducer'
import {
  initCart,
  selectOrDeselectCartUnit,
  setCartTab,
  setIsAppliedCouponQuantityChanged,
} from '~/cart/modules/cart/actions'
import {
  getCartUnitById,
  getCartUnitListByIdList,
  getCurrentCartUnitList,
  getItemGroupMapKey,
  getSameItemGroupCartUnitIds,
  hasPartnershipDiscount,
  sortSignalCartUnits,
} from '~/cart/modules/cart/reducer'
import { ItemGroupView } from '~/cart/modules/cart/types'
import ActionHelper from '~/cart/modules/complex-actions/action-helper'
import { CartException } from '~/cart/modules/complex-actions/cart-exception'
import { handleSmileClubJoinResults } from '~/cart/modules/complex-actions/gateway-handler'
import { applyFundingDiscount } from '~/cart/modules/funding-discount/actions'
import { applyGroupCoupons } from '~/cart/modules/group-coupon/actions'
import { getAppliedGroupCouponList } from '~/cart/modules/group-coupon/reducer'
import {
  fillBtRecommendItemExtInfo,
  setCurrentBtSeedIdx,
} from '~/cart/modules/recommend/actions'
import {
  getBuyUnavailableInfo,
  getIsAllSelected,
  getSelectedCartUnitList,
  RootState,
} from '~/cart/modules/reducers'
import {
  initShipping,
  setLatestShippingAddress,
  setShippingCountry,
} from '~/cart/modules/shipping/actions'
import {
  getLatestShippingAddress,
  getShippingCountry,
} from '~/cart/modules/shipping/reducer'
import {
  initSmileFresh,
  setBranchServiceType,
} from '~/cart/modules/smile-fresh/actions'
import { CouponDownloadMode } from '~/cart/modules/types'
import {
  clearAllUnitCoupons,
  setCouponBoxOpenedCartUnitId,
  setUnitCouponBoxOpened,
  setUnitCouponLoaded,
} from '~/cart/modules/unit-coupon/actions'
import {
  getAppliedUnitCouponList,
  getIsCartUnitCouponBoxOpened,
} from '~/cart/modules/unit-coupon/reducer'
import {
  DownloadableUnitCouponFailedLifeCycle,
  DownloadableUnitCouponFailedReason,
} from '~/cart/modules/unit-coupon/types'
import {
  firstLoaded,
  openLayer,
  setFirstLoadFailed,
  setIsShippingFeeLoadedAfterChanged,
} from '~/cart/modules/view/actions'
import {
  EnumLayerType,
  LayerData,
  OpenLayerPayloadType,
} from '~/cart/modules/view/types'
import areaCodes from '~/data/areaCodes'
import tenantConstants from '~/data/checkout-constants'
import domains from '~/data/domains'
import ActionWrapper, {
  AsyncComplexAction,
  ComplexAction,
  ComplexThunkDispatch,
} from '~/lib/action-wrapper'
import AppSchemeHelper from '~/lib/app-scheme-helper'
import ComplexDataStore from '~/lib/complex-data-store'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import {
  ERROR_HANDLER_ALERT,
  ERROR_HANDLER_DO_NOTHING,
} from '~/lib/default-error-handlers'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import Montelena from '~/lib/montelena'
import simpleSwitch from '~/lib/simple-switch'
import siteEnv from '~/lib/site-env'
import { focusOrScrollIntoView, goSignIn } from '~/lib/utils'
import UXEHelper from '~/lib/uxe-helper'
import TypeMapper from '~/type-mapper'
import {
  BranchServiceType,
  CartTabType,
  EnumGatewayType,
  ExceptionItemType,
  ExpressShopType,
  FakeCouponType,
  IncomeDutyItemOrderType,
  OverseaShippingCompanyType,
  OverseaShippingNotiOrderType,
  ShippingCountryType,
  SmileClubMemberType,
  StaticPageType,
} from '~/types/enums'

const actionWrapper = new ActionWrapper<RootState>(apiErrorHandler)

const browserHistory = ((): History | undefined => {
  try {
    return createBrowserHistory()
  } catch (e) {
    ERROR_HANDLER_DO_NOTHING()(e)
    return undefined
  }
})()

type GetState = () => RootState
type ComplexDispatch = ComplexThunkDispatch<RootState>

export const defaultCartExceptionHandler =
  (
    triggerElement: HTMLElement,
    unexpectedErrorHandler?: (
      dispatch: ComplexDispatch,
      getState: GetState,
    ) => (e: Error) => void,
  ) =>
  (dispatch: ComplexDispatch, getState: GetState) =>
  (e: Error): void => {
    if (e instanceof CartException) {
      if (e.message) {
        window.alert(e.message)
      }
      if (e.focusElementId) {
        focusOrScrollIntoView(e.focusElementId)
      } else if (e.focusElement) {
        focusOrScrollIntoView(e.focusElement)
      }
      if (e.action) {
        dispatch<Promise<void> | void>(e.action(triggerElement))
      }
    } else {
      if (unexpectedErrorHandler) {
        unexpectedErrorHandler(dispatch, getState)(e)
      } else {
        throw e
      }
    }
  }

export const goCartTab = (cartTab: CartTabType): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch, getState: GetState) => {
    const path = ((): string | undefined => {
      const match = window.location.href
        .toLowerCase()
        .match(/\/(ko|en|jp|cn)\/(m|pc)?/)
      const prefix = match && match[0]
      if (!prefix) {
        return undefined
      }
      switch (cartTab) {
        case 'SmileFresh':
          return prefix + '/cart/smilefresh'
        case 'SmileDelivery':
          return prefix + '/cart/smiledelivery'
        case 'ExpressShop':
          return prefix + '/cart/expressshop'
        default:
          return prefix + '/cart'
      }
    })()

    const history = browserHistory

    if (history && path) {
      try {
        history.replace(path + window.location.search + window.location.hash, {
          cartTab,
        })
      } catch (e) {
        ERROR_HANDLER_DO_NOTHING()(e)
      }
    }
    dispatch(setCartTab(cartTab))
    ActionHelper.updateOverseaShippingCost(dispatch, getState).catch(
      ERROR_HANDLER_DO_NOTHING,
    )
    ActionHelper.updateExhibitExtraDiscount(dispatch, getState)
    UXEHelper.onCartTabChanged()
  })

const asyncRoute = (
  history: History,
  path: string,
  method: 'replace' | 'push' | 'go',
  length?: number,
): Promise<void> =>
  new Promise<void>((resolve: () => void) => {
    const unmountListener = history.listen((location) => {
      if (location.pathname === path) {
        unmountListener()
        resolve()
      }
    })

    if (method === 'go' && length !== undefined) {
      history.go(length)
    } else if (method !== 'go') {
      history[method](path)
    } else {
      unmountListener()
      resolve()
      return
    }
  })

export const routeTo = (path: string): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState, history) => {
      const state = getState()

      // 루트페이지가 아니면 무조건 루트페이지로 이동했다가 다시 페이지이동한다
      // 이렇게 하지 않으면 history 가 꼬이기때문임.
      // 만약 stacking 되어야 하는 history 가 있다면 따로 예외처리할것
      if (history.location.pathname !== '/') {
        if (siteEnv.env !== 'production') {
          console.log(
            'window.initialHistoryLength',
            window.initialHistoryLength,
          )
          console.log('window.history.length', window.history.length)
          console.log('history.length', history.length)
          if (window.initialHistoryLength < window.history.length - 1) {
            console.log(
              'goback',
              window.initialHistoryLength - window.history.length,
            )
          }
        }
        if (
          window.initialHistoryLength < window.history.length - 1 &&
          !state.view.isIE // IE는 history.go() 하면 새로고침도 되고.. 문제가 많다.. 무조건 replace 한다..
        ) {
          await asyncRoute(
            history,
            '/',
            'go',
            window.initialHistoryLength - window.history.length,
          )
        } else {
          await asyncRoute(history, '/', 'replace')
        }
      }
      if (path !== '/') {
        return asyncRoute(history, path, 'push')
      }
    },
  )

export const goInitialPath = (): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch) => {
    return dispatch<Promise<void>>(routeTo('/'))
  })

/**
 * @param type
 * @param data
 */
export const openGateway = (
  type: EnumGatewayType,
  data: GatewayData,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    const key = data.key || uniqueId('gw')
    data.key = key

    ComplexDataStore.set('GatewayData', key, data)

    dispatch(routeTo(`/gateway/${type}/${key}`)).catch(ERROR_HANDLER_ALERT())
  })

/**
 * @param type
 * @param data
 */
export const openGatewayAsync = <T = unknown>(
  type: EnumGatewayType,
  data: GatewayData<T>,
): AsyncComplexAction<RootState, T> =>
  actionWrapper.asyncWrapper(
    (dispatch: ComplexDispatch) =>
      new Promise<T>((resolve) => {
        const key = data.key || uniqueId('gw')
        data.key = key
        data.onResolve = resolve

        ComplexDataStore.set<T>('GatewayData', key, data)

        dispatch(routeTo(`/gateway/${type}/${key}`)).catch(
          ERROR_HANDLER_ALERT(),
        )
      }),
  )

/**
 * 뒤로가기할때 꺼지는 Route를 이용한 Layer 띄울때(옥션 모바일 쿠폰함)
 */
export const openRouteLayer = (
  payload: OpenLayerPayloadType,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    const key = uniqueId('rl')
    ComplexDataStore.set('LayerDetailData', key, payload.detailData)

    dispatch(routeTo(`/layer/${payload.type.toString()}/${key}`)).catch(
      ERROR_HANDLER_ALERT(),
    )
  })

export const openStaticPageViaGateway = (
  triggerElement: HTMLElement,
  title: string,
  pageType: StaticPageType,
  gatewayType: EnumGatewayType,
  returnUrl?: string,
  gatewayKey?: string,
  param1?: string,
  param2?: string,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    const useMobilePage =
      !tenantConstants.NotUseMobileVersionStaticPages.includes(pageType)

    const staticPageUrl = `${
      useMobilePage ? domains.CART_FE_CLIENT_MOBILE : domains.CART_FE_CLIENT
    }/static-page`

    dispatch(
      openGateway(gatewayType, {
        key: gatewayKey,
        src: `${staticPageUrl}?pageType=${pageType}${
          returnUrl ? `&returnUrl=${encodeURIComponent(returnUrl)}` : ''
        }&closeUrl=${encodeURIComponent(
          `${domains.CART_FE_SERVER}/gateway/${gatewayKey || '-'}/close`,
        )}${param1 ? `&param1=${param1}` : ''}${
          param2 ? `&param2=${param2}` : ''
        }`,
        title: title,
        triggerElement: triggerElement,
      }),
    )
  })

export const openLayerComplex = (
  payload: OpenLayerPayloadType,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    const newLayer: LayerData = {
      key: uniqueId('layer_'),
      type: payload.type,
    }
    ComplexDataStore.set('LayerDetailData', newLayer.key, payload.detailData)
    dispatch(openLayer(newLayer))
  })

export const openSimpleLayer = (
  type: EnumLayerType,
  triggerElement: HTMLElement,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    dispatch(
      openLayerComplex({
        type,
        detailData: { triggerElement },
      }),
    )
  })

export const visibilityListener = (): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const listener = () => {
      if (!document.hidden) {
        if (CookieHelper.getCookie('visibilityRequest')) {
          const { visibilityType } = JSON.parse(
            unescape(CookieHelper.getCookie('visibilityRequest') || ''),
          )
          switch (visibilityType) {
            case 'SMILECLUB':
              dispatch(handleSmileClubJoinResults())
              break
          }
        }
        window.removeEventListener('visibilitychange', listener, false)
      }
    }
    window.addEventListener('visibilitychange', listener)
  })

/**
 * 초기화
 * 스텝 추가 시 영향도 파악하여 최대한 병렬화 하도록 노력할것..!
 * 지금도 이미 너무 길다. 더 압축할 수 없을까?
 *
 * 화면 뜨기 전/후는 고객에게 로딩화면을 최소한 보여주기 위하여
 * 변화하여도 눈에 띄지 않는곳들은 화면을 띄운상태에서 조회하는 기준
 *
 * 1. 주문단위(CheckoutUnit) 조회 (대기열 체크)
 * 2. 최근 주소 조회(이미 주소 정해진 특수 주문서 제외)
 * 3. 스마일박스 주소인 경우 유효 여부 조회
 * 4. 최초로딩 이전(화면 뜨기 전) 필수 정보
 *  4.1. 배송비 조회 (최초 쿠폰적용 전에 조회 필요)
 *  4.2. 할인 조회 (쿠폰적용정보가 포함되어야해서 쿠폰적용 이후 조회 필요)
 * 5. 최초로딩 이전(화면 뜨기 전) 선택 정보 - 오류무시
 * 6. 최초로딩 이후(화면 뜬 이후) 필수 정보
 * 7. 최초로딩 이후(화면 뜬 이후) 선택 정보 - 오류무시
 */
export const init = (): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState) => {
      await ActionHelper.updateEnv(dispatch)

      const cartData = await fetchGetCart()

      const {
        shippingCountry,
        buyer,
        expressShopDeliveryAddress,
        smileClub,
        carts,
        shippingBundles,
        shippingPolicies,
        removeCartUnitIds,
        isNeedMerge,
        isBusinessBuyer,
        encodedSellerKeys,
        latestShippingAddress,
        smileFreshBranches,
        priorityBranchServiceType,
        cartNudgingTypes,
      } = cartData

      await Promise.all([
        isNeedMerge ? fetchMerge() : undefined,
        removeCartUnitIds && removeCartUnitIds.length > 0
          ? fetchRemoveCartUnits({ cartUnitIds: removeCartUnitIds })
          : undefined,
      ])

      dispatch(initShipping({ shippingCountry: shippingCountry }))

      const cookieTabType = CookieHelper.getCookie(CookieKeys.TabType)
      if (cookieTabType) {
        CookieHelper.setCookie(
          CookieKeys.TabType,
          '',
          moment().subtract(1, 'day').toDate(),
        )

        if (tenantConstants.IsUsingCartTab) {
          const currentCartTab = simpleSwitch<CartTabType, string>(
            cookieTabType,
          )
            .on('smilefresh', 'SmileFresh')
            .on('smiledelivery', 'SmileDelivery')
            .on('expressshop', 'ExpressShop')
            .otherwise('All')
          dispatch(goCartTab(currentCartTab))
        }
      }

      if (latestShippingAddress) {
        const {
          addressNo,
          address,
          zipCode,
          countryType,
          alterType,
          alterText,
        } = latestShippingAddress

        if (address && countryType && addressNo) {
          const isZipCodeRequired = countryType !== 'HongKong' // 홍콩만 zipCode가 없다
          dispatch(
            setLatestShippingAddress({
              addressNo: addressNo,
              address: address.trim(),
              zipCode: isZipCodeRequired ? zipCode?.trim() : '',
              countryType: countryType,
              alterType: alterType || 'Unknown',
              alterText,
            }),
          )
        } else if (!addressNo && alterType === 'None') {
          // 최근배송지 없는 경우
          dispatch(
            setLatestShippingAddress({
              addressNo: addressNo || 0,
              address: '',
              zipCode: zipCode,
              countryType: countryType || 'Unknown',
              alterType: alterType || 'Unknown',
              alterText,
            }),
          )
        } else if (!address && addressNo && alterType === 'NotSupport') {
          // 주소는 있으나 유효하지 않은 주소인 경우 (NotSupport)
          // 1)우편번호6자리
          // 2)영문주소의 경우
          // 3)유효하지 않은 주소의 경우
          dispatch(
            setLatestShippingAddress({
              addressNo: addressNo || 0,
              address: '',
              zipCode: zipCode,
              countryType: countryType || 'Unknown',
              alterType: alterType || 'NotSupport',
              alterText,
            }),
          )
        }
      }

      if (smileFreshBranches && smileFreshBranches.length > 0) {
        const branchServiceType: BranchServiceType =
          priorityBranchServiceType && priorityBranchServiceType !== 'Unknown'
            ? priorityBranchServiceType
            : 'Reserve'

        dispatch(
          initSmileFresh({
            currentBranchServiceType: branchServiceType,
            availableBranches: smileFreshBranches.map(
              TypeMapper.asSmileFreshBranch,
            ),
          }),
        )
      }

      ActionHelper.setShippingFeeReduxInfo(
        dispatch,
        getState,
        shippingBundles,
        shippingPolicies,
      )

      if (buyer) {
        dispatch(
          initBuyer({
            buyerName: buyer.buyerName,
            loginId: buyer.loginId,
            buyerGrade: buyer.buyerGrade,
            memberType: buyer.simpleMember ? 'SimpleMember' : 'Member',
            isSimpleJoinForeigner: buyer.simpleJoinForeigner,
            isDomesticBusinessman: isBusinessBuyer,
            isSmileClub: smileClub && smileClub.isSmileClubMember,
            smileClubInfo: smileClub && {
              memberType: simpleSwitch<SmileClubMemberType, string>(
                smileClub.smileClubMemberType,
              )
                .on('S1', 'Waiting')
                .on('SF', 'Free')
                .on('SP', 'Purchase')
                .on('S3', 'Restricted')
                .on('S4', 'Withdrawal')
                .otherwise('Unknown'),
              membershipWithdrawDate: smileClub.membershipWithdrawDate,
            },
            expressShopDeliveryAddress:
              expressShopDeliveryAddress &&
              expressShopDeliveryAddress.address &&
              expressShopDeliveryAddress.receiverName
                ? {
                    ...expressShopDeliveryAddress,
                    address: expressShopDeliveryAddress.address,
                    receiverName: expressShopDeliveryAddress.receiverName,
                  }
                : undefined,
            cartSize: buyer.cartSize,
          }),
        )
      } else {
        dispatch(
          initBuyer({
            memberType: 'NonMember',
            isDomesticBusinessman: isBusinessBuyer,
          }),
        )
      }

      if (carts) {
        dispatch(
          initCart({
            cartUnitList: carts.map(TypeMapper.asCartUnit),
            encodedSellerKeys: encodedSellerKeys || {},
            cartNudgingTypes,
          }),
        )

        const state = getState()
        const selectedCartUnitIdList = carts
          .filter(
            (x) =>
              moment(x.insertDateISO).isAfter(moment().subtract(1, 'day')) &&
              !getBuyUnavailableInfo(state, x.cartUnitId),
          )
          .flatMap((x) => (x.cartUnitId ? [x.cartUnitId] : []))

        if (selectedCartUnitIdList.length > 0) {
          const state = getState()

          dispatch(setIsShippingFeeLoadedAfterChanged(true))
          await ActionHelper.selectOrDeselectCartUnitsComplex(
            dispatch,
            getState,
            uniq(
              selectedCartUnitIdList.flatMap((cartUnitId) => {
                return getSameItemGroupCartUnitIds(state.cart, cartUnitId)
              }),
            ),
            true,
          )
        }

        ActionHelper.setFundingDiscountReduxInfo(dispatch, carts)
      }
      // region 최초로딩 이전(화면 뜨기 전)

      /**
       * 4. 최초로딩 이전(화면 뜨기 전) 필수 정보
       */
      await Promise.all([])

      /**
       * 5. 최초로딩 이전(화면 뜨기 전) 선택 정보 - 오류무시
       * allSettled 를 사용하면 중간에 실패된 애가 있어도 모두 기다린다
       * - 적용된 쿠폰 정보 조회 (할인, 배송비 정보 필요)
       * - 해외배송국가 전체 조회(G마켓)
       * - 스마일클럽 원클릭 대상자 여부조
       */
      await Promise.allSettled([
        // 주문서 들어오는 시점에 이미 적용되어있는 쿠폰 조회 (이미 설정한 쿠폰이 적용안되어도 주문서는 떠야함)
        tenantConstants.CouponUseType === 'UnitCoupon'
          ? ActionHelper.updateAppliedUnitCoupons(dispatch, getState)
          : Promise.resolve(),

        // 해외배송국가 전체 조회
        ActionHelper.updateAllOverseaShippingCountries(dispatch).then(() =>
          ActionHelper.onChangeShippingCountry(dispatch, getState).then(() =>
            ActionHelper.updateShippingUnavailableItems(
              dispatch,
              getState,
            ).then(() =>
              ActionHelper.updateOverseaShippingCost(dispatch, getState),
            ),
          ),
        ),

        ActionHelper.updateSmileClubOneClickInfo(dispatch, getState),
      ]).then((results) => {
        results.forEach((result) => {
          if (result.status === 'rejected') {
            ERROR_HANDLER_DO_NOTHING()(result.reason)
          }
        })
      })
      // endregion 최초로딩 이전(화면 뜨기 전)

      if (tenantConstants.PageShowingPoint !== 'FullLoad') {
        dispatch(firstLoaded())
        UXEHelper.onLoad()
      }

      // region 최초로딩 이후(화면 뜬 이후)

      /**
       * 6. 최초로딩 이후(화면 뜬 이후) 필수 정보
       */
      await Promise.all([])

      /**
       * 7. 최초로딩 이후(화면 뜬 이후) 선택 정보 - 오류무시
       * allSettled 를 사용하면 중간에 실패된 애가 있어도 모두 기다린다
       * - 전체 캐시백 조회 (할인, 배송비 정보 필요)
       * - 배송예정일 정보 조회
       * - BSD 조회
       * - 시그널 조회
       * - 사은품/덤 조회
       * - 필요한 환율정보 조회
       * - Cart BT 초기화
       * - 각종 넛징용 레이어 초기화
       */
      await Promise.allSettled([
        ActionHelper.updateSmileCashback(dispatch, getState),

        // 배송예정일 정보 조회
        ActionHelper.updateTransPolicyInfo(dispatch, getState),

        // 필요한 환율정보 조회
        ActionHelper.updateExchangeInfo(dispatch, getState),

        // BSD 조회
        ActionHelper.updateBsdInfo(dispatch, getState),

        // 시그널 조회
        ActionHelper.updateSignalCartUnit(
          dispatch,
          getState,
          sortSignalCartUnits(getState().cart),
        ),

        // 사은품/덤 조회
        ActionHelper.updatePurchaseBenefitInfo(dispatch, getState),

        // Cart BT 초기화
        ActionHelper.initCartBtItems(dispatch, getState),

        // 통합멤버십 대상자 조회
        ActionHelper.updateUnifyMembershipInfo(dispatch, getState),

        // 각종 넛징용 레이어 초기화
        ActionHelper.initLayers(dispatch, getState),

        // hashedCguid 조회 및 Google Script PageView event 발행
        ActionHelper.updateHashedCguid(dispatch, getState),
      ]).then((results) => {
        results.forEach((result) => {
          if (result.status === 'rejected') {
            ERROR_HANDLER_DO_NOTHING()(result.reason)
          }
        })
      })

      // endregion 최초로딩 이후(화면 뜬 이후)

      if (tenantConstants.PageShowingPoint === 'FullLoad') {
        dispatch(firstLoaded())
        UXEHelper.onLoad()
      }
    },
    (dispatch) =>
      (e): void => {
        fetchClientError(e, true).catch((err) => {
          console.error(err)
        })
        // 최초 로딩시에 문제 발생하면 오류설정해준다.
        dispatch(setFirstLoadFailed(true))
      },
  )

/**
 * 옥션 쿠폰함 열기
 * @param itemGroup
 * @param triggerElement
 */
export const openGroupCouponBox = (
  itemGroup: ItemGroupView,
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    const state = getState()
    if (state.buyer.memberType === 'NonMember') {
      const exception = new CartException('CART-BR5-01')
      window.alert(exception.message)
      goSignIn()
      return
    }

    const firstCartUnit = getCartUnitById(
      state.cart,
      itemGroup.cartUnitIdList[0],
    )
    if (firstCartUnit) {
      if (!firstCartUnit.isCouponUsable) {
        switch (firstCartUnit.unusableCouponType) {
          case 'USED_ITEM':
            window.alert(
              '고객님, 죄송합니다.\n중고 상품에 쿠폰을 적용할 수 없습니다.',
            )
            return
          default:
            window.alert(
              '고객님, 죄송합니다.\n이 상품은 쿠폰을 적용할 수 없는 상품입니다.',
            )
            return
        }
      }
    }

    const recommendedGroupCouponKey = getItemGroupMapKey(state.cart, itemGroup)

    const needsGroupCouponLoad = !itemGroup.cartUnitIdList.some((cartUnitId) =>
      hasPartnershipDiscount(state.cart, cartUnitId),
    )

    if (needsGroupCouponLoad) {
      await ActionHelper.loadGroupCoupon(
        dispatch,
        getState,
        itemGroup.cartUnitIdList,
        recommendedGroupCouponKey,
      )
    }

    // 모바일의 경우 쿠폰함이 전체화면으로 켜지기때문에
    // 뒤로갈때 꺼질 수 있도록 Route 로 이동하는 레이어로 띄워준다
    if (state.view.isMobile) {
      dispatch(
        openRouteLayer({
          type: EnumLayerType.GroupCouponBox,
          detailData: {
            triggerElement,
            groupCouponBox: {
              itemGroupView: itemGroup,
            },
          },
        }),
      )
    } else {
      dispatch(
        openLayerComplex({
          type: EnumLayerType.GroupCouponBox,
          detailData: {
            triggerElement,
            groupCouponBox: {
              itemGroupView: itemGroup,
            },
          },
        }),
      )
    }
  })

/**
 * G/G9 쿠폰함 열기
 * @param cartUnitId
 * @param triggerElement
 */
export const openUnitCouponBox = (
  cartUnitId: number,
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    const state = getState()
    if (state.buyer.memberType === 'NonMember') {
      const exception = new CartException('CART-BR5-01')
      window.alert(exception.message)
      goSignIn()
      return
    }

    if (!!state.unitCoupon.couponBoxOpenedCartUnitId) {
      dispatch(setUnitCouponBoxOpened(undefined))
    }

    const cartUnit = getCartUnitById(state.cart, cartUnitId)
    if (cartUnit) {
      if (!cartUnit.isCouponUsable) {
        switch (cartUnit.unusableCouponType) {
          case 'USED_ITEM':
            window.alert(
              '고객님, 죄송합니다.\n중고 상품에 쿠폰을 적용할 수 없습니다.',
            )
            return
          default:
            window.alert(
              '고객님, 죄송합니다.\n이 상품은 쿠폰을 적용할 수 없는 상품입니다.',
            )
            return
        }
      }
    }

    // 쿠폰함 열때 동일 상품그룹 선택해준다.
    // 배송비쿠폰은 선택되어있어야 배송비가 계산되기때문에 추가된 내용, but 항상 적용된다
    const sameItemGroupCartUnitIds = getSameItemGroupCartUnitIds(
      state.cart,
      cartUnitId,
    )

    ActionHelper.selectOrDeselectCartUnitsComplex(
      dispatch,
      getState,
      sameItemGroupCartUnitIds,
      true,
    )

    dispatch(setUnitCouponLoaded({ cartUnitId, isLoaded: false }))
    dispatch(setUnitCouponBoxOpened(cartUnitId))

    await ActionHelper.loadUnitCoupon(dispatch, getState, cartUnitId)

    dispatch(setUnitCouponLoaded({ cartUnitId, isLoaded: true }))

    await ActionHelper.updateSignalCartUnit(
      dispatch,
      getState,
      cartUnit ? [cartUnit] : [],
    )

    if (tenantConstants.IsUsingCouponBoxLayer) {
      dispatch(
        openLayerComplex({
          type: EnumLayerType.UnitCouponBox,
          detailData: {
            triggerElement,
            unitCouponBox: {
              cartUnitId,
            },
          },
        }),
      )
    }
  }, ERROR_HANDLER_ALERT)

/**
 * 옥션 쿠폰 적용하기
 * @param cartUnitIdList
 * @param selectedCouponIssueNoes
 * @param triggerElement
 */
export const applyGroupCouponComplex = (
  cartUnitIdList: number[],
  selectedCouponIssueNoes: number[],
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    // 옥션 쿠폰 적용
    const isSuccess = await ActionHelper.applyGroupCouponComplex(
      dispatch,
      getState,
      cartUnitIdList,
      selectedCouponIssueNoes,
      triggerElement,
    )

    if (isSuccess) {
      // 쿠폰 적용 후 업데이트
      ActionHelper.updateSmileCashback(
        dispatch,
        getState,
        cartUnitIdList,
      ).catch(ERROR_HANDLER_DO_NOTHING())
    }

    return isSuccess
  }, ERROR_HANDLER_ALERT)

/**
 * 옥션 쿠폰 발급번호 업데이트
 * @param cartUnitIdList
 * @param couponPolicyNo
 */
export const updateGroupCouponIssueNo = (
  cartUnitIdList: number[],
  couponPolicyNo: number,
): AsyncComplexAction<RootState, number | undefined> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    // 쿠폰 발급번호 조회 후 업데이트
    return ActionHelper.updateGroupCouponIssueNo(
      dispatch,
      getState,
      cartUnitIdList,
      couponPolicyNo,
    )
  }, ERROR_HANDLER_ALERT)

/**
 * 쿠폰 다운로드
 * @param eventNo 이벤트번호
 * @param encStr 암호화 문자열
 */
export const issueCoupon = (
  eventNo: number,
  encStr: string,
): AsyncComplexAction<
  RootState,
  {
    isSuccess: boolean
    failedReason?: DownloadableUnitCouponFailedReason
    failedLifeCycle?: DownloadableUnitCouponFailedLifeCycle
  }
> =>
  actionWrapper.asyncWrapper(async () => {
    const { isSuccess, errorReason, errorLifeCycle } = await fetchIssueCoupon(
      eventNo,
      encStr,
    )

    return {
      isSuccess: isSuccess,
      failedReason: errorReason,
      failedLifeCycle: errorLifeCycle,
    }
  }, ERROR_HANDLER_ALERT)

/**
 * G/G9 쿠폰 적용하기
 * @param cartUnitId
 * @param selectedCouponIssueNoes
 * @param triggerElement
 */
export const applyUnitCouponComplex = (
  cartUnitId: number,
  selectedCouponIssueNoes: number[],
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    // 쿠폰 적용
    const isSuccess = await ActionHelper.applyUnitCouponComplex(
      dispatch,
      getState,
      cartUnitId,
      selectedCouponIssueNoes,
      triggerElement,
    )

    if (isSuccess) {
      // 쿠폰 적용 후 업데이트
      ActionHelper.updateSmileCashback(dispatch, getState, [cartUnitId]).catch(
        ERROR_HANDLER_DO_NOTHING(),
      )
    }

    return isSuccess
  }, ERROR_HANDLER_ALERT)

/**
 * 지마켓 쿠폰 발급번호 업데이트
 * @param cartUnitId
 * @param couponPolicyNo
 * @param couponDownloadMode
 */
export const updateUnitCouponIssueNo = (
  cartUnitId: number,
  couponPolicyNo: number,
  couponDownloadMode: CouponDownloadMode,
): AsyncComplexAction<RootState, number | undefined> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    // 쿠폰 발급번호 조회 후 업데이트
    return ActionHelper.updateUnitCouponIssueNo(
      dispatch,
      getState,
      cartUnitId,
      couponPolicyNo,
      couponDownloadMode,
    )
  }, ERROR_HANDLER_ALERT)

/**
 * G9 쿠폰 해제하기(쿠폰번호기준)
 * @param couponIssueNoesToUnapply
 * @param triggerElement
 */
export const unapplyUnitCouponComplex = (
  couponIssueNoesToUnapply: number[],
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    // 쿠폰 해제
    const isSuccess = await ActionHelper.unapplyUnitCouponComplex(
      dispatch,
      getState,
      couponIssueNoesToUnapply,
      triggerElement,
    )

    if (isSuccess) {
      // 쿠폰 적용 후 업데이트
      ActionHelper.updateSmileCashback(dispatch, getState, []).catch(
        ERROR_HANDLER_DO_NOTHING(),
      )
    }

    return isSuccess
  }, ERROR_HANDLER_ALERT)

export const openSmileClubJoin = (
  triggerElement: HTMLElement,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch, getState) => {
    const state = getState()

    const isFreeClubMember =
      state.buyer.smileClubInfo &&
      state.buyer.smileClubInfo.memberType === 'Free'

    const isJoinableSmileClubBiz = getIsJoinableSmileClubBiz(state.buyer)

    const returnUrl = encodeURIComponent(
      `${domains.CART_FE_SERVER}/gateway/-/smile-club/join`,
    )
    const cancelUrl = encodeURIComponent(
      `${domains.CART_FE_SERVER}/gateway/-/smile-club/close`,
    )

    const url = isJoinableSmileClubBiz
      ? `${domains.SMILE_CLUB}/join-gate/index?fromPage=order&type=biz&returnUrl=${returnUrl}&cancelUrl=${cancelUrl}`
      : !isFreeClubMember
      ? `${domains.SMILE_CLUB}/join-gate?fromPage=order_SmileCash&returnUrl=${returnUrl}&cancelUrl=${cancelUrl}`
      : formatString(
          domains.SSL_MEMBER_SMILE_CLUB_GATE,
          encodeURIComponent(
            `${domains.SMILE_CLUB}/to-paid-gate` +
              `?returnUrl=${returnUrl}` +
              `&cancelUrl=${cancelUrl}`,
          ),
        )

    if (state.view.isApp) {
      const appScheme = AppSchemeHelper.getOpenWindowScheme(
        state.view.isApp,
        state.view.isAndroid,
        state.view.appInfo?.appType,
        state.view.appInfo?.appVersion,
      )
      if (appScheme) {
        dispatch(visibilityListener())
        window.location.href = formatString(
          appScheme,
          encodeURIComponent(url),
          encodeURIComponent('유니버스 멤버십 가입'),
        )
      }
    } else if (state.view.isMobile) {
      window.open(url)
    } else {
      dispatch(
        openGatewayAsync(EnumGatewayType.SmileClubGateway, {
          src: url,
          title: '유니버스 멤버십 가입',
          isNotDimmed: true,
          triggerElement,
        }),
      )
    }
  })

export const openOneClickJoin = (
  triggerElement: HTMLElement,
  fromPage?: string,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch, getState) => {
    const state = getState()

    const returnUrl = encodeURIComponent(
      `${domains.CART_FE_SERVER}/gateway/-/smile-club/join`,
    )
    const cancelUrl = encodeURIComponent(
      `${domains.CART_FE_SERVER}/gateway/-/smile-club/close`,
    )
    const url = `${domains.UNINVERSE_CLUB}/membership/join/oneclick?fromPage=${fromPage}&returnUrl=${returnUrl}&cancelUrl=${cancelUrl}`

    if (state.view.isApp) {
      const appScheme = AppSchemeHelper.getOpenWindowScheme(
        state.view.isApp,
        state.view.isAndroid,
        state.view.appInfo?.appType,
        state.view.appInfo?.appVersion,
      )
      if (appScheme) {
        dispatch(visibilityListener())
        window.location.href = formatString(
          appScheme,
          encodeURIComponent(url),
          encodeURIComponent('유니버스 멤버십 가입'),
        )
      }
    } else if (state.view.isMobile) {
      window.open(url)
    } else {
      dispatch(
        openGatewayAsync(EnumGatewayType.SmileClubGateway, {
          src: url,
          title: '유니버스 멤버십 가입',
          isNotDimmed: true,
          triggerElement,
        }),
      )
    }
  })
export const openAddressBookForFastDelivery = (
  triggerElement: HTMLElement,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch, getState) => {
    const state = getState()

    const isMember = state.buyer.memberType !== 'NonMember'
    const isSmileClub = state.buyer.isSmileClub
    const latestShippingAddress = getLatestShippingAddress(state.shipping)
    const isSmileFresh = state.cart.currentCartTab === 'SmileFresh'

    const returnUrl = encodeURIComponent(
      `${domains.CART_FE_SERVER}/gateway/-/address-book/return`,
    )

    if (isMember) {
      const url =
        // 배송주소록 API 조회 에러이거나 최근 배송지 없는 경우 배송지 추가하기
        (!latestShippingAddress ||
          latestShippingAddress.alterType === 'None') &&
        domains.ADDRESS_BOOK_ADD
          ? formatString(domains.ADDRESS_BOOK_ADD, returnUrl, '')
          : domains.ADDRESS_BOOK
          ? formatString(
              domains.ADDRESS_BOOK,
              returnUrl,
              '',
              'K',
              isSmileClub ? 'Y' : 'N',
              isSmileFresh ? 'Y' : 'N',
            )
          : undefined

      if (url) {
        dispatch(
          openGateway(EnumGatewayType.AddressBook, {
            src: url,
            title: '배송주소록',
            triggerElement,
          }),
        )
      }
    } else {
      goSignIn()
    }
  })

export const selectOverseaShippingCompanyComplex = (
  shippingCompanyType: OverseaShippingCompanyType,
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      await ActionHelper.selectOverseaShippingCompanyComplex(
        dispatch,
        getState,
        shippingCompanyType,
      )
    },
  )

export const toggleCartSelectorComplex = (
  cartUnitIdList?: number[],
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    const state = getState()
    const isAllSelected = getIsAllSelected(state, cartUnitIdList)
    const cartUnitList = cartUnitIdList
      ? getCartUnitListByIdList(state.cart, cartUnitIdList)
      : getCurrentCartUnitList(state.cart)
    const withBuyUnavailable =
      isAllSelected ||
      cartUnitList.every((cartUnit) =>
        getBuyUnavailableInfo(state, cartUnit.cartUnitId),
      )
    ActionHelper.selectOrDeselectCartUnitsComplex(
      dispatch,
      getState,
      cartUnitList.map((x) => x.cartUnitId),
      !isAllSelected,
      withBuyUnavailable,
    )
  })

export const removeCartUnits = (
  triggerElements: HTMLElement,
  cartUnitIdList?: number[],
  redirectUrl?: string,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    const state = getState()

    const selectedCartUnitList = cartUnitIdList
      ? getCartUnitListByIdList(state.cart, cartUnitIdList)
      : getSelectedCartUnitList(state, true)

    if (selectedCartUnitList.length === 0) {
      throw new CartException('CART-BR4-09')
    }

    const selectedCartUnitIdList = selectedCartUnitList.map((x) => x.cartUnitId)

    const sameItemGroupCartUnitIds = uniq(
      selectedCartUnitIdList.flatMap((cartUnitId) =>
        getSameItemGroupCartUnitIds(state.cart, cartUnitId),
      ),
    )

    const message = redirectUrl
      ? '선택하신 상품을 삭제하고 이동하시겠습니까?'
      : __('ESCROW_BASKET_ALERT_4', '선택하신 상품을 삭제하시겠습니까?')

    if (window.confirm(message)) {
      const result = await fetchRemoveCartUnits({
        cartUnitIds: selectedCartUnitIdList,
        deleteCauseType: 'Buyer',
      })
      if (result.success) {
        if (redirectUrl) {
          window.location.href = redirectUrl
          return
        }

        ActionHelper.removeCartUnits(dispatch, getState, selectedCartUnitIdList)

        await Promise.all([
          ActionHelper.updateDiscountsComplex(
            dispatch,
            getState,
            sameItemGroupCartUnitIds,
          ),
          ActionHelper.updateShippingFee(
            dispatch,
            getState,
            selectedCartUnitList,
          ),
          ActionHelper.updateOverseaShippingCost(dispatch, getState),
          ActionHelper.updateExhibitExtraDiscount(dispatch, getState),
        ])

        return true
      } else {
        window.alert(result.message || 'failed')
        return false
      }
    }
  }, defaultCartExceptionHandler(triggerElements))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getExpressShopTimetableUrl = (
  expressShopType: ExpressShopType,
): string | undefined => {
  const url = domains.EXPRESS_SHOP_TIMETABLE

  if (domains.EXPRESS_SHOP_TIMETABLE && tenantConstants.ExpressShopSellerIds) {
    formatString(
      domains.EXPRESS_SHOP_TIMETABLE,
      tenantConstants.ExpressShopSellerIds[expressShopType],
    )
  }

  if (!url) {
    return undefined
  }

  switch (expressShopType) {
    case 'LotteSuper':
      return url + 'lottesuper'
    case 'GSFresh':
      return url + 'gsfresh300'
    case 'LotteMart':
      // dev:testlotte / real: lottemartm
      return url + 'testlotte'
    default:
      return url + 'SHomeplus'
  }
}

export const openExpressTimeTable = (
  triggerElement: HTMLElement,
  expressShopType: ExpressShopType,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    if (
      domains.EXPRESS_SHOP_TIMETABLE &&
      tenantConstants.ExpressShopSellerIds
    ) {
      const url = formatString(
        domains.EXPRESS_SHOP_TIMETABLE,
        tenantConstants.ExpressShopSellerIds[expressShopType],
        `${domains.CART_FE_SERVER}/gateway/-/close`,
      )

      dispatch(
        openGateway(EnumGatewayType.ExpressShop, {
          src: url,
          title: '스마일클럽',
          triggerElement,
        }),
      )
    }
  })

export const setCartUnitQuantityComplex = (
  triggerElement: HTMLElement,
  cartUnitId: number,
  quantity: number,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      const state = getState()

      if (getIsCartUnitCouponBoxOpened(state.unitCoupon, cartUnitId)) {
        dispatch(setCouponBoxOpenedCartUnitId(undefined))
      }

      if (isNaN(quantity)) {
        throw new CartException('CART-BR3-16', triggerElement)
      } else if (quantity < 1) {
        throw new CartException('CART-BR3-01', triggerElement, 1)
      } else if (quantity > 999) {
        throw new CartException('CART-BR3-09', triggerElement)
      }

      const appliedGroupCoupons = getAppliedGroupCouponList(state.groupCoupon, [
        cartUnitId,
      ])
      appliedGroupCoupons.forEach((coupon) =>
        dispatch(applyGroupCoupons([{ couponIssueNo: coupon.couponIssueNo }])),
      )

      const sameItemGroupCartUnitIds = getSameItemGroupCartUnitIds(
        state.cart,
        cartUnitId,
      )
      dispatch(
        applyFundingDiscount(
          sameItemGroupCartUnitIds.map((cartUnitId) => ({ cartUnitId })),
        ),
      ) // 카드사 즉시할인 떼기

      const appliedCoupons = getAppliedUnitCouponList(
        state.unitCoupon,
        cartUnitId,
      )
      if (
        appliedCoupons &&
        appliedCoupons.length > 0 &&
        appliedCoupons.filter((x) => x.couponPrice && x.couponPrice > 0)
          .length > 0
      ) {
        // 토스트 얼럿 노출
        dispatch(setIsAppliedCouponQuantityChanged(true))

        // 쿠폰 때기
        const unapplyRequest = appliedCoupons.map<number>(
          (appliedCoupon) => appliedCoupon.couponIssueNo,
        )

        const result = await fetchUnapplyCoupons({
          couponIssueNoes: unapplyRequest,
        })

        dispatch(
          clearAllUnitCoupons({
            cartUnitId,
          }),
        )
      }

      const branchServiceType = state.smileFresh.currentBranchServiceType
      const changeResult = await fetchSetQuantity({
        cartUnitId,
        quantity,
        branchServiceType,
      })
      if (changeResult && changeResult.success) {
        await ActionHelper.updateCartUnitsComplex(
          dispatch,
          getState,
          [cartUnitId],
          false,
          false,
          true,
        )
        return true
      }
      return false
    },
    defaultCartExceptionHandler(triggerElement, ERROR_HANDLER_DO_NOTHING),
  )

export const clickFakeCoupon = (
  triggerElement: HTMLElement,
  fakeCouponType: FakeCouponType,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    if (fakeCouponType === 'SmileClubOneClick') {
      dispatch(
        openSimpleLayer(EnumLayerType.SmileClubOneClickJoin, triggerElement),
      )
    }
  })

// 원클릭 가입
export const joinSmileClubByOneClick = (
  fromType: 'FakeCouponLayer' | 'Banner',
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch, getState) => {
    const result = await fetchJoinSmileClubByOneClick()
    if (!result) {
      window.alert(
        '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다.',
      )
      return false
    }

    const impressionCode =
      fromType === 'FakeCouponLayer'
        ? areaCodes.COMPLETE_JOIN_ONE_CLICK_ON_COUPON
        : areaCodes.COMPLETE_JOIN_ONE_CLICK_ON_BANNER

    if (impressionCode) {
      Montelena.logImpression(
        'IMP_VI',
        impressionCode,
        { subscriptionno: result.subscriptionNo },
        'joinSmileClubByOneClick',
      )
    }

    // 클럽 가입 성공했으면 그냥 true 처리
    dispatch(
      updateSmileClubMemberInfo({
        isSmileClub: true, //smileClub.isMember,
        smileClubInfo: {
          memberType: 'Unknown',
        },
      }),
    )

    // 클럽 가입 완료 후 배송스케줄 재조회 및 업데이트
    await ActionHelper.updateTransPolicyInfo(dispatch, getState)
    // TODO 쿠폰함에서 연 경우는 쿠폰 재조회 필요
    return true
  }, ERROR_HANDLER_ALERT)

export const openCountrySelector = (
  triggerElement: HTMLElement,
): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    dispatch(openSimpleLayer(EnumLayerType.CountrySelector, triggerElement))
  })

export const setShippingCountryComplex = (
  countryType: ShippingCountryType,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      const prevCountry = getShippingCountry(getState().shipping).countryType
      if (prevCountry === countryType) {
        // 국가가 안바뀌었으면 아무것도 안하고 return
        return true
      }
      dispatch(setShippingCountry(countryType))

      const state = getState()
      const shippingCountry = getShippingCountry(state.shipping)

      CookieHelper.setCookie(
        CookieKeys.ShippingCountry,
        shippingCountry.countryCode,
        undefined,
        domains.DOMAIN_FOR_COOKIE,
      )

      // 해외배송->국내배송 / 국내배송->해외배송 변경시 장바구니 전체를 다시 조회한다.(배송비, 배송비그룹(bundleKey)이 변경되기때문)
      const wasOverseaShipping = prevCountry !== 'SouthKorea'
      const isOverseaShipping = countryType !== 'SouthKorea'
      if (wasOverseaShipping !== isOverseaShipping) {
        await ActionHelper.updateCartUnitsComplex(dispatch, getState)
      }
      await ActionHelper.onChangeShippingCountry(dispatch, getState)
      await ActionHelper.updateShippingUnavailableItems(dispatch, getState)
      return true
    },
    ERROR_HANDLER_ALERT,
  )

export const updateTransPolicyInfo = (): AsyncComplexAction<
  RootState,
  boolean
> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      await ActionHelper.updateTransPolicyInfo(dispatch, getState)
    },
  )

export const confirmOrderExceptSomeItems = (
  showingItems: {
    itemNo: string
    itemName: string
    itemImageUrl?: string
    exceptionItemType: ExceptionItemType
  }[],
  text1: string,
  text2: string,
  text3: string,
  subText: string,
  buttonText: string,
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch) => {
    return new Promise<boolean>((resolve) => {
      dispatch(
        openLayerComplex({
          type: EnumLayerType.ConfirmOrderExceptSomeItems,
          detailData: {
            triggerElement,
            confirmOrderExceptSomeItems: {
              showingItems,
              text1,
              text2,
              text3,
              subText,
              buttonText,
              onConfirm: (isConfirm): void => {
                resolve(isConfirm)
              },
            },
          },
        }),
      )
    })
  })

export const confirmIncomeDutyItemOrderType = (
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, IncomeDutyItemOrderType | undefined> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch) => {
    return new Promise<IncomeDutyItemOrderType | undefined>((resolve) => {
      dispatch(
        openLayerComplex({
          type: EnumLayerType.ConfirmIncomeDutyItemOrderType,
          detailData: {
            triggerElement,
            confirmIncomeDutyItemOrderType: {
              onConfirm: (orderType): void => {
                resolve(orderType)
              },
            },
          },
        }),
      )
    })
  })

export const confirmOverseaShippingNoti = (
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, OverseaShippingNotiOrderType | undefined> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch) => {
    return new Promise<OverseaShippingNotiOrderType | undefined>((resolve) => {
      dispatch(
        openLayerComplex({
          type: EnumLayerType.OverseaPackageNoti,
          detailData: {
            triggerElement,
            confirmOverseaShippingNotiType: {
              onConfirm: (orderType): void => {
                resolve(orderType)
              },
            },
          },
        }),
      )
    })
  })

export const confirmAuthentication = (
  message: string,
  triggerElement: HTMLElement,
): AsyncComplexAction<RootState, boolean> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch) => {
    return new Promise<boolean>((resolve) => {
      dispatch(
        openLayerComplex({
          type: EnumLayerType.ConfirmAuthentication,
          detailData: {
            triggerElement,
            confirmAuthentication: {
              message,
              onConfirm: (isConfirm): void => {
                resolve(isConfirm)
              },
            },
          },
        }),
      )
    })
  })

export const switchToNextBtItem = (): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      const state = getState()
      const totalRecommendSeedItemCount = state.recommend.btItemMap.length
      if (totalRecommendSeedItemCount === 0) {
        return
      }

      const currentIndex = state.recommend.currentBtSeedIdx
      const nextIndex =
        currentIndex >= 0 && currentIndex + 1 < totalRecommendSeedItemCount
          ? currentIndex + 1
          : 0

      const nextItemMap = state.recommend.btItemMap[nextIndex]

      if (!nextItemMap) {
        return
      }

      if (!nextItemMap.isExtInfoFilled) {
        const itemExtInfo = await fetchGetRecommendItems({
          itemNos: nextItemMap.recommendItems.map((x) => x.itemNo),
        })
        dispatch(
          fillBtRecommendItemExtInfo({
            seedItemNo: nextItemMap.seedItemNo,
            itemExtInfoList: itemExtInfo,
          }),
        )
      }

      dispatch(setCurrentBtSeedIdx(nextIndex))
    },
  )

export const addCartFromRecommend = (
  itemNo: string,
  currentBranchServiceType: string,
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      const result = await fetchAddCart({
        cartUnits: [
          {
            requestKey: 0,
            itemNo: itemNo,
            quantity: 1,
            shippingMethodType: 'General',
            shippingChargePayType: 'PaymentInAdvance',
          },
        ],
        branchServiceType: currentBranchServiceType,
      })

      // 담기 후 이것저것 업데이트해주는데 그것까지 모두 기다렸다가 하면 너무 느린것같아..
      // 담기만 성공하면 일단 성공 애니메이션 노출한다
      UXEHelper.onRecommendedItemAddedCart()

      if (result.cartUnitResponses) {
        const newCartUnitIds = result.cartUnitResponses.flatMap((x) =>
          x.cartUnitId ? [x.cartUnitId] : [],
        )

        await ActionHelper.updateCartUnitsComplex(
          dispatch,
          getState,
          newCartUnitIds,
          true, // 요 아래서 배송비조회 다시할거라서..!
          true,
        )
        await ActionHelper.selectOrDeselectCartUnitsComplex(
          dispatch,
          getState,
          newCartUnitIds,
          true,
        )
      }
    },
  )

/**
 * 장바구니 교체(담기 및 삭제) 상태 업데이트
 *  - 사용자 경험 요구사항으로 장바구니 추가 후, 삭제하기로 함
 *  = 장바구니에 1개만 담겨 있는 상황에 장바구니 선 삭제시, '장바구니가 비어있습니다.' 문구가 노출될 수 있음
 * @param addedCartUnitIds 추가된 장바구니 cartUnitId
 * @param removedCartUnitIds 삭제된 장바구니 cartUnitId
 */
const addAndRemoveCartUnits = (
  addedCartUnitIds: number[],
  removedCartUnitIds: number[],
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      await ActionHelper.updateCartUnitsComplex(
        dispatch,
        getState,
        addedCartUnitIds, // 추가된 장바구니만 업데이트
        true, // 배송비 조회는 아래에서
        true,
        true, // 스프 지점 업데이트 하지 않음
      )

      if (!isEmpty(removedCartUnitIds)) {
        ActionHelper.removeCartUnits(dispatch, getState, removedCartUnitIds)
      }

      const state = getState()
      const cartUnitList = getCartUnitListByIdList(state.cart, addedCartUnitIds)
      const sameItemGroupCartUnitIds = uniq(
        addedCartUnitIds.flatMap((cartUnitId) =>
          getSameItemGroupCartUnitIds(state.cart, cartUnitId),
        ),
      )

      await Promise.all([
        dispatch(
          selectOrDeselectCartUnit({
            cartUnitIdList: sameItemGroupCartUnitIds,
            isSelect: true,
          }),
        ),
        ActionHelper.updateDiscountsComplex(
          dispatch,
          getState,
          sameItemGroupCartUnitIds,
        ),
        ActionHelper.updateShippingFee(dispatch, getState, cartUnitList),
        ActionHelper.updateOverseaShippingCost(dispatch, getState),
      ])
    },
  )

export const addCartUnits = (
  addedCartUnitIds: number[],
): AsyncComplexAction<RootState> => {
  if (isEmpty(addedCartUnitIds)) {
    throw new Error(`parameters of addCartUnits action are empty.`)
  }
  return addAndRemoveCartUnits(addedCartUnitIds, [])
}

export const replaceCartUnits = (
  addedCartUnitIds: number[],
  removedCartUnitIds: number[],
): AsyncComplexAction<RootState> => {
  if (isEmpty(addedCartUnitIds) || isEmpty(removedCartUnitIds)) {
    throw new Error(`parameters of replaceCartUnit action are empty.`)
  }
  return addAndRemoveCartUnits(addedCartUnitIds, removedCartUnitIds)
}

export const updateLatestAddress = (): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      await ActionHelper.updateLatestAddress(dispatch, getState)
      await ActionHelper.updateTransPolicyInfo(dispatch, getState)
    },
    ERROR_HANDLER_ALERT,
  )

export const toggleSmileFreshBranchServiceType = (): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch, getState: GetState) => {
    const state = getState()
    const currentServiceType = state.smileFresh.currentBranchServiceType

    const nextType: BranchServiceType =
      currentServiceType === 'Reserve' ? 'Dawn' : 'Reserve'

    if (
      state.smileFresh.availableBranches.some((x) =>
        x.branchServiceTypes.includes(nextType),
      )
    ) {
      dispatch(setBranchServiceType(nextType))
      const smileFreshCartUnitIds = state.cart.cartUnitList
        .filter(
          (x) =>
            x.cartUnitType === 'SmileFresh' &&
            state.cart.selectedCartUnitIdList.includes(x.cartUnitId),
        )
        .map((x) => x.cartUnitId)
      ActionHelper.selectOrDeselectCartUnitsComplex(
        dispatch,
        getState,
        smileFreshCartUnitIds,
        true,
        false,
        true,
      )
    }
  })

export const callBackCartBtVimp = (
  viewableImpressionSecureUrl: string,
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async () => {
    const url = new URL(viewableImpressionSecureUrl)
    await fetchCartBtVimp(url.search)
  })

export const callBackCartBtImp = (
  impressionSecureUrlList: string[],
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async () => {
    // queryParams들을 impressionSecureUrlList에서 AD에서 원하는 Spec대로 추출
    const queryParams = impressionSecureUrlList.reduce(
      (accumulator: { [key: string]: string }[], currentUrl: string) => {
        const url = new URL(currentUrl)
        const queryParams = new URLSearchParams(url.search)
        const paramObject: { [key: string]: string } = {}

        queryParams.forEach((value, key) => {
          paramObject[key] = value
        })

        paramObject['ref'] = encodeURIComponent(domains.CART)

        accumulator.push(paramObject)
        return accumulator
      },
      [],
    )
    await fetchCartImp(queryParams)
  })
