import { RootState } from '~/cart/modules/reducers'
import { getBrErrorMessage } from '~/data/br-error-messages'
import { AsyncComplexAction, ComplexAction } from '~/lib/action-wrapper'

export class CartException extends Error {
  action?: (
    triggerElement: HTMLElement,
  ) => AsyncComplexAction<RootState> | ComplexAction<RootState>

  brCode?: string
  focusElementId?: string
  focusElement?: HTMLElement

  public constructor(
    message?: string,
    focusId?: string | HTMLElement,
    data?: string | number,
  )
  public constructor(
    action: (
      triggerElement: HTMLElement,
    ) => AsyncComplexAction<RootState> | ComplexAction<RootState>,
  )
  public constructor(
    messageOrAction?:
      | string
      | ((
          triggerElement: HTMLElement,
        ) => AsyncComplexAction<RootState> | ComplexAction<RootState>),
    focusElementId?: string | HTMLElement,
    data?: string | number,
  ) {
    const message =
      typeof messageOrAction === 'function' ? undefined : messageOrAction

    if (message && /^CART-BR\d{1,3}-\d{1,3}$/.test(message)) {
      super(getBrErrorMessage(message, data))
      this.brCode = message
    } else {
      super(message)
    }

    if (typeof messageOrAction === 'function') {
      this.action = messageOrAction
    }

    if (focusElementId) {
      if (typeof focusElementId === 'string') {
        this.focusElementId = focusElementId
      } else {
        this.focusElement = focusElementId
      }
    }
  }
}
