import { compact, intersection, isEqual, map, pickBy } from 'lodash'
import { createReducer } from 'typesafe-actions'
import { NotDownloadedCouponIssueNo } from '~/data/consts'
import {
  APPLY_GROUP_COUPONS,
  CLEAR_ALL_GROUP_COUPONS,
  CLEAR_RECOMMENDED_GROUP_COUPONS,
  SET_DOWNLOADED_GROUP_COUPON_ISSUE_NO,
  SET_DOWNLOADED_RECOMMENDED_GROUP_COUPON,
  SET_IS_AUTO_DOWNLOADED_GROUP_COUPON,
  SET_TOTAL_GROUP_COUPON_COUNT,
  UPDATE_AVAILABLE_GROUP_COUPON,
  UPDATE_AVAILABLE_GROUP_COUPON_MULTI,
  UPDATE_RECOMMENDED_GROUP_COUPONS,
} from './actions'
import {
  GroupCoupon,
  GroupCouponAction,
  GroupCouponApplicationMap,
  GroupCouponState,
  RecommendedGroupCouponNo,
} from './types'

// 초기 상태 선언
const initialState: GroupCouponState = {
  groupCouponApplications: {},
  availableGroupCoupons: [],
  totalCouponCount: 0,
  recommendedCoupons: {},
}

export const getAvailableGroupCouponList = (
  state: GroupCouponState,
  cartUnitIdList: number[],
): GroupCoupon[] => {
  const coupons = state.availableGroupCoupons.find((x) =>
    isEqual(x.cartUnitIdList, cartUnitIdList),
  )
  if (coupons && coupons.groupCouponList) {
    return coupons.groupCouponList
  } else {
    return []
  }
}

export const getAllGroupCouponApplicationList = (
  state: GroupCouponState,
  predicate?: (cartUnitIds: number[], couponIssueNo: string) => boolean,
): GroupCouponApplicationMap => {
  return pickBy(
    state.groupCouponApplications,
    (cartUnitIds = [], couponIssueNo) =>
      cartUnitIds.length > 0 &&
      (predicate ? predicate(cartUnitIds, couponIssueNo) : true),
  )
}

export const getAppliedGroupCouponList = (
  state: GroupCouponState,
  cartUnitIdList: number[],
): (GroupCoupon & { cartUnitIdList: number[] })[] => {
  return map(
    getAllGroupCouponApplicationList(
      state,
      (idList) => intersection(idList, cartUnitIdList).length > 0,
    ),
    (idList, couponIssueNoString) => ({
      cartUnitIdList: idList || [],
      couponIssueNo: parseInt(couponIssueNoString),
    }),
  ).flatMap((x) => {
    const groupCoupon = getAvailableGroupCouponList(
      state,
      x.cartUnitIdList,
    ).find((coupon) => coupon.couponIssueNo === x.couponIssueNo)
    if (groupCoupon) {
      return [{ ...groupCoupon, cartUnitIdList: x.cartUnitIdList }]
    } else {
      return []
    }
  })
}

export const getGroupCouponAppliedCartUnitIdList = (
  state: GroupCouponState,
  couponIssueNo: number,
): number[] | undefined => {
  return state.groupCouponApplications[couponIssueNo]
}

export const getAllAppliedGroupCouponNoList = (
  state: GroupCouponState,
  predicate?: (cartUnitIds: number[], couponIssueNo: string) => boolean,
): number[] => {
  return compact(
    map(
      getAllGroupCouponApplicationList(state, predicate),
      (_, couponIssueNoString) => parseInt(couponIssueNoString),
    ),
  )
}

export const getGroupCoupon = (
  state: GroupCouponState,
  cartUnitIdList: number[],
  couponIssueNo: number,
): GroupCoupon | undefined => {
  const available = state.availableGroupCoupons.find((x) =>
    isEqual(x.cartUnitIdList, cartUnitIdList),
  )
  if (available) {
    return available.groupCouponList.find(
      (x) => x.couponIssueNo === couponIssueNo,
    )
  }
}

export const updateAvailableGroupCouponList = (
  state: GroupCouponState,
  cartUnitIdList: number[],
  couponIssueNo: number,
  couponPolicyNo: number,
): GroupCoupon[] => {
  const groupCoupons = getAvailableGroupCouponList(state, cartUnitIdList)

  if (groupCoupons) {
    return groupCoupons.map((groupCoupon) => {
      if (
        groupCoupon.couponPolicyNo === couponPolicyNo &&
        groupCoupon.couponIssueNo === NotDownloadedCouponIssueNo
      ) {
        return { ...groupCoupon, couponIssueNo: couponIssueNo }
      } else {
        return groupCoupon
      }
    })
  } else {
    return []
  }
}

export const updateGroupCouponDownloadState = (
  state: GroupCouponState,
  cartUnitIdList: number[],
  couponIssueNo: number,
  isAutoDownloadedCoupon: boolean,
): GroupCoupon[] => {
  const groupCoupons = getAvailableGroupCouponList(state, cartUnitIdList)

  if (groupCoupons) {
    return groupCoupons.map((groupCoupon) => {
      if (
        groupCoupon.couponIssueNo === couponIssueNo &&
        groupCoupon.couponIssueNo !== NotDownloadedCouponIssueNo
      ) {
        return {
          ...groupCoupon,
          isAutoDownloadedCoupon: isAutoDownloadedCoupon,
        }
      } else {
        return groupCoupon
      }
    })
  } else {
    return []
  }
}

export const getRecommendedGroupCouponList = (
  state: GroupCouponState,
  key: string,
): RecommendedGroupCouponNo[] => {
  return state.recommendedCoupons[key] || []
}

// 리듀서 작성
const groupCoupon = createReducer<GroupCouponState, GroupCouponAction>(
  initialState,
  {
    [UPDATE_AVAILABLE_GROUP_COUPON]: (state, { payload }) => ({
      ...state,
      availableGroupCoupons: [
        ...state.availableGroupCoupons.filter(
          (x) => !isEqual(x.cartUnitIdList, payload.cartUnitIdList),
        ),
        {
          cartUnitIdList: payload.cartUnitIdList,
          groupCouponList: payload.groupCouponList,
        },
      ],
    }),
    [UPDATE_AVAILABLE_GROUP_COUPON_MULTI]: (state, { payload }) => ({
      ...state,
      availableGroupCoupons: [
        ...state.availableGroupCoupons.filter((x) =>
          payload.some((y) => !isEqual(x.cartUnitIdList, y.cartUnitIdList)),
        ),
        ...payload,
      ],
    }),
    [APPLY_GROUP_COUPONS]: (state, { payload }) => {
      const newApplications = { ...state.groupCouponApplications }
      payload.forEach(({ couponIssueNo, cartUnitIdList }) => {
        newApplications[couponIssueNo] = cartUnitIdList
      })
      return {
        ...state,
        groupCouponApplications: newApplications,
      }
    },
    [CLEAR_ALL_GROUP_COUPONS]: (state, { payload: { cartUnitIdList } }) => {
      const newApplications = { ...state.groupCouponApplications }
      const appliedGroupCouponIssueNoList = getAppliedGroupCouponList(
        state,
        cartUnitIdList,
      ).map((x) => x.couponIssueNo)
      appliedGroupCouponIssueNoList.forEach((issueNo) => {
        delete newApplications[issueNo]
      })
      return {
        ...state,
        groupCouponApplications: newApplications,
      }
    },
    [SET_TOTAL_GROUP_COUPON_COUNT]: (state, { payload: totalCouponCount }) => ({
      ...state,
      totalCouponCount,
    }),

    [SET_DOWNLOADED_GROUP_COUPON_ISSUE_NO]: (state, { payload }) => {
      return {
        ...state,
        availableGroupCoupons: [
          ...state.availableGroupCoupons.filter(
            (x) => !isEqual(x.cartUnitIdList, payload.cartUnitIdList),
          ),
          {
            cartUnitIdList: payload.cartUnitIdList,
            groupCouponList: updateAvailableGroupCouponList(
              state,
              payload.cartUnitIdList,
              payload.couponIssueNo,
              payload.couponPolicyNo,
            ),
          },
        ],
      }
    },

    [UPDATE_RECOMMENDED_GROUP_COUPONS]: (state, { payload }) => ({
      ...state,
      recommendedCoupons: payload,
    }),

    [CLEAR_RECOMMENDED_GROUP_COUPONS]: (state) => ({
      ...state,
      recommendedCoupons: {},
    }),

    [SET_DOWNLOADED_RECOMMENDED_GROUP_COUPON]: (state, { payload }) => {
      const { key, policyNo, issueNo } = payload
      const coupons = state.recommendedCoupons[key] || []
      state.recommendedCoupons[key] = coupons.map((coupon) => {
        if (coupon.couponPolicyNo === policyNo) {
          return {
            ...coupon,
            couponIssueNo: issueNo,
          }
        }
        return coupon
      })

      return {
        ...state,
        recommendedCoupons: state.recommendedCoupons,
      }
    },

    [SET_IS_AUTO_DOWNLOADED_GROUP_COUPON]: (state, { payload }) => {
      return {
        ...state,
        availableGroupCoupons: [
          ...state.availableGroupCoupons.filter(
            (x) => !isEqual(x.cartUnitIdList, payload.cartUnitIdList),
          ),
          {
            cartUnitIdList: payload.cartUnitIdList,
            groupCouponList: updateGroupCouponDownloadState(
              state,
              payload.cartUnitIdList,
              payload.couponIssueNo,
              payload.isAutoDownloadedCoupon,
            ),
          },
        ],
      }
    },
  },
)

export default groupCoupon
