import {
  CartUnit,
  CartUnitPriceType,
  SmileFreshItemBranch,
} from '~/cart/modules/cart/types'
import { OverseaShippingCompany } from '~/cart/modules/shipping/types'
import { SmileFreshBranch } from '~/cart/modules/smile-fresh/types'

export default class TypeMapper {
  static asCartUnit = (
    source: cartAPI.CartResponse & { signal?: buyingAPI.ItemsResponse },
  ): CartUnit => {
    return {
      cartUnitId: source.cartUnitId || 0,
      cartUnitType: source.cartUnitType || 'Normal',
      quantity: source.quantity || 0,
      partnershipCode: source.partnershipCode,
      unableOrderReasonCode: source.unableOrderReasonCode,
      unableOrderData:
        typeof source.unableOrderData === 'string' ||
        typeof source.unableOrderData === 'number'
          ? source.unableOrderData
          : '',
      unableOrderType: source.unableOrderType,
      unusableCouponType: source.unusableCouponType,
      isItemDiscountAgreement: source.isItemDiscountAgreement || false,
      shippingPolicyId: source.shippingPolicyId || 0,
      bundleKey: source.bundleKey,
      shippingPolicyKey: source.shippingPolicyKey || '',
      expressShopBranch:
        source.cartUnitType === 'ExpressShop' &&
        source.itemBranches &&
        source.itemBranches.length > 0 &&
        source.itemBranches[0].branchCode &&
        source.itemBranches[0].expressShopType !== 'Unknown'
          ? {
              expressShopType:
                source.itemBranches[0].expressShopType || 'Unknown',
              branchCode: source.itemBranches[0].branchCode,
              branchName: source.itemBranches[0].branchName || '',
              branchAdditionalPrice:
                source.itemBranches[0].branchAdditionalPrice || 0,
            }
          : undefined,
      smileFreshBranches:
        (source.cartUnitType === 'SmileFresh' &&
          source.itemBranches?.flatMap<SmileFreshItemBranch>((branch) =>
            branch.branchCode
              ? [
                  {
                    branchCode: branch.branchCode,
                    branchName: branch.branchName || '',
                    branchAdditionalPrice: branch.branchAdditionalPrice || 0,
                    branchServiceTypes: branch.branchServiceTypes || [],
                  },
                ]
              : [],
          )) ||
        [],
      item:
        source.item && source.item.itemNo
          ? {
              ...source.item,
              itemNo: source.item.itemNo,
              itemName: source.item.itemName || '',
              options: source.item.options
                ? source.item.options.map((option) => ({
                    ...option,
                    optionNo: option.optionNo || 0,
                    optionType: option.optionType || 'Unknown',
                    optionPrice: option.optionPrice || 0,
                    optionEntries: option.optionEntries
                      ? option.optionEntries.map((entry) => ({
                          name: entry.name || '',
                          value: entry.value || '',
                        }))
                      : [],
                  }))
                : [],
              additions: source.item.additions
                ? source.item.additions.map((addition) => ({
                    ...addition,
                    additionNo: addition.additionNo || 0,
                    quantity: addition.quantity || 0,
                    optionType: addition.optionType || 'Unknown',
                    price: addition.price || 0,
                  }))
                : [],
              bsd:
                source.item.bsd &&
                typeof source.item.bsd.bigSmile === 'boolean' &&
                source.item.bsd.startDate &&
                source.item.bsd.endDate
                  ? {
                      ...source.item.bsd,
                      startDate: source.item.bsd.startDate,
                      endDate: source.item.bsd.endDate,
                      bigSmile: source.item.bsd.bigSmile,
                      bigSaleTitle: source.item.bsd.bigSaleTitle || '',
                    }
                  : undefined,
              largeCategoryCode: source.item.largeCategoryCode || '',
              mediumCategoryCode: source.item.mediumCategoryCode || '',
              smallCategoryCode: source.item.smallCategoryCode || '',
              detailCategoryCode: source.item.detailCategoryCode || '',
              epin: source.item.epinCode,
              itemSellPrice: source.item.itemSellPrice || 0,
              itemPurchaseBenefitList: source.item.itemPurchaseBenefitList
                ? source.item.itemPurchaseBenefitList.map((benefit) => ({
                    ...benefit,
                    benefitType: benefit.benefitType || 'Unknown',
                  }))
                : [],
              isECoupon: source.item.isECoupon || false,
              isUsedItem: source.item.isUsedItem || false,
              isIncomeDuty: source.item.isIncomeDuty || false,
              isPaymentMoneyCategory:
                source.item.isPaymentMoneyCategory || false,
              isSmileClubBizDeal: source.item.isSmileClubBizDeal || false,
              isAdult: source.item.isAdult || false,
              isInstall: source.item.isInstall || false,
              isC2C: source.item.isC2C || false,
              brandId: source.item.brandId,
              brandName: source.item.brandName,
              isZeroPrice: source.item.isZeroPrice || false,
              isMoneyCategory: source.item.isMoneyCategory || false,
              isSmileDelivery: source.item.isSmileDelivery || false,
            }
          : {
              itemNo: '', // 이리로 오는 케이스는 없어야함
              itemName: '',
              options: [],
              additions: [],
              largeCategoryCode: '',
              mediumCategoryCode: '',
              smallCategoryCode: '',
              detailCategoryCode: '',
              itemSellPrice: 0,
              itemPurchaseBenefitList: [],
              isECoupon: false,
              isUsedItem: false,
              isIncomeDuty: false,
              isPaymentMoneyCategory: false,
              isSmileClubBizDeal: false,
              isAdult: false,
              isInstall: false,
              isC2C: false,
              brandId: 0,
              brandName: '',
              isZeroPrice: false,
              isMoneyCategory: false,
              isSmileDelivery: false,
            },
      seller: source.seller
        ? {
            ...source.seller,
            sellerKey: source.seller.sellerKey || '',
            sellerName: source.seller.sellerName || '',
            miniShopName: source.seller.miniShopName || '',
            shopLink: source.seller.shopLink || '',
          }
        : {
            sellerKey: '',
            sellerName: '',
            miniShopName: '',
            shopLink: '',
          },
      discounts: source.discounts
        ? source.discounts.map((discount) => ({
            discountType: discount.discountType || 'Unknown',
            discountPrice: discount.discountPrice || 0,
            appliedQuantity: discount.appliedQuantity || 0,
            bundleDiscountPrice: discount.bundleDiscountPrice || 0,
            discountPolicy: {
              basisKind: discount.discountPolicy?.basisKind || '',
              calcType: discount.discountPolicy?.calcType || '',
              calcTypeSub: discount.discountPolicy?.calcTypeSub || '',
              discountPrice: discount.discountPolicy?.discountPrice || 0,
              discountRate: discount.discountPolicy?.discountRate || 0,
              minApplyCondition:
                discount.discountPolicy?.minApplyCondition || 0,
            },
            totalBundleDiscountPrice: discount.totalBundleDiscountPrice || 0,
          }))
        : [],
      appliedCouponIssueNoes: source.appliedCouponIssueNoes || [],
      insertDateISO: source.insertDateISO || new Date().toISOString(),
      duplicateCartUnitIds: source.duplicateCartUnitIds || [],
      exhibitExtraDiscount: source.exhibitExtraDiscount || null,
      priceChangedSignalAvailable: source.priceChangedSignalAvailable || false,
      signal: source.signal,
      isBuyAvailable: source.isBuyAvailable || false,
      isCouponUsable: source.isCouponUsable || false,
      isNeedAuthentication: source.isNeedAuthentication || false,
      hasDuplicates: source.hasDuplicates || false,
    }
  }

  static asCashbackCartRequest = (
    cartUnit: CartUnit,
    priceInfo: CartUnitPriceType,
  ): cartAPI.CashbackCartRequest => ({
    cartUnitId: cartUnit.cartUnitId,
    largeCategoryCode: cartUnit.item.largeCategoryCode,
    mediumCategoryCode: cartUnit.item.mediumCategoryCode,
    smallCategoryCode: cartUnit.item.smallCategoryCode,
    sellerKey: cartUnit.seller.sellerKey,
    itemNo: cartUnit.item.itemNo,
    quantity: cartUnit.quantity,
    itemPrice: cartUnit.item.itemSellPrice,
    branchAdditionalPrice: priceInfo.cartUnitBranchAdditionalPrice,
    optionsAdditionalPrice: priceInfo.cartUnitOptionsAdditionalPrice,
    additionsTotalAmount: priceInfo.cartUnitAdditionsTotalPrice,
    cartUnitTotalAmount: priceInfo.cartUnitItemPrice,
    ebayDiscountPrice: priceInfo.cartUnitEbayDiscountPrice,
    sellerDiscountPrice: priceInfo.cartUnitSellerDiscountPrice,
    bundleDiscountPrice: priceInfo.cartUnitBundleDiscountPrice,
    partnershipDiscountPrice: priceInfo.cartUnitPartnershipDiscountPrice,
    extraDiscountPrice: priceInfo.cartUnitFundingDiscountPrice,
    buyMileageRate: cartUnit.item.buyMileageRate,
    isSmileDelivery: cartUnit.cartUnitType === 'SmileDelivery',
    isSmileFresh: cartUnit.cartUnitType === 'SmileFresh',
    isMoneyCategory: cartUnit.item.isPaymentMoneyCategory,
  })

  static asOverseaShippingCompany = (
    source: cartAPI.OverseaShippingCompanyResponse,
  ): OverseaShippingCompany => {
    return {
      shippingCompanyType: source.shippingCompany,
      shippingCompanyName: source.shippingCompanyName,
    }
  }

  static asSmileFreshBranch = (
    source: cartAPI.BranchResponse,
  ): SmileFreshBranch => {
    return {
      branchServiceTypes: source.branchServiceTypes || [],
      sellerKey: source.sellerKey || '',
      longDistance: source.longDistance,
      branchCode: source.branchCode || '',
      branchName: source.branchName,
      contract: source.contract,
      branchFastShippingSlot: source.branchFastShippingSlot,
    }
  }
}
