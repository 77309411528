import { Domain, DomainByEnv, DomainCommon } from '~/data/domains'
import siteEnv from '~/lib/site-env'
import dataPicker from '../../data-picker'

/**
 * 중국어/일본어는 영어와 다른경우만 여기에 정의
 */
const domains: SiteEnvDataType<Partial<DomainByEnv>>[] = [
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'English',
    },
    data: {
      SCRIPT: '//script-dev.gmarket.co.kr',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'English',
    },
    data: {
      SCRIPT: '//script-dev.gmarket.co.kr',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Chinese',
    },
    data: {
      SCRIPT: '//script-dev.gmarket.co.kr',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Chinese',
    },
    data: {
      SCRIPT: '//script-dev.gmarket.co.kr',
    },
  },
]

const common: Partial<DomainCommon> = {}

const domainsByEnv =
  dataPicker<Partial<DomainByEnv>>(domains, siteEnv, true) || {}

const result: Partial<Domain> = {
  ...domainsByEnv,
  ...common,
}

export default result
