import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import { IncomeDutyItemOrderType } from '~/types/enums'

type LayerConfirmIncomeDutyItemOrderTypeProp = LayerProps & {
  onCloseLayerAsync: (closeImmediately?: boolean) => Promise<void>
  detailData?: {
    onConfirm: (orderType?: IncomeDutyItemOrderType) => void
  }
}

const LayerConfirmIncomeDutyItemOrderType = ({
  layerId,
  layerKey,
  onCloseLayerAsync,
  detailData,
}: LayerConfirmIncomeDutyItemOrderTypeProp): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const onClickConfirmButton = useCallback(
    (orderType?: IncomeDutyItemOrderType) => async (): Promise<void> => {
      await onCloseLayerAsync(true)
      if (detailData) {
        detailData.onConfirm(orderType)
      }
    },
    [detailData, onCloseLayerAsync],
  )

  if (!detailData) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div
      className="nv-layer"
      id={layerId}
      // id="layer_culture-tax"
      style={{ display: 'block' }}
    >
      <div className="nv-layer-bg" onClick={onClickConfirmButton()} />
      <div className="nv-layer-cont">
        <div className="box__layer-culture-tax">
          <p className="text__info">
            도서상품과 일반상품을 동시에 주문하시면
            <br />
            <span className="text--primary">문화비 소득공제 대상에서 제외</span>
            됩니다.
            <br />
            그래도 함께 구매하시겠습니까?
          </p>
          <div className="box__button">
            <button
              type="button"
              className="button button__cancel"
              onClick={onClickConfirmButton('OnlyIncomeDutyItem')}
              data-montelena-acode={areaCodes.ONLY_INCOME_DUTY_ITEM_ORDER}
            >
              도서상품만 구매할게요
            </button>
            <button
              type="button"
              className="button button__confirm"
              onClick={onClickConfirmButton('OrderTogether')}
              data-montelena-acode={areaCodes.INCOME_DUTY_ITEM_ORDER_TOGETHER}
            >
              함께 구매할게요
            </button>
          </div>
          <button
            className="button__close sprite__cart--after"
            onClick={onClickConfirmButton()}
          >
            <span className="for-a11y">팝업 닫기</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default LayerConfirmIncomeDutyItemOrderType
