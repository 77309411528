import _, { uniqueId } from 'lodash'
import React from 'react'

export const EOLRegex = /\r\n|\r|\n/

export const currencyAmount = (amount: number, showSign?: boolean): string => {
  const viewAmount = amount > 0 ? amount : -1 * amount

  const numberString =
    viewAmount.toString().indexOf('.') < 0
      ? viewAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : viewAmount
          .toString()
          .replace(/\B(?=(\d{3})+\.)/g, ',')
          .replace(/(\.\d{2})\d*/, '$1')

  if ((showSign || amount < 0) && amount !== 0) {
    const sign = amount > 0 ? '+' : '-'
    return sign + numberString
  } else {
    return numberString
  }
}

function numberTokenizer(
  amount: number,
  base: number,
  tokens: number[] = [],
): number[] {
  const head = Math.floor(amount / base)
  const rest = amount - head * base
  return head < base
    ? [head, rest, ...tokens]
    : numberTokenizer(head, base, [rest, ...tokens])
}

export const koreanNumeralNotation = (
  amount: number,
  delimiter = ' ',
): string => {
  const tokens = numberTokenizer(amount, 10000)

  // MAX_INTEGER 가 '경' 보다 작음
  return _.zip(_.reverse(tokens), ['', '만', '억', '조', '경'])
    .flatMap(([num, unit]) => (num ? [`${currencyAmount(num)}${unit}`] : []))
    .reverse()
    .join(delimiter)
}

export const numberMixKoreanCurrencyAmount = (amount: number): string => {
  if (amount >= 10000) {
    const first = amount.toString().slice(0, -4)
    const second = currencyAmount(Number(amount.toString().slice(-4)))
    return second === '0' ? first + '만' : first + '만 ' + second
  } else {
    return currencyAmount(amount)
  }
}

export const koreanCurrencyAmount = (amount: number): string => {
  const unitsByOrder = [
    {
      unit: '만',
      base: 10000,
    },
    {
      unit: '천',
      base: 1000,
    },
    {
      unit: '백',
      base: 100,
    },
  ]
  for (const unit of unitsByOrder) {
    if (amount >= unit.base) {
      if (amount % unit.base === 0) {
        return `${amount / unit.base}${unit.unit}`
      } else {
        return currencyAmount(amount)
      }
    }
  }

  return currencyAmount(amount)
}

export const toNumber = (currencyAmount: string): number => {
  return parseInt(currencyAmount.replace(/[^\d]/g, '')) || 0
}

export const toNumericValue = (input: string): string => {
  return input.replace(/[^\d]/g, '')
}

export const toNDigitNumberString = (input: number, length: number): string => {
  const numberString = input.toString()
  if (numberString.length < length) {
    return (
      Array(length - numberString.length)
        .fill('0')
        .join() + input
    )
  }
  return numberString
}

export const phoneNumber = (input: string): string => {
  const numericValue = toNumericValue(input)
  const middle3regex = /^(\d{1,3})?-?(\d{1,3})?-?(\d+)?/
  const middle4regex = /^(\d{3})-?(\d{4})-?(\d{4})/

  const regex = numericValue.length >= 11 ? middle4regex : middle3regex

  return numericValue.replace(regex, (match, n1, n2, n3) => {
    if (n3) {
      return `${n1}-${n2}-${n3}`
    } else if (n2) {
      return `${n1}-${n2}`
    } else if (n1) {
      return `${n1}`
    } else {
      return ''
    }
  })
}

export const toCardNumber = (input: string, isCardAmex: boolean): string => {
  const numericValue = toNumericValue(input)
  const normalRegex = /^(\d{1,4})?-?(\d{1,4})?-?(\d{1,4})?-?(\d+)?/
  const amexRegex = /^(\d{1,4})?-?(\d{1,6})?-?(\d+)?/

  const regex = isCardAmex ? amexRegex : normalRegex

  return numericValue.replace(regex, (match, n1, n2, n3, n4) => {
    if (n4) {
      return `${n1}-${n2}-${n3}-${n4}`
    } else if (n3) {
      return `${n1}-${n2}-${n3}`
    } else if (n2) {
      return `${n1}-${n2}`
    } else if (n1) {
      return `${n1}`
    } else {
      return ''
    }
  })
}

export const toCardMonthYear = (input: string): string => {
  const numericValue = toNumericValue(input)

  const regex = /^(\d{1,2})?\/?(\d+)?/

  return numericValue.replace(regex, (match, n1, n2) => {
    if (n2) {
      return `${n1}/${n2}`
    } else if (n1) {
      return `${n1}`
    } else {
      return ''
    }
  })
}

/**
 * format 에 맞게 string replace, string 리턴
 * ex1) formatString('{0} is {1}', string1, number1)
 * -> return 'string1 is number1'
 * ex2) formatString('{0} is {1}', [string1, number1])
 * -> return 'string1 is number1'
 * @param baseString
 * @param data
 */
export function formatString(
  baseString: string,
  ...data: (string | number)[]
): string
export function formatString(
  baseString: string,
  data: (string | number)[],
): string

export function formatString(
  baseString: string,
  ...args: (string | number | undefined)[] | [(string | number | undefined)[]]
): string {
  const params =
    args[0] && args[0] instanceof Array
      ? args[0]
      : (args as (string | number | undefined)[])

  return params.reduce<string>((result, param, idx) => {
    const regEx = new RegExp('\\{' + idx + '\\}', 'gm')
    if (typeof param === 'string' || typeof param === 'number') {
      return result.replace(regEx, param.toString())
    } else {
      return result
    }
  }, baseString)
}

/**
 * \n | \r | \r\n 와 같은 linebrake 사용시 <br/>로 split
 * @param message
 */
export const splitWithBr = (message?: string): (JSX.Element | string)[] => {
  try {
    return message
      ? message
          .split(EOLRegex)
          .flatMap((x, idx) => (idx > 0 ? [<br key={idx} />, x] : [x]))
      : []
  } catch (e) {
    // DO NOTHING
  }
  return [message || '']
}

/**
 * format 에 맞게 string replace, JSX or string array 리턴
 * \n | \r | \r\n 와 같은 linebrake 사용시 <br/>로 추가됨
 * ex1) formatAsDom('{0} is {1}, {2} and {3}', Object1, string1, Array1, string2)
 * -> return [Object1, ' is string1, ', Array1, ' and string2']
 * ex3) formatAsDom('{0} is {1}, {2} and {3}', [Object1, string1, Array1, string2])
 * -> return [Object1, ' is string1, ', Array1, ' and string2']
 * @param baseString
 * @param data
 */
export function formatAsDom(
  baseString: string | undefined,
  ...data: (string | number | JSX.Element)[]
): (string | JSX.Element)[]
export function formatAsDom(
  baseString: string | undefined,
  data: (string | number | JSX.Element)[],
): (string | JSX.Element)[]

export function formatAsDom(
  baseString: string | undefined,
  ...args:
    | (string | number | JSX.Element)[]
    | [(string | number | JSX.Element)[]]
): (string | JSX.Element)[] {
  const uniqId = uniqueId('formatted')

  const params =
    args[0] && args[0] instanceof Array
      ? args[0]
      : (args as (string | number | JSX.Element)[])

  return params.reduce<(string | JSX.Element)[]>(
    (result, param, idx) =>
      result
        .flatMap((res) => {
          const regEx = new RegExp('\\{' + idx + '\\}', 'gm')
          if (typeof res === 'string') {
            if (typeof param === 'object') {
              const splitList = res.split(regEx)
              return splitList.flatMap((split, idx2) => [
                split,
                // 마지막은 추가하지 않음
                ...(idx2 < splitList.length - 1 ? [param] : []),
              ])
            } else {
              return [res.replace(regEx, param.toString())]
            }
          } else {
            return [res]
          }
        })
        .map((x, idx) => {
          if (typeof x === 'object') {
            return { ...x, key: `${uniqId}_${idx}` } // react 요소에 key 를 넣어줘야하기때문
          } else {
            return x
          }
        }),
    splitWithBr(baseString),
  )
}

export const getInstallmentMonthText = (
  month: number,
  freeInstallmentListOrIsFreeInstallment?: number[] | boolean,
): string => {
  const isFreeInstallment =
    typeof freeInstallmentListOrIsFreeInstallment === 'boolean'
      ? freeInstallmentListOrIsFreeInstallment
      : freeInstallmentListOrIsFreeInstallment &&
        freeInstallmentListOrIsFreeInstallment.includes(month)

  if (month > 0) {
    if (isFreeInstallment) {
      return `${month}개월 무이자 할부`
    } else {
      return `${month}개월`
    }
  } else {
    return '일시불'
  }
}

export const toMaskingNumber = (
  input: string | undefined,
  isBank: boolean,
  isPhone: boolean,
): string => {
  if (!input) {
    return ''
  }
  if (isBank) {
    return input
  } else if (isPhone) {
    const phoneRegex = /^(.{3})(.{3,4})(.{4})$/
    if (phoneRegex.test(input)) {
      return input.replace(phoneRegex, '$1 $2 $3')
    }
  } else {
    if (input.length === 16) {
      return input.replace(/^(.{4})(.{4})(.{4})(.{4})$/, '$1 $2 $3 $4')
    } else if (input.length === 15) {
      return input.replace(/^(.{4})(.{6})(.{5})$/, '$1 $2 $3')
    }
  }

  return input
}

/**
 * CHKP-8124 배송지 마스킹 처리
 * 이름
 * (국내) 첫 글자와 마지막 글자 제외한 가운데 마스킹 처리
 * (해외) 첫번째 자리를 제외한 이름의 4자리 이상 (ex- b**** Gates, S**** Jobs, W**** Buffett, H*** Gil Dong)
 * 예외) 두 글자인 경우 첫 글자 제외한 나머지 마스킹 처리
 * @param input
 */
export const toMaskingReceiverName = (input: string | undefined): string => {
  if (!input) {
    return ''
  }
  const middleMaskingRule = /(?!^.?).(?!.{0}$)/gi
  // (예외) 한글, 영문명 모두 두글자인 경우 (ex- 김은 -> 김*, Jk -> J*)
  const exceptionalRule = /^[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z]{2}$/
  // 첫글자 제외 전부 마스킹
  const restMaskingRule = /(?!^.?)./gi

  return exceptionalRule.test(input)
    ? input.replace(restMaskingRule, '*')
    : input.replace(middleMaskingRule, '*')
}

/**
 * CHKP-8124 배송지 마스킹 처리
 * @param input
 */
export const toMaskingDetailAddress = (input: string | undefined): string => {
  if (!input) {
    return ''
  }
  const addressMaskingRule = /[ㄱ-ㅎㅏ-ㅣ가-힣A-Za-z0-9_~@#&(),.\-]/gi
  return addressMaskingRule.test(input)
    ? input.replace(addressMaskingRule, '*')
    : input
}
