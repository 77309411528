import { createStandardAction } from 'typesafe-actions'
import { LayerData, ViewState } from './types'

export const INIT_VIEW = 'view/INIT_VIEW'
export const FIRST_LOADED = 'view/FIRST_LOADED'
export const SET_FIRST_LOAD_FAILED = 'view/SET_FIRST_LOAD_FAILED'
export const SET_TITLE = 'view/SET_TITLE'
export const SET_SHOWING_APP_HEADER = 'view/SET_SHOWING_APP_HEADER'
export const SET_HEADER_CLOSE_URL = 'view/SET_HEADER_CLOSE_URL'
export const OPEN_LAYER = 'view/OPEN_LAYER'
export const CLOSE_LAYER = 'view/CLOSE_LAYER'
export const SET_IS_PREPARE_ORDER_PROCESSING =
  'view/SET_IS_PREPARE_ORDER_PROCESSING'
export const SET_IS_SHIPPING_FEE_LOADED_AFTER_CHANGED =
  'view/SET_IS_SHIPPING_FEE_LOADED_AFTER_CHANGED'

export const initView = createStandardAction(INIT_VIEW)<Partial<ViewState>>()
export const firstLoaded = createStandardAction(FIRST_LOADED)()
export const setFirstLoadFailed = createStandardAction(
  SET_FIRST_LOAD_FAILED,
)<boolean>()
export const setTitle = createStandardAction(SET_TITLE)<string>()
export const setShowingAppHeader = createStandardAction(
  SET_SHOWING_APP_HEADER,
)<boolean>()
export const setHeaderCloseUrl = createStandardAction(SET_HEADER_CLOSE_URL)<
  string | undefined
>()

export const openLayer = createStandardAction(OPEN_LAYER)<LayerData>()

export const closeLayer = createStandardAction(CLOSE_LAYER)<string>()

export const setIsPrepareOrderProcessing = createStandardAction(
  SET_IS_PREPARE_ORDER_PROCESSING,
)<boolean>()
export const setIsShippingFeeLoadedAfterChanged = createStandardAction(
  SET_IS_SHIPPING_FEE_LOADED_AFTER_CHANGED,
)<boolean>()
