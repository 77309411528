import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CouponBoxBody from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxBody'
import CouponBoxSummary from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxSummary'
import CouponBoxTable from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxTable'
import CouponBoxToastProvider from '~/cart/gmarket/ko/common/components/CouponBox/CouponBoxToastProvider'
import CouponBoxExtraDiscountBoard from '~/cart/gmarket/ko/common/components/CouponBox/ExtraDiscount/CouponBoxExtraDiscountBoard'
import EmptyMobileUnitCouponItem from '~/cart/gmarket/ko/common/containers/CouponBox/EmptyMobileUnitCouponItem'
import UnitCouponBoxItemSummary from '~/cart/gmarket/ko/common/containers/CouponBox/UnitCouponBoxItemSummary'
import CouponDownloadingContainer from '~/cart/gmarket/ko/common/containers/CouponDownloadingContainer'
import useExtraDiscountCandidates from '~/cart/hooks/useExtraDiscountCandidates'
import useUnitCouponBox from '~/cart/hooks/useUnitCouponBox'
import { openOneClickJoin } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import {
  CouponBoxTableType,
  CouponBoxTableViewData,
} from '~/cart/modules/types'
import { setUnitCouponBoxOpened } from '~/cart/modules/unit-coupon/actions'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'
import Montelena from '~/lib/montelena'
import UXEHelper from '~/lib/uxe-helper'

type CouponBoxProps = {
  layerId: string
  cartUnitId: number
  onCloseLayer: () => void
}

const getCouponBoxTableViewDataByType = (
  couponBoxViewData: CouponBoxTableViewData[],
  couponBoxTableType: CouponBoxTableType,
): CouponBoxTableViewData | undefined => {
  return couponBoxViewData.find(
    (x) =>
      x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
      x.type === couponBoxTableType,
  )
}

const isSelectedRecommendedCoupon = (
  couponBoxViewData: CouponBoxTableViewData | undefined,
): boolean => {
  // 적용된 쿠폰이 없고, 추천 선택된 쿠폰이 있는 경우
  return couponBoxViewData
    ? !couponBoxViewData.coupons.some(({ isApplied }) => isApplied) &&
        couponBoxViewData.coupons.some(
          ({ selectedByRecommendation, issuable }) =>
            selectedByRecommendation && !issuable,
        )
    : false
}

const getSelectedRecommendedCouponPolicyNo = (
  couponBoxViewData: CouponBoxTableViewData | undefined,
): number | string => {
  if (couponBoxViewData && isSelectedRecommendedCoupon(couponBoxViewData)) {
    const selectedRecommendedCoupon = couponBoxViewData.coupons.find(
      ({ selectedByRecommendation, issuable }) =>
        selectedByRecommendation && !issuable,
    )

    return selectedRecommendedCoupon?.policyNo ?? ''
  }

  return ''
}

const UnitCouponBoxMobileContainer = ({
  layerId,
  cartUnitId,
  onCloseLayer,
}: CouponBoxProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const closeCouponBox = useCallback(() => {
    UXEHelper.closeSlideLayer()

    window.setTimeout(() => {
      dispatch(setUnitCouponBoxOpened(undefined))
      onCloseLayer()
    }, 300)
  }, [dispatch, onCloseLayer])

  const {
    cartUnit,
    summary,
    selectedCoupon,
    couponBoxViewData,
    onChangeCoupon,
    onCancelCoupon,
    onApplyCoupon,
  } = useUnitCouponBox(cartUnitId)

  const sellerCouponBoxViewData = getCouponBoxTableViewDataByType(
    couponBoxViewData,
    CouponBoxTableType.Seller,
  )

  const bundleCouponBoxViewData = getCouponBoxTableViewDataByType(
    couponBoxViewData,
    CouponBoxTableType.Bundle,
  )

  const superCouponBoxViewData = getCouponBoxTableViewDataByType(
    couponBoxViewData,
    CouponBoxTableType.Super,
  )

  const buyerCouponBoxViewData = getCouponBoxTableViewDataByType(
    couponBoxViewData,
    CouponBoxTableType.Buyer,
  )

  const onClickApply = useCallback(
    onApplyCoupon(
      closeCouponBox,
      () =>
        window.confirm(
          '다른 상품에 이미 적용되어있는 쿠폰입니다. 취소 후 이 상품에 적용하시겠습니까?',
        ),
      (paymentCouponName) =>
        window.alert(
          paymentCouponName +
            ' 결제수단만 사용가능한 쿠폰입니다. 주문결제시 결제제한이 적용됩니다.',
        ),
    ),
    [onApplyCoupon, closeCouponBox],
  )

  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    setShowTooltip(
      // 일반쿠폰 중복쿠폰 중 자동으로 선택되어진 추천쿠폰이 하나라도 있는 경우 tooltip 노출
      isSelectedRecommendedCoupon(buyerCouponBoxViewData) ||
        isSelectedRecommendedCoupon(superCouponBoxViewData),
    )
  }, [buyerCouponBoxViewData, superCouponBoxViewData])

  useEffect(() => {
    if (showTooltip) {
      Montelena.logImpression(
        'IMP_VI',
        '200010221',
        {
          ['buyer_coupon_master_no_1']: getSelectedRecommendedCouponPolicyNo(
            buyerCouponBoxViewData,
          ),
          ['super_coupon_master_no_2']: getSelectedRecommendedCouponPolicyNo(
            superCouponBoxViewData,
          ),
        },
        'UnitCouponBoxMobileContainer',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip])

  const { extraDiscountCandidates } = useExtraDiscountCandidates(cartUnitId)

  const onClickJoinSmileClub = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      dispatch(openOneClickJoin(e.currentTarget, 'coupon'))
    },
    [dispatch],
  )

  return (
    <div>
      <div className="section__layerframe-slideup" id={layerId} tabIndex={0}>
        <div className="section__layer-coupon-apply">
          <div className="box__coupon-header">
            <h2 className="text__title">쿠폰 적용</h2>
            <button
              type="button"
              className="button__closed sprite__coupon--before"
              onClick={closeCouponBox}
              data-montelena-acode="200010217"
            >
              <span className="for-a11y">팝업 닫기</span>
            </button>
          </div>
          <CouponBoxToastProvider>
            <CouponDownloadingContainer cartUnitId={cartUnitId}>
              <CouponBoxBody>
                <UnitCouponBoxItemSummary item={cartUnit?.item} />
                {sellerCouponBoxViewData && (
                  <CouponBoxTable
                    isMobile={isMobile}
                    viewData={sellerCouponBoxViewData}
                    selectedCouponIssueNo={selectedCoupon['Seller']}
                    onChangeCoupon={onChangeCoupon()}
                    onCancelCoupon={onCancelCoupon}
                    onClickJoinSmileClub={onClickJoinSmileClub}
                  />
                )}
                {bundleCouponBoxViewData && (
                  <CouponBoxTable
                    isMobile={isMobile}
                    viewData={bundleCouponBoxViewData}
                    selectedCouponIssueNo={selectedCoupon['Bundle']}
                    onChangeCoupon={onChangeCoupon()}
                    onCancelCoupon={onCancelCoupon}
                    onClickJoinSmileClub={onClickJoinSmileClub}
                  />
                )}
                {buyerCouponBoxViewData || superCouponBoxViewData ? (
                  <>
                    {buyerCouponBoxViewData && (
                      <CouponBoxTable
                        isMobile={isMobile}
                        viewData={buyerCouponBoxViewData}
                        selectedCouponIssueNo={selectedCoupon['Buyer']}
                        onChangeCoupon={onChangeCoupon()}
                        onCancelCoupon={onCancelCoupon}
                        onClickJoinSmileClub={onClickJoinSmileClub}
                      />
                    )}
                    {superCouponBoxViewData && (
                      <CouponBoxTable
                        isMobile={isMobile}
                        viewData={superCouponBoxViewData}
                        selectedCouponIssueNo={selectedCoupon['Super']}
                        onChangeCoupon={onChangeCoupon()}
                        onCancelCoupon={onCancelCoupon}
                        onClickJoinSmileClub={onClickJoinSmileClub}
                      />
                    )}
                  </>
                ) : (
                  <EmptyMobileUnitCouponItem />
                )}
                <CouponBoxExtraDiscountBoard
                  isMobile={true}
                  candidates={extraDiscountCandidates}
                />
              </CouponBoxBody>
            </CouponDownloadingContainer>
          </CouponBoxToastProvider>
          <div className="box__total-price">
            <CouponBoxSummary summary={summary} />
            <div className="box__button">
              {showTooltip && (
                <span className="box__tooltip sprite__coupon--before box__tooltip--motion">
                  <span className="text__emphasis">
                    쿠폰 적용하고 할인 받으세요!
                  </span>
                </span>
              )}
              <button
                type="button"
                className="button button__apply"
                onClick={onClickApply}
                data-montelena-acode="200010218"
              >
                쿠폰 적용하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitCouponBoxMobileContainer
