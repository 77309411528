import axiosClient from '~/lib/axios-client'

export const fetchGetCartBt = async (
  request: cartAPI.RecommendCartBtRequest,
): Promise<cartAPI.RecommendCartBtResponse[]> => {
  return axiosClient.post('/recommend/cartBt?version=v2', request)
}

export const fetchGetRecommendItems = async (
  request: cartAPI.RecommendCartBtRequest,
): Promise<cartAPI.RecommendItemResponse[]> => {
  return axiosClient.post('/recommend/items', request)
}

export const fetchCartBtVimp = async (request: string): Promise<void> => {
  return axiosClient.post('/recommend/vimp', { request })
}

export const fetchCartImp = async (
  request: { [p: string]: string }[],
): Promise<void> => {
  return axiosClient.post('/recommend/imp', request)
}

export const fetchGetBuyBox = async (
  request: cartFE.GetCartBuyBoxRequest,
): Promise<cartFE.GetCartBuyBoxResponse[]> => {
  return axiosClient.post('/recommend/getBuyBox', request)
}
