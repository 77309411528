type On<S, T> = (
  condition: T,
  value: S,
) => { on: On<S, T>; otherwise: (v: S) => S }

type Res<S, T> = {
  on: On<S, T>
  otherwise: (v: S) => S
}

const matched = <S, T>(x: S): Res<S, T> => ({
  on: (): Res<S, T> => matched(x),
  otherwise: (): S => x,
})

const simpleSwitch = <S, T>(source: T | undefined): Res<S, T> => ({
  on: (condition: T, value: S): Res<S, T> =>
    source === condition ? matched<S, T>(value) : simpleSwitch<S, T>(source),
  otherwise: (value: S): S => value,
})

export default simpleSwitch
