import axiosClient from '~/lib/axios-client'

export const fetchGetCouponBox = async (
  request: cartAPI.GetCouponBoxRequest,
): Promise<cartAPI.CouponBoxResponse> => {
  return axiosClient.post('/coupon/getCouponBox', request)
}

export const fetchGetAppliedCoupons = async (
  request: cartAPI.GetAppliedCouponsRequest,
): Promise<cartAPI.CouponResponse[]> => {
  return axiosClient.post('/coupon/getAppliedCoupons', request)
}

export const fetchGetSmileCardNudgeCoupon = async (): Promise<
  cartAPI.SmileCardNudgeCouponResponse[]
> => {
  return axiosClient.post('/coupon/getSmileCardNudgeCoupon')
}

export const fetchGetUnusedCoupon = async (
  usedCouponIssueNos: number[],
  couponPolicyNo: number,
): Promise<cartFE.GetUnusedCouponResponse> => {
  return axiosClient.post('/coupon/getUnusedCoupon', {
    usedCouponIssueNos,
    couponPolicyNo,
  })
}

export const fetchIssueCoupon = async (
  eventNo: number,
  encStr: string,
): Promise<cartFE.IssueCouponResponse> => {
  return axiosClient.post('/coupon/issueCoupon', { eventNo, encStr })
}

export const fetchGetRecommendedCoupons = async (
  request: cartFE.GetRecommendedCouponsRequest,
): Promise<cartFE.GetRecommendedCouponsResponse[]> => {
  return axiosClient.post('/coupon/getRecommendedCoupons', request)
}
