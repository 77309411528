import axiosClient from '~/lib/axios-client'

export const fetchGetBsdEvents = async (
  request: cartAPI.ItemBsdEventsRequest,
): Promise<cartAPI.ItemBsdResponse[]> => {
  return axiosClient.post('/item/getBsdEvents', request)
}

export const fetchGetPurchaseBenefits = async (
  request: cartAPI.ItemPurchaseBenefitRequest,
): Promise<cartAPI.ItemPurchaseBenefitResponse[]> => {
  return axiosClient.post('/item/getPurchaseBenefits', request)
}
