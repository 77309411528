import { Domain, DomainByEnv, DomainCommon } from '~/data/domains'
import siteEnv from '~/lib/site-env'
import dataPicker from '../../data-picker'

const domains: SiteEnvDataType<Partial<DomainByEnv>>[] = []

const common: Partial<DomainCommon> = {}

const domainsByEnv =
  dataPicker<Partial<DomainByEnv>>(domains, siteEnv, true) || {}

const result: Partial<Domain> = {
  ...domainsByEnv,
  ...common,
}

export default result
