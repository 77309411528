/* eslint-disable */
import siteEnv from '~/lib/site-env'

const isProduction = siteEnv.env === 'production' || siteEnv.env === 'av'
const scriptHost = isProduction
  ? '//script.auction.co.kr'
  : '//script-dev.auction.co.kr'

export default {
  loadScriptByOrder: true,
  headerScriptSources: [
    scriptHost + '/common/header_common.js',
    scriptHost + '/common/jquery.js',
    scriptHost + '/pc/style/js/common.js',
    scriptHost + '/pc/style/js/ui.header.js',
  ],
  footerScriptSources: [],
  renderHeader: () => {
    // DO NOTHING
  },

  renderFooter: () => {
    // DO NOTHING
  },

  releaseHeader: () => {
    // DO NOTHING
  },

  releaseFooter: () => {
    // DO NOTHING
  },

  setHeaderTitle: (title, isCoreApp) => {
    // DO NOTHING
  },

  setCloseUrl: (url) => {
    // DO NOTHING
  },
  hideAppHeader: (title) => {
    // DO NOTHING
  },
  showAppHeader: (title) => {
    // DO NOTHING
  },
}
