import { createReducer } from 'typesafe-actions'
import {
  INIT_SMILE_FRESH,
  SET_BRANCH_SERVICE_TYPE,
} from '~/cart/modules/smile-fresh/actions'
import { SmileFreshAction, SmileFreshState } from './types'

const initialState: SmileFreshState = {
  currentBranchServiceType: 'Unknown',
  availableBranches: [],
}

export const getIsSeperatedBranches = (state: SmileFreshState): boolean => {
  return (
    state.availableBranches.length === 2 &&
    state.availableBranches.every((x) => x.branchServiceTypes.length === 1) &&
    state.availableBranches.some((x) =>
      x.branchServiceTypes.includes('Dawn'),
    ) &&
    state.availableBranches.some((x) =>
      x.branchServiceTypes.includes('Reserve'),
    )
  )
}

export const getCurrentSmileFreshArrivalEstimate = (
  state: SmileFreshState,
): string | undefined => {
  const branch = state.availableBranches.find((x) =>
    x.branchServiceTypes.includes(state.currentBranchServiceType),
  )
  if (branch && branch.branchFastShippingSlot) {
    return branch.branchFastShippingSlot.displayInfo
  }
}

const smileFresh = createReducer<SmileFreshState, SmileFreshAction>(
  initialState,
  {
    [INIT_SMILE_FRESH]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_BRANCH_SERVICE_TYPE]: (state, { payload }) => ({
      ...state,
      currentBranchServiceType: payload,
    }),
  },
)

export default smileFresh
