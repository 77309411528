import { createStandardAction } from 'typesafe-actions'
import { CartBtViewType } from '~/types/enums'
import { BtItemMap, BuyBoxMap, HitBuyBox, RecommendedItemMap } from './types'

export const SET_BT_RECOMMEND_ITEM_MAP = 'recommend/SET_BT_RECOMMEND_ITEM_MAP'
export const FILL_BT_RECOMMEND_ITEM_EXT_INFO =
  'recommend/FILL_BT_RECOMMEND_ITEM_EXT_INFO'
export const SET_CURRENT_BT_SEED_IDX = 'recommend/SET_CURRENT_BT_SEED_IDX'
export const SET_BT_VIEW_TYPE = 'recommend/SET_BT_VIEW_TYPE'
export const ADD_BUY_BOXES = 'recommend/ADD_BUY_BOXES'
export const REMOVE_BUY_BOXES = 'recommend/REMOVE_BUY_BOXES'
export const ADD_HIT_BUY_BOX = 'recommend/ADD_HIT_BUY_BOX'
export const ADD_RECOMMENDED_ITEMS = 'recommend/ADD_RECOMMENDED_ITEMS'

export const setBtRecommendItemMap = createStandardAction(
  SET_BT_RECOMMEND_ITEM_MAP,
)<BtItemMap[]>()

export const fillBtRecommendItemExtInfo = createStandardAction(
  FILL_BT_RECOMMEND_ITEM_EXT_INFO,
)<{
  seedItemNo: string
  itemExtInfoList: cartAPI.RecommendItemResponse[]
}>()

export const setCurrentBtSeedIdx = createStandardAction(
  SET_CURRENT_BT_SEED_IDX,
)<number>()

export const setBtViewType =
  createStandardAction(SET_BT_VIEW_TYPE)<CartBtViewType>()

export const addBuyBoxes = createStandardAction(ADD_BUY_BOXES)<BuyBoxMap>()

export const removeBuyBoxes = createStandardAction(REMOVE_BUY_BOXES)<string[]>()

export const addHitBuyBox = createStandardAction(ADD_HIT_BUY_BOX)<{
  itemNo: string
  hitBuyBox: HitBuyBox
}>()

export const addRecommendedItems = createStandardAction(
  ADD_RECOMMENDED_ITEMS,
)<RecommendedItemMap>()
