import { History } from 'history'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { ApiError } from '~/lib/axios-client'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'

export type ComplexThunkDispatch<RootStateType> = ThunkDispatch<
  RootStateType,
  History,
  AnyAction
>

export type AsyncComplexAction<RootStateType, ReturnType = void> = ThunkAction<
  Promise<ReturnType | void>,
  RootStateType,
  History,
  AnyAction
>

export type ComplexAction<RootStateType, ReturnType = void> = ThunkAction<
  ReturnType | void,
  RootStateType,
  History,
  AnyAction
>

export default class ActionWrapper<RootStateType> {
  apiErrorHandler: (error: ApiError | Error) => void
  constructor(apiErrorHandler: (error: ApiError | Error) => void) {
    this.apiErrorHandler = apiErrorHandler
  }

  asyncWrapper = <T = void>(
    f: AsyncComplexAction<RootStateType, T>,
    unexpectedErrorHandler?: (
      dispatch: ComplexThunkDispatch<RootStateType>,
      getState: () => RootStateType,
    ) => (e: Error) => void,
    onFinal?: (
      dispatch: ComplexThunkDispatch<RootStateType>,
      getState: () => RootStateType,
    ) => () => void,
  ): AsyncComplexAction<RootStateType, T> => {
    return async (
      dispatch: ComplexThunkDispatch<RootStateType>,
      getState: () => RootStateType,
      history: History,
    ): Promise<void | T> => {
      if (unexpectedErrorHandler) {
        return f(dispatch, getState, history)
          .catch(this.apiErrorHandler)
          .catch(unexpectedErrorHandler(dispatch, getState))
          .finally(onFinal ? onFinal(dispatch, getState) : undefined)
      } else {
        return f(dispatch, getState, history)
          .catch(this.apiErrorHandler)
          .catch(ERROR_HANDLER_DO_NOTHING())
          .finally(onFinal ? onFinal(dispatch, getState) : undefined)
      }
    }
  }

  wrapper = <T = void>(
    f: ComplexAction<RootStateType, T>,
    unexpectedErrorHandler?: (
      dispatch: ComplexThunkDispatch<RootStateType>,
      getState: () => RootStateType,
    ) => (e: Error) => void,
    onFinal?: (
      dispatch: ComplexThunkDispatch<RootStateType>,
      getState: () => RootStateType,
    ) => () => void,
  ): ComplexAction<RootStateType, T> => {
    return (
      dispatch: ComplexThunkDispatch<RootStateType>,
      getState: () => RootStateType,
      history: History,
    ): T | void => {
      try {
        return f(dispatch, getState, history)
      } catch (e) {
        if (unexpectedErrorHandler) {
          try {
            this.apiErrorHandler(e)
          } catch (unexpectedError) {
            unexpectedErrorHandler(dispatch, getState)(e)
          } finally {
            if (onFinal) {
              onFinal(dispatch, getState)()
            }
          }
        } else {
          try {
            this.apiErrorHandler(e)
          } catch (e) {
            ERROR_HANDLER_DO_NOTHING()(e)
          } finally {
            if (onFinal) {
              onFinal(dispatch, getState)()
            }
          }
        }
      }
    }
  }
}
