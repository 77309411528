import queryString from 'query-string'
import EnumConverter from '~/lib/enum-converter'
import { ExpressShopParameter } from '~/types/common'
import { StaticPageType } from '~/types/enums'

const getDecodedQueryString = (
  query: queryString.ParsedQuery,
  key: string,
): string | undefined => {
  const queryString = query[key]
  if (typeof queryString === 'string') {
    return decodeURIComponent(queryString)
  }
}

export const getParameters = (): {
  expressShopReq: ExpressShopParameter | undefined
  closeUrl?: string
  isOpenedFromPC: boolean
  isMockApi: boolean
  signalCartUnitId?: number | undefined
} => {
  const query = queryString.parse(window.location.search)
  const isOpenedFromPC = query['fromPC'] === 'true'
  const isMockApi = query['mock'] === 'true'
  const cartUnitId = getDecodedQueryString(query, 'signalCartUnitId')

  const expressShopString = getDecodedQueryString(query, 'expressShop')
  const expressShopReq: ExpressShopParameter | undefined = expressShopString
    ? JSON.parse(expressShopString)
    : undefined
  const closeUrl = getDecodedQueryString(query, 'closeUrl')
  const signalCartUnitId = cartUnitId ? parseInt(cartUnitId, 10) : undefined

  return {
    expressShopReq,
    closeUrl,
    isOpenedFromPC,
    isMockApi,
    signalCartUnitId,
  }
}

export const getStaticPageParams = (): {
  pageType: StaticPageType
  returnUrl?: string
  closeUrl?: string
  param1?: string
  param2?: string
  param3?: string
  param4?: string
} => {
  const query = queryString.parse(window.location.search)

  const pageTypeString = query['pageType'] || query['pagetype']

  let pageType: StaticPageType = 'Unknown'

  if (typeof pageTypeString === 'string') {
    pageType = EnumConverter.toUnionType<StaticPageType>(
      pageTypeString,
      'Unknown',
    )
  }

  return {
    pageType,
    returnUrl:
      typeof query['returnUrl'] === 'string' ? query['returnUrl'] : undefined,
    closeUrl:
      typeof query['closeUrl'] === 'string' ? query['closeUrl'] : undefined,
    param1: typeof query['param1'] === 'string' ? query['param1'] : undefined,
    param2: typeof query['param2'] === 'string' ? query['param2'] : undefined,
    param3: typeof query['param3'] === 'string' ? query['param3'] : undefined,
    param4: typeof query['param4'] === 'string' ? query['param4'] : undefined,
  }
}
