import { createStandardAction } from 'typesafe-actions'
import {
  BuyerState,
  SmileClubInfo,
  SmileclubUnifyMembershipInfo,
} from './types'

export const INIT_BUYER = 'buyer/INIT_BUYER'
export const SET_SMILE_CLUB_MEMBER_INFO = 'buyer/UPDATE_SMILE_CLUB_MEMBER_INFO'
export const UPDATE_UNIFY_MEMBERSHIP_INFO = 'buyer/UPDATE_UNIFY_MEMBERSHIP_INFO'
export const SET_SMILE_CLUB_ONE_CLICK_TARGET_INFO =
  'buyer/UPDATE_SMILE_CLUB_ONE_CLICK_TARGET_INFO'
export const UPDATE_HASHED_CGUID = 'buyer/UPDATE_HASHED_CGUID'

export const initBuyer = createStandardAction(INIT_BUYER)<Partial<BuyerState>>()
export const updateSmileClubMemberInfo = createStandardAction(
  SET_SMILE_CLUB_MEMBER_INFO,
)<{ isSmileClub: boolean; smileClubInfo: SmileClubInfo }>()
export const updateUnifyMembershipInfo = createStandardAction(
  UPDATE_UNIFY_MEMBERSHIP_INFO,
)<SmileclubUnifyMembershipInfo>()
export const updateSmileClubOneClickTargetInfo = createStandardAction(
  SET_SMILE_CLUB_ONE_CLICK_TARGET_INFO,
)<boolean>()
export const updateHashedCguid = createStandardAction(
  UPDATE_HASHED_CGUID,
)<string>()
