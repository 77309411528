import { TenantConstants } from './index'

const constants: TenantConstants = {
  NotUseMobileVersionStaticPages: [],
  IsOverseaShippingAvailable: false,
  IsShowingBenefitInfos: true,
  PageShowingPoint: 'OnlyEssential',
  CouponUseType: 'UnitCoupon',
  IsUsingCouponBoxLayer: true,
  IsUsingCartTab: true,
  IsUsingCartBt: false,
  ElementScrollOptions: {},
  SupportedNudgingTypes: ['CLUB_ONE_CLICK', 'CART_COUNT_EXTENDED'],
  MomentLocale: 'ko',
  CustomerCenterContact: '1588-0184',
}

export default constants
