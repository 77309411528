import { GlobalLanguageCodeAlias } from '~/data/consts'

export default <T>(
  data: SiteEnvDataType<T>[],
  env: SiteEnvType,
  forceSelect = false,
): T | undefined => {
  if (!data || data.length === 0 || !env) {
    return undefined
  }

  const byTenant = data.filter((x) => x.env.tenantType === env.tenantType)
  if (!byTenant || byTenant.length === 0) {
    return forceSelect ? undefined : data[0].data
  }

  const byDevice = byTenant.filter((x) => x.env.deviceType === env.deviceType)
  if (!byDevice || byDevice.length === 0) {
    return forceSelect ? undefined : byTenant[0].data
  }

  const byLanguage = byDevice.find(
    (x) => x.env.languageType === env.languageType,
  )

  if (env.languageType !== 'Korean') {
    const byGlobalLanguage = byDevice.find(
      (x) => x.env.languageType === GlobalLanguageCodeAlias,
    )
    if (byGlobalLanguage) {
      return Object.assign({}, byGlobalLanguage.data, (byLanguage || {}).data)
    }
  }

  if (!byLanguage) {
    return forceSelect ? undefined : byDevice[0].data
  }

  return byLanguage.data
}
