import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getExhibitDiscountNudge,
  urlWithQueryParameters,
} from '~/cart/modules/cart/reducer'
import { ExhibitDiscountNudgeList } from '~/cart/modules/cart/types'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import { currencyAmount } from '~/lib/formatter'
import logRender from '~/lib/log-render'
import Montelena from '~/lib/montelena'

const ExhibitDiscountToast = (): JSX.Element => {
  logRender()

  const newExhibitDiscountNudge = useSelector((state: RootState) =>
    getExhibitDiscountNudge(state.cart.exhibitDiscountNudge),
  )
  const [exhibitDiscountNudge, setExhibitDiscountNudge] = useState<
    ExhibitDiscountNudgeList | undefined
  >(undefined)
  const nudgeTime = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (nudgeTime.current) {
      window.clearTimeout(nudgeTime.current)
    }
    nudgeTime.current = setTimeout(
      () => {
        setExhibitDiscountNudge(newExhibitDiscountNudge)
        if (newExhibitDiscountNudge) {
          Montelena.logImpression(
            'IMP_IM',
            newExhibitDiscountNudge?.fillBaseAmountYn === 'Y'
              ? areaCodes?.EXHIDIT_EXTRA_DISCOUNT?.fillBaseAmountY || ''
              : areaCodes?.EXHIDIT_EXTRA_DISCOUNT?.fillBaseAmountN || '',
            {
              ['exhibit_name']: newExhibitDiscountNudge.exhibitName,
              ['master_no']: newExhibitDiscountNudge.masterNo,
            },
            'ExhibitDiscountToast',
          )
        }
      },
      newExhibitDiscountNudge ? 0 : 1000,
    )
    return (): void => {
      if (nudgeTime.current) {
        window.clearTimeout(nudgeTime.current)
      }
    }
  }, [newExhibitDiscountNudge])

  const onClickNudgeUrl = (): void => {
    if (exhibitDiscountNudge) {
      window.location.href = urlWithQueryParameters(
        exhibitDiscountNudge.linkUrl,
        [{ extraMasterNo: exhibitDiscountNudge.masterNo }],
      )
    }
  }

  return (
    <div
      className={`box__toast-popup box__toast-payment-method ${
        newExhibitDiscountNudge ? 'box__toast-popup--active' : ''
      }`}
    >
      <div className="box__inner">
        <i className="image__card"></i>
        <div className="text__content">
          <span className="text__promotion-name">
            {exhibitDiscountNudge?.exhibitName}
          </span>
          {exhibitDiscountNudge?.fillBaseAmountYn === 'Y' ? (
            <span className="text__promotion-content">
              결제 시 최대{' '}
              <strong className="text__price">
                {exhibitDiscountNudge.discountRate}% 추가할인
              </strong>{' '}
              적용가능 👏
            </span>
          ) : (
            <span className="text__promotion-content">
              <strong className="text__price">
                {currencyAmount(exhibitDiscountNudge?.needAmount || 0)}원
              </strong>{' '}
              더 담고 할인받기
            </span>
          )}
        </div>
        {exhibitDiscountNudge?.fillBaseAmountYn !== 'Y' && (
          <button
            type="button"
            className="button__more-cart"
            data-montelena-acode={
              areaCodes?.EXHIDIT_EXTRA_DISCOUNT?.ToastNudgeClick
            }
            data-montelena-exhibit_name={exhibitDiscountNudge?.exhibitName}
            data-montelena-master_no={exhibitDiscountNudge?.masterNo}
            onClick={onClickNudgeUrl}
          >
            더 담기
          </button>
        )}
      </div>
    </div>
  )
}

export default React.memo(ExhibitDiscountToast)
