import { createReducer } from 'typesafe-actions'
import { UPDATE_EXCHANGE_INFO_LIST } from './actions'
import { ExchangeAction, ExchangeInfo, ExchangeState } from './types'

const initialState: ExchangeState = {
  exchangeInfoList: [],
}

export const getExchangeInfo = (
  state: ExchangeState,
  currencyCode: string,
): ExchangeInfo | undefined => {
  return state.exchangeInfoList.find((x) => x.currencyCode === currencyCode)
}

const exchange = createReducer<ExchangeState, ExchangeAction>(initialState, {
  [UPDATE_EXCHANGE_INFO_LIST]: (state, { payload }) => ({
    ...state,
    exchangeInfoList: state.exchangeInfoList
      .filter((x) => payload.some((y) => y.currencyCode === x.currencyCode)) // 데이터 있는것은 유지한다
      .concat(payload),
  }),
})

export default exchange
