import { TenantConstants } from './index'

const constants: Partial<TenantConstants> = {
  IsOverseaShippingAvailable: false,
  IsShowingBenefitInfos: false,
  IsUsingCouponBoxLayer: true,
  IsUsingCartTab: false,
  IsUsingCartBt: false,
  SupportedNudgingTypes: [],
  CustomerCenterContact: '1644-5702',
}

export default constants
