import { uniq, values } from 'lodash'
import { createReducer } from 'typesafe-actions'
import {
  APPLY_FUNDING_DISCOUNT,
  UPDATE_AVAILABLE_FUNDING_DISCOUNT,
} from './actions'
import {
  AvailableFundingDiscountMap,
  FundingDiscount,
  FundingDiscountAction,
  FundingDiscountState,
} from './types'

// 초기 상태 선언
const initialState: FundingDiscountState = {
  availableFundingDiscounts: {},
  fundingDiscountApplications: {},
}

/**
 * 여러개를 한번에 조회할 때
 * 전부 사용가능한 할인으로만 가격 합산해서 준다
 * @param state
 * @param cartUnitIdList
 */
export const getAvailableFundingDiscountListByGroup = (
  state: FundingDiscountState,
  cartUnitIdList: number[],
): FundingDiscount[] => {
  const everyAvailableDiscounts = cartUnitIdList.map(
    (cartUnitId) => state.availableFundingDiscounts[cartUnitId],
  )
  const firstFundingDiscounts = everyAvailableDiscounts[0]
  if (!firstFundingDiscounts) {
    return []
  }

  return firstFundingDiscounts.flatMap<FundingDiscount>((fundingDiscount) => {
    if (
      // 전부 사용가능한지 확인
      everyAvailableDiscounts.every(
        (list) =>
          list &&
          list.some(
            (x) => x.discountPolicyNo === fundingDiscount.discountPolicyNo,
          ),
      )
    ) {
      return [
        {
          discountPolicyNo: fundingDiscount.discountPolicyNo,
          displayName: fundingDiscount.displayName,
          discountPolicyName: fundingDiscount.discountPolicyName,
          discountPrice: everyAvailableDiscounts.reduce<number>(
            (result, list) => {
              const sameFundingDiscount =
                list &&
                list.find(
                  (y) =>
                    y.discountPolicyNo === fundingDiscount.discountPolicyNo,
                )
              // 할인가격 합산
              if (sameFundingDiscount) {
                return result + sameFundingDiscount.discountPrice
              }
              return result
            },
            0,
          ),
        },
      ]
    }
    return []
  })
}

export const getAppliedFundingDiscountList = (
  state: FundingDiscountState,
  cartUnitId: number,
): FundingDiscount[] => {
  const appliedPolicyNo = state.fundingDiscountApplications[cartUnitId]

  const fundingDiscounts = state.availableFundingDiscounts[cartUnitId]
  if (!fundingDiscounts) {
    return []
  }

  return fundingDiscounts.filter((x) => appliedPolicyNo === x.discountPolicyNo)
}

export const getAppliedFundingDiscount = (
  state: FundingDiscountState,
  cartUnitId: number,
): FundingDiscount | undefined => {
  const appliedDiscountPolicyNo = state.fundingDiscountApplications[cartUnitId]

  if (appliedDiscountPolicyNo) {
    const fundingDiscounts = state.availableFundingDiscounts[cartUnitId]
    if (fundingDiscounts) {
      return fundingDiscounts.find(
        (x) => x.discountPolicyNo === appliedDiscountPolicyNo,
      )
    }
  }
}

/**
 * 즉시할인 넛징용
 * @param state
 */
export const getAppliedFundingDiscountNames = (
  state: FundingDiscountState,
): string | undefined => {
  const appliedPolicyNoList = uniq(values(state.fundingDiscountApplications))
  const appliedDiscounts = appliedPolicyNoList.flatMap((policyNo) => {
    const result = Object.values(state.availableFundingDiscounts)
      .flatMap((x) => x)
      .find((x) => x && x.discountPolicyNo === policyNo)
    return result ? [result] : []
  })

  if (appliedDiscounts.length === 0) {
    return undefined
  }
  return appliedDiscounts.map((x) => x.displayName).join(', ')
}

// 리듀서 작성
const fundingDiscount = createReducer<
  FundingDiscountState,
  FundingDiscountAction
>(initialState, {
  [UPDATE_AVAILABLE_FUNDING_DISCOUNT]: (state, { payload }) => ({
    ...state,
    availableFundingDiscounts: {
      ...state.availableFundingDiscounts,
      ...payload.reduce<AvailableFundingDiscountMap>((result, x) => {
        return {
          ...result,
          [x.cartUnitId]: x.fundingDiscounts,
        }
      }, {}),
    },
  }),
  [APPLY_FUNDING_DISCOUNT]: (state, { payload }) => {
    const newApplications = { ...state.fundingDiscountApplications }
    payload.forEach(({ cartUnitId, appliedPolicyNo }) => {
      newApplications[cartUnitId] = appliedPolicyNo
    })
    return {
      ...state,
      fundingDiscountApplications: newApplications,
    }
  },
})

export default fundingDiscount
