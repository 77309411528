/* eslint-disable */
import siteEnv from '~/lib/site-env'

/**
 * 대체 왜 헤더 관련 초기화 함수가 이렇게 있는것이냐..
 * http://script-dev.auction.co.kr/mobile/style/js/ui.js
 * 에서 복사해옴
 * 최초로딩시에 header를 생성할게 아니라
 * 동적으로 헤더를 제어하기위해서 여기다 둔다
 * 너무싫다
 */
const initializeFromUiJs = () => {
  if (
    $(
      '.header_fix:not(.header_smiledelivery), .category-slider--nav, .l-main--finding .card--full',
    ).length > 0
  ) {
    headerFix()
  }
  footerInfo()

  // TOP button
  var toBtnFix = $('#btn_top, .bottom_wrap .to-detail')
  $(window).on('scroll', function (e) {
    if ($(window).scrollTop() > 300) {
      toBtnFix.addClass('active')
    } else {
      toBtnFix.removeClass('active')
    }
  })
  $('#btn_top').click(function () {
    $(window).scrollTop(0)
  })
  // LP select
  $('.bx_select select').on('change', function () {
    var text = this[this.selectedIndex].text
    $(this).closest('.h_cate_path').find('.h_cate').text(text)
  })

  // 검색어 입력 시 검색어 리셋 버튼 노출
  $('.inp').on('keyup focus', function (e) {
    if (this.value) {
      $(this).closest('.search_box').addClass('has_value')
    } else {
      $(this).closest('.search_box').removeClass('has_value')
    }
  })
  $('.search_box .btn_del').click(function () {
    $('.search_box').removeClass('focus')
    $(this).parent().find('.inp').val(null).focus()
  })
  $(window).load(function () {
    if ($('.bottom_inner').length > 0) {
      $('#footer').css('padding-bottom', '70px')
    }
  })
}

const isProduction = siteEnv.env === 'production' || siteEnv.env === 'av'
const scriptHost = isProduction
  ? '//script.auction.co.kr'
  : '//script-dev.auction.co.kr'

export default {
  loadScriptByOrder: true, // 순차적으로 로드해야할때만 사용!! 이슈없으면 default는 false다 주의
  headerScriptSources: [
    scriptHost + '/mobile/v4/gen/IM.Header.min.js',
    scriptHost + '/mobile/v4/gen/IM.Header.LayerTemplate.min.js',
  ],
  footerScriptSources: [],
  renderHeader: (title) => {
    window.IMHeader.configs.headerType = window.IMHeader.consts.headerTypeLayer

    window.IMHeader.configs.bottomType =
      window.IMHeader.consts.bottomTypeFloating

    // window.IMHeader.configs.renderFooter = false

    window.IMHeader.configs.hideGoTopButton = false

    window.IMHeader.configs.basicTitle = title
    window.IMHeader.configs.layerTitle = title

    // 헤더/푸터 생성 함수 호출
    window.IMHeader.TemplateHelper.render() // 헤더/푸터 생성 함수 호출

    initializeFromUiJs()
  },

  renderFooter: () => {
    // DO NOTHING
  },

  releaseHeader: () => {
    window.IMHeader = undefined
  },

  releaseFooter: () => {
    // DO NOTHING
  },

  setHeaderTitle: (title, isCoreApp) => {
    if (window.IMHeader && window.IMHeader.updateLayerTitle) {
      window.IMHeader.updateLayerTitle(title)
    }
    if (isCoreApp) {
      location.href =
        'auction://appheader?title=' + title + '&type=HEADER_TYPE_SIMPLE'
    }
  },

  setCloseUrl: (url) => {
    if (window.IMHeader && window.IMHeader.updatePreviousBackUrl) {
      // 전달받은게 없으면 기본값(뒤로가기)으로
      window.IMHeader.updatePreviousBackUrl(
        url || 'javascript:history.back(-1);',
      )
    }
  },
  hideAppHeader: (title) => {
    location.href =
      'auction://appheader?title=' + title + '&type=HEADER_TYPE_NO_TITLE'
  },
  showAppHeader: (title) => {
    location.href =
      'auction://appheader?title=' + title + '&type=HEADER_TYPE_SIMPLE'
  },
}
