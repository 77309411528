import { fetchClientError } from '~/apis/log'
import { ApiError } from '~/lib/axios-client'
import { __ } from '~/lib/i18n'
import siteEnv from '~/lib/site-env'

export const ERROR_HANDLER_ALERT =
  () =>
  (e: Error): void => {
    const defaultMessage = __(
      'IDX_TODO',
      '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다.',
    )

    if (siteEnv.env !== 'production') {
      window.alert(`${defaultMessage}\n(디버깅용 에러메시지 : ${e.message})`)
      console.error(e)
    } else {
      window.alert(defaultMessage)
    }
    if (!(e instanceof ApiError)) {
      fetchClientError(e, true).catch((err) => {
        console.error(err)
      })
    }
  }

export const ERROR_HANDLER_DO_NOTHING =
  () =>
  (e: Error): void => {
    if (siteEnv.env !== 'production') {
      console.error(e)
    }
    if (!(e instanceof ApiError)) {
      fetchClientError(e, true).catch((err) => {
        console.error(err)
      })
    }
  }
