import domains from '~/data/domains'
import { ApiError } from '~/lib/axios-client'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import siteEnv from '~/lib/site-env'
import { getCurrentUrl, goBackOrClose } from '~/lib/utils'

const CART_API_BIZ_ERROR_STATUS = 409

const getIndexedMessage = (input: string): string => {
  if (/^\w+_\w+_\w+_\d+$/.test(input.trim())) {
    return __(input) || input
  } else {
    return input
  }
}

const getErrorMessage = (message: string, data: unknown): string => {
  if (typeof data === 'string' || typeof data === 'number') {
    return formatString(message, data)
  } else if (data && data instanceof Array) {
    const arrayData = data.flatMap<string | number>((x) =>
      typeof x === 'string' || typeof x === 'number' ? [x] : [],
    )
    return formatString(message, arrayData)
  } else {
    return message
  }
}

const isCommandResponse = function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): data is cartAPI.CommandResponse {
  return (
    !!data &&
    typeof data.code !== 'undefined' &&
    typeof data.isSuccess !== 'undefined'
  )
}

const isCommandResponseArray = function (
  data: unknown,
): data is cartAPI.CommandResponse[] {
  return data instanceof Array && data.every(isCommandResponse)
}

export const apiErrorHandler = (error: ApiError | Error): void => {
  if (error instanceof ApiError) {
    if (error.domain === 'cart-api') {
      // 최초로딩
      if (/CART-BR2-/.test(error.resultCode)) {
        switch (error.resultCode) {
          // 간편회원 본인인증 필요 상품 존재
          case 'CHECKOUT-BR2-200':
            window.location.replace(
              formatString(domains.SELF_AUTH, getCurrentUrl(true)),
            )
            return
          default:
            if (error.message) {
              alert(
                getErrorMessage(getIndexedMessage(error.message), error.data),
              )
            } else {
              window.alert(
                formatString(
                  __(
                    'IDX_TODO',
                    '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다. \n사유 : {0}',
                  ),
                  error.resultCode,
                ),
              )
            }
        }

        // 개발시에는 뒤로가면 복잡해진다..
        if (siteEnv.env !== 'local') {
          goBackOrClose()
        }
        return
      } else if (error.resultCode === 'CART-BR1-51') {
        if (isCommandResponseArray(error.data) && error.data.length === 1) {
          const innerError = error.data[0]
          alert(
            getErrorMessage(
              innerError.message || innerError.code || '',
              innerError.data,
            ),
          )
        } else {
          alert(getErrorMessage(getIndexedMessage(error.message), error.data))
        }
        return
      } else {
        // 그 외 cart-api 오류
        if (error.status === CART_API_BIZ_ERROR_STATUS && error.message) {
          alert(getErrorMessage(getIndexedMessage(error.message), error.data))
        } else if (error.resultCode) {
          alert(
            formatString(
              __(
                'IDX_TODO',
                '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다. \n사유 : {0}',
              ),
              error.resultCode,
            ),
          )
        } else {
          window.alert(
            __(
              'IDX_TODO',
              '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다.',
            ),
          )
        }
        if (error.redirectUrl) {
          window.location.replace(error.redirectUrl)
        }
        return
      }
    } else if (error.domain === 'cart-fe') {
      switch (error.resultCode) {
        case 'CART-BR-101': // 대기열
          throw error
      }
    }

    if (error.nextAction) {
      if (
        error.nextAction === 'ALERT' ||
        error.nextAction === 'ALERT_AND_REFRESH' ||
        error.nextAction === 'ALERT_AND_REDIRECT'
      ) {
        window.alert(getIndexedMessage(error.message))
      }

      if (error.redirectUrl) {
        window.location.href = error.redirectUrl
      }

      if (error.nextAction === 'ALERT_AND_REFRESH') {
        window.location.reload()
      }
    } else {
      if (error.redirectUrl) {
        window.location.href = error.redirectUrl
      } else if (error.message) {
        window.alert(
          formatString(
            __(
              'IDX_TODO',
              '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다. \n사유 : {0}',
            ),
            getIndexedMessage(error.message),
          ),
        )
      } else {
        window.alert(
          __(
            'IDX_TODO',
            '일시적인 오류가 발생하였습니다. 잠시 후 다시 시도하여 주시기 바랍니다.',
          ),
        )
      }
    }
  } else {
    throw error
  }
}
