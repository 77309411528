import React from 'react'
const Processing = ({
  text,
}: {
  text?: string
  type: 'PrepareOrder' | 'Load'
}): JSX.Element => {
  return (
    <div className="box__inner-content">
      <div className="box__cart-loading">
        <div className="image__loading">
          <span className="for-a11y">{text}</span>
        </div>
      </div>
    </div>
  )
}

export default Processing
