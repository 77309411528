import React, { useEffect, useRef } from 'react'
import domains from '~/data/domains'
import UXEHelper from '~/lib/uxe-helper'

const useLoadingDots = (
  type: 'dot_mix_24' | 'dot_mix_48',
): [React.RefObject<HTMLDivElement>] => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      UXEHelper.initLottie(ref.current, {
        path:
          domains.SCRIPT +
          `/mobile/js/application/kr/lottie/loading/loading_${type}.json`,
        loop: true,
      })
    }
  }, [ref, type])

  return [ref]
}

export default useLoadingDots
