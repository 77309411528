import axiosClient from '~/lib/axios-client'

export const fetchGetShipping = async (
  request: cartAPI.GetShippingFeeRequest,
): Promise<cartAPI.GetShippingResponse> => {
  return axiosClient.post('/shipping/getShipping', request)
}

export const fetchGetShippingUnavailableItems = async (
  request: cartAPI.GetShippingUnavailableItemsRequest,
): Promise<cartAPI.ShippingUnavailableItemsResponse[]> => {
  return axiosClient.post('/shipping/getShippingUnavailableItems', request)
}

export const fetchGetSmileDeliveryTransPolicy = async (
  request: cartAPI.GetSmileDeliveryTransPolicyRequest,
): Promise<cartAPI.GetSmileDeliveryTransPolicyResponse> => {
  return axiosClient.post('/shipping/getSmileDeliveryTransPolicy', request)
}
