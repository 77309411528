// HTMLElement 를 Redux state 에 보관하면 Redux 에서 변화 분석할때 Circular 오류가 난다..
// 접근성 이슈때문에 어쨌든 레이어 호출한 element 를 보관할곳이 필요한데.. window 에 저장한다
// 특별한 이유가 있어서 window 에 저장한것은 아니니.. 더 좋은 방안이 있다면 수정하면 좋겠다
// Gateway 도 path 로 이동하는데 저장할곳이 없어서.. 여기에 한다
// Context 를 이용하면 좀 나을까..?
export default class ComplexDataStore {
  private static getComplexDataMap(
    type: ComplexDataType,
  ): Map<string, ComplexData> {
    if (!window.complexData) {
      window.complexData = {}
    }

    const map = window.complexData[type]

    if (map) {
      return map
    } else {
      const newMap = new Map<string, ComplexData>()
      window.complexData[type] = newMap
      return newMap
    }
  }

  static set<T = unknown>(
    type: 'GatewayData',
    key: string,
    data: GatewayData<T>,
  ): void

  static set(type: 'LayerDetailData', key: string, data: LayerDetailData): void

  static set<T = unknown>(
    type: ComplexDataType,
    key: string,
    data: ComplexData<T>,
  ): void {
    ComplexDataStore.getComplexDataMap(type).set(key, data)
  }

  static get<T = unknown>(
    type: 'GatewayData',
    key: string,
  ): GatewayData<T> | undefined

  static get(type: 'LayerDetailData', key: string): LayerDetailData | undefined

  static get<T = unknown>(
    type: ComplexDataType,
    key: string,
  ): ComplexData<T> | undefined {
    return ComplexDataStore.getComplexDataMap(type).get(key)
  }

  static delete(type: ComplexDataType, key: string): void {
    ComplexDataStore.getComplexDataMap(type).delete(key)
  }
}
