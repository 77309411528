export default class EnumConverter {
  /**
   * 현재 input event 에서 value 를 사용할 때에만 사용
   * 타입이 확실한 경우에만 사용해야한다.
   * @param value
   * @param defaultValue
   * @param dataList
   */
  static toUnionType<T extends string>(
    value: string,
    defaultValue?: T,
    dataList?: string[],
  ): T {
    if (dataList && defaultValue) {
      if (dataList.includes(value)) {
        return value as T
      } else {
        return defaultValue
      }
    }

    if (value || !defaultValue) {
      return value as T
    } else {
      return defaultValue
    }
  }

  static toEnum<T extends { [key: string]: string | number }>(
    type: T,
    value: string | number | undefined,
    defaultValue: T[keyof T],
  ): T[keyof T]

  static toEnum<T extends { [key: string]: string | number }>(
    type: T,
    value: string | number | undefined,
  ): T[keyof T] | undefined

  static toEnum<T extends { [key: string]: string | number }>(
    type: T,
    value: string | number | undefined,
    defaultValue?: T[keyof T],
  ): T[keyof T] | undefined {
    const dataFoundByValue = Object.keys(type)
      .map((x: keyof T) => ({
        key: x,
        value: type[x],
      }))
      .find((x) => x.value === value)

    if (dataFoundByValue) {
      return type[dataFoundByValue.key]
    }

    return defaultValue
  }
}
