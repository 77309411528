import { createReducer } from 'typesafe-actions'
import ComplexDataStore from '~/lib/complex-data-store'
import { __ } from '~/lib/i18n'
import {
  CLOSE_LAYER,
  FIRST_LOADED,
  INIT_VIEW,
  OPEN_LAYER,
  SET_FIRST_LOAD_FAILED,
  SET_HEADER_CLOSE_URL,
  SET_IS_PREPARE_ORDER_PROCESSING,
  SET_IS_SHIPPING_FEE_LOADED_AFTER_CHANGED,
  SET_SHOWING_APP_HEADER,
  SET_TITLE,
} from './actions'
import { LayerData, ViewAction, ViewState } from './types'

export const INITIAL_TITLE = __('ESCROW_BASKET_TEXT_45', '장바구니')

const initialState: ViewState = {
  tenantType: 'Unknown',
  isMobile: false,
  languageType: 'Korean',
  isApp: false,
  isAndroid: false,
  isIE: false,
  firstLoaded: false,
  firstLoadFailed: false,
  isPrepareOrderProcessing: false,
  title: INITIAL_TITLE,
  layerData: [],
  showingAppHeader: true,
  isShippingFeeLoadedAfterChanged: false,
}

export const isFirstLoaded = (state: ViewState): boolean => state.firstLoaded

/**
 * 그대로 사용시 triggerElement 때문에 다르다고 인식되어 렌더링 낭비됨
 * useSelector 에 key 비교하는 equalityFunction 꼭 사용할것
 * LayerContainer 참조
 * @param state
 */
export const getLayers = (state: ViewState): LayerData[] => {
  return state.layerData
}

const view = createReducer<ViewState, ViewAction>(initialState, {
  [INIT_VIEW]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [FIRST_LOADED]: (state) => ({
    ...state,
    firstLoaded: true,
  }),
  [SET_FIRST_LOAD_FAILED]: (state, { payload }) => ({
    ...state,
    firstLoadFailed: payload,
  }),
  [SET_TITLE]: (state, { payload: title }) => ({ ...state, title }),
  [SET_SHOWING_APP_HEADER]: (state, { payload: showingAppHeader }) => ({
    ...state,
    showingAppHeader,
  }),
  [SET_HEADER_CLOSE_URL]: (state, { payload: headerCloseUrl }) => ({
    ...state,
    headerCloseUrl,
  }),
  [OPEN_LAYER]: (state, { payload }) => ({
    ...state,
    layerData: state.layerData.concat(payload),
  }),
  [CLOSE_LAYER]: (state, { payload: key }) => {
    ComplexDataStore.delete('LayerDetailData', key)
    return {
      ...state,
      layerData: state.layerData.filter((x) => x.key !== key),
    }
  },
  [SET_IS_PREPARE_ORDER_PROCESSING]: (
    state,
    { payload: isOrderProcessing },
  ) => ({
    ...state,
    isPrepareOrderProcessing: isOrderProcessing,
  }),
  [SET_IS_SHIPPING_FEE_LOADED_AFTER_CHANGED]: (state, { payload }) => ({
    ...state,
    isShippingFeeLoadedAfterChanged: payload,
  }),
})

export default view
