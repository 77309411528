import React, { DetailedHTMLProps, useState } from 'react'
import { focusOrScrollIntoView } from '~/lib/utils'

const getByte = (input: string): number => {
  const charList = input.split('')

  return charList.reduce<number>((result, char) => {
    if (escape(char).startsWith('%u')) {
      return result + 2
    } else {
      return result + 1
    }
  }, 0)
}

export type CustomInputProps<ValueType = string> = {
  nextElement?: string | HTMLElement
  onlyNumber?: boolean // 패턴만 입력 허용하는지 여부
  maxByte?: number
  needTrim?: boolean
  value?: ValueType
  valueOnBlur?: ValueType
  reactRef?: React.RefObject<HTMLInputElement>
} & DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const CustomInput = <ValueType extends string>(
  props: CustomInputProps<ValueType>,
): JSX.Element => {
  const {
    maxByte,
    nextElement,
    onlyNumber,
    valueOnBlur,
    needTrim,
    reactRef,
    ...elseProps
  } = props

  const [isFocusIn, setFocusIn] = useState(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onlyNumber) {
      e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/g, '')
    }

    if (nextElement && elseProps.maxLength && elseProps.maxLength > 0) {
      if (e.currentTarget.value.length === elseProps.maxLength) {
        window.setTimeout(() => {
          focusOrScrollIntoView(nextElement)
        })
      }
    }

    // 안드로이드에서는 input 에 maxLength 설정이 입력하는 중에는 안먹는다.
    // 추가 방지 처리
    if (elseProps.maxLength && elseProps.maxLength > 0) {
      if (e.currentTarget.value.length > elseProps.maxLength) {
        return e.preventDefault()
      }
    }

    if (maxByte !== undefined) {
      const byte = getByte(e.currentTarget.value)
      if (byte > maxByte) {
        return e.preventDefault()
      }
    }
    if (props.onChange) {
      props.onChange(e)
    }
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    setFocusIn(true)
    if (props.onFocus) {
      props.onFocus(e)
    }
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (needTrim) {
      e.currentTarget.value = e.currentTarget.value.trim()
    }
    setFocusIn(false)
    if (props.onBlur) {
      props.onBlur(e)
    }
  }

  const value =
    valueOnBlur !== undefined && !isFocusIn ? valueOnBlur : props.value

  return (
    <input
      {...elseProps}
      ref={reactRef}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

export default CustomInput
