import axiosClient from '~/lib/axios-client'

export const fetchGetAvailableDiscount = async (
  request: cartAPI.GetAvailableDiscountRequest,
): Promise<cartAPI.GetDiscountResponse[]> => {
  return axiosClient.post('/discount/getAvailableDiscount', request)
}
export const fetchGetExhibitExtraDiscountForNudge = async (
  request: dcAPI.ExhibitExtraDiscountNudgeRequest[],
): Promise<dcAPI.GetDiscountForNudgeResponse[]> => {
  return axiosClient.post('/discount/getExhibitExtraDiscountForNudge', request)
}
