import { createReducer } from 'typesafe-actions'
import { INIT_EXPRESS_SHOP } from './actions'
import { ExpressShopAction, ExpressShopState } from './types'

const initialState: ExpressShopState = {
  branchDecisions: [],
  hideAltAgreementExpressShopTypes: [],
}

const branch = createReducer<ExpressShopState, ExpressShopAction>(
  initialState,
  {
    [INIT_EXPRESS_SHOP]: (state, { payload: newState }) => ({
      ...state,
      ...newState,
    }),
  },
)

export default branch
