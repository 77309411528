import { Domain, DomainByEnv, DomainCommon } from '~/data/domains'
import siteEnv from '~/lib/site-env'
import dataPicker from '../data-picker'

const domains: SiteEnvDataType<Partial<DomainByEnv>>[] = [
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Korean',
    },
    data: {
      SCRIPT: '//script-stg.gmarket.co.kr',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Korean',
    },
    data: {
      SCRIPT: '//script-stg.gmarket.co.kr',
    },
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'PC',
      languageType: 'Korean',
    },
    data: {
      SCRIPT: '//script-stg.auction.co.kr',
      SIGN_IN: 'https://memberssl-av.auction.co.kr/authenticate/?url={0}',
      SSL_MEMBER: 'https://memberssl-av.auction.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE:
        'https://memberss-av.auction.co.kr/fp/SmileClub/SmileClubGate.aspx?nexturl={0}',
      SELF_AUTH:
        'https://memberssl-av.auction.co.kr/Common/VerifyMain.aspx?url={0}',
      ECOUPON_SELF_AUTH:
        'https://memberssl-av.auction.co.kr/Common/m/VerifyIdentityPopup.aspx?ctype=N&mtype=Z&next={0}&close={1}',
    },
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'Mobile',
      languageType: 'Korean',
    },
    data: {
      SCRIPT: '//script-stg.auction.co.kr',
      SIGN_IN:
        'https://signin-av.auction.co.kr/Authenticate/MobileLogin.aspx?url={0}',
      SSL_MEMBER: 'https://memberssl-av.auction.co.kr',
      SSL_MEMBER_SMILE_CLUB_GATE:
        'https://memberssl-av.auction.co.kr/fp/SmileClub/SmileClubGate.aspx?nexturl={0}',
      SELF_AUTH:
        'https://memberssl-av.auction.co.kr/Common/VerifyMain.aspx?url={0}',
      ECOUPON_SELF_AUTH:
        'https://memberssl-av.auction.co.kr/Common/m/VerifyIdentityPopup.aspx?ctype=N&mtype=Z&next={0}&close={1}',
    },
  },
]

const cartHost = 'https://cart-av{devset}.{tenant}.co.kr'

const common: Partial<DomainCommon> = {
  CART: cartHost,
  CART_HOME: `${cartHost}/{language}/{device}/cart`,
  CART_FE_CLIENT: `${cartHost}/{language}/{device}`,
  CART_FE_CLIENT_MOBILE: `${cartHost}/{language}/m`,
  CART_FE_SERVER: `${cartHost}/server/{language}/{device}`,
  CHECKOUT:
    'https://checkout-av.{tenant}.co.kr/{language}/{device}/checkout?chid={0}',
}

const domainsByEnv =
  dataPicker<Partial<DomainByEnv>>(domains, siteEnv, true) || {}

const result: Partial<Domain> = {
  ...domainsByEnv,
  ...common,
}

export default result
